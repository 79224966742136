import React, { Fragment, useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import { getUniversityDetail, getIntern, updateUniversityTier } from '../../../api/member'
import { getUrlParams, setDetailDateFormat, onlyNumber } from '../../../utils/index'
import { MENUITEMS } from '../../../data/menu'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const UserManagement = () => {
  const dispatch = useAppDispatch()

  const [params, setParams] = useState({})
  const [basicInfo, setBasicInfo] = useState(null)
  const [student, setStudent] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [recordsStudentTotal, setRecordsStudentTotal] = useState(0)

  const [tier, setTier] = useState(5)

  useEffect(() => {
    dispatch(setLoading(false))
    
    // ?ucid=1&uvid=250
    // 기업 회원 기본정보
    const urlParams = getUrlParams(window.location.href)
    callBasicInfo(urlParams)
    callStudent(urlParams)
    setParams(urlParams)
    const temp = MENUITEMS.Items.filter(
      (menu) =>
        menu.children.filter((ch) => ch.path == window.location.pathname)
          .length != 0
    )
  }, [])

  const callBasicInfo = async (param) => {
    if(Object.keys(param).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getUniversityDetail(param.ucid).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        setBasicInfo({ ...data })
        setTier(data?.universityContact?.university?.tier ?? 5)
      } else {
        setBasicInfo({})
      }
    }
  }

  const callStudent = async (param) => {
    if(Object.keys(param).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getIntern({ universityId: param.uvid }).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        setStudent([...data.items])
        setRecordsStudentTotal(data.totalCount)
      } else {
        setStudent([])
        setRecordsStudentTotal(0)
      }
    }
  }

  const confirmTier = async () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('티어를 수정하시겠습니까?')) {
      dispatch(setLoading(true))
      
      const { code } = await updateUniversityTier(params?.uvid, { tier }).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        alert('수정 완료')
        window.location.reload()
      }else {
        alert('수정 실패')
      }
    }
  }

  return (
    <Fragment>
      <Container>
        <div className="contents">
          {/** 기본정보 테이블 */}
          <div className="card">
            <div className="card-head">
              <div className="d-flex justify-content-end">
                <a href="#" className="comm-btn btn-md btn-gray bd-radius-3" 
                  onClick={(e) => { 
                    e.preventDefault()
                    setEditMode(!editMode)
                  }}
                >
                  { !editMode ? '수정' : '보기' }
                </a>
              </div>
            </div>
            <div className="card-body">
              <div className="table mb-50 mt-40">
                <div className="table-head">
                    <h6 className="table-title">기본정보</h6>
                </div>
                <div className="table-body">
                  <table border="0" cellSpacing="0" cellPadding="0">
                    <colgroup>
                        <col width="15%" />
                        <col width="35%" />
                        <col width="15%" />
                        <col width="35%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td className="tac bg">ID</td>
                        <td className="tac">{basicInfo?.universityContact?.email || '-'}</td>
                        <td className="tac bg">담당자명</td>
                        <td className="tac">{basicInfo?.universityContact?.name || '-'}</td>
                      </tr>
                      <tr>
                        <td className="tac bg">연락처</td>
                        <td className="tac">{basicInfo?.universityContact?.phone || '-'}</td>
                        <td className="tac bg">대학교명</td>
                        <td className="tac">{basicInfo?.universityContact?.university?.name|| '-'}</td>
                      </tr>
                      <tr>
                        <td className="tac bg">회원가입일</td>
                        <td className="tac">
                          {basicInfo?.user?.createdAt != '' && basicInfo?.user?.createdAt 
                          ? setDetailDateFormat(basicInfo?.user?.createdAt) 
                          : '-'}
                        </td>
                        <td className="tac bg">최종로그인</td>
                        <td className="tac">
                          {basicInfo?.user?.lastLoginAt != '' && basicInfo?.user?.lastLoginAt 
                          ? setDetailDateFormat(basicInfo?.user?.lastLoginAt) 
                          : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td className="tac bg">대학교 티어</td>
                        <td className="tac">
                          <div className="d-flex">
                            <input type="number" id="tier" className="int" 
                              placeholder={basicInfo?.universityContact?.university?.tier ?? '티어'} min={1} max={5} value={tier} disabled={!editMode}
                              style={{width: '80px'}}
                              onInput={(e) => { e.target.value = onlyNumber(e.target.value) }}
                              onChange={(e) => {
                                if (e.target.value.length > 1) e.target.value = e.target.value.slice(0, 1)
                                setTier(e.target.value)
                            }} />

                            <button type="submit" className="comm-btn bd-radius-5 btn-success" disabled={!editMode}
                              onClick={() => confirmTier()} >수정</button>
                          </div>
                        </td>
                        <td className="tac bg"></td>
                        <td className="tac"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/** 매칭 내역 테이블 */}
              <div className="table mb-50 mt-40">
                <div className="table-head">
                    <h6 className="table-title">학생 리스트</h6>
                </div>
                <div className="table-body not-hover">
                  <table border="0" cellSpacing="0" cellPadding="0">
                    <colgroup>
                      <col width="55px" />
                      <col width="15%" />
                      <col width="20%" />
                      <col width="*" />
                      <col width="15%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>성명</th>
                        <th>학년</th>
                        <th>학과</th>
                        <th>기타사항</th>
                      </tr>
                    </thead>
                    <tbody>
                      {student?.length != 0 ? (
                        student.map((item, idx) => (
                          <tr key={idx}>
                            <td className="tac">
                              {recordsStudentTotal - idx}
                            </td>
                            <td className="tac">{item?.user?.name || '-'}</td>
                            <td className="tac">{item?.affiliatedUniversity?.grade || '-'}</td>
                            <td className="tac">{item?.affiliatedUniversity?.major || '-'}</td>
                            <td className="tac">{item?.otherInformation || '-'}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">
                            <div className="table-nopost">
                              조회된 데이터가 없습니다.
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default UserManagement
