import React, { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'
import dayjs from 'dayjs'
import SearchFilter from '../../common/searchFilter'
import PaginationComponent from '../../common/pagination'
import { getInternshipStory, updateInternshipStoryYn } from '../../../api/post'
import {
  comma,
  setDateTime,
  setDetailDateFormat,
  getUrlParams,
} from '../../../utils/index'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const ContentsManagement = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const urlParam = getUrlParams(window.location.href)
  const [complete, setComplete] = useState(false)
  const [recordsTotal, setRecordsTotal] = useState(300)
  const [storyList, setStoryList] = useState(null)

  const [params, setParams] = useState({
    filter: {
      startDate: new Date(
        dayjs().subtract(1, 'y').format('YYYY.MM.DD 00:00:00')
      ),
      endDate: new Date(dayjs().format('YYYY.MM.DD 23:59:59')),
      searchTxt: '',
    },
    pageNum: 1,
    listNum: 10,
  })

  const [filter, setFilter] = useState({
    startDate: new Date(dayjs().subtract(1, 'y').format('YYYY.MM.DD 00:00:00')),
    endDate: new Date(dayjs().format('YYYY.MM.DD 23:59:59')),
    searchTxt: '',
  })

  const callApplication = async () => {
    dispatch(setLoading(true))

    const { code, data } = await getInternshipStory({
      searchWord: params.filter.searchTxt,
      startAt: setDateTime(params.filter.startDate),
      endAt: setDateTime(params.filter.endDate),
      offset: (params.pageNum - 1) * params.listNum,
      limit: params.listNum,
      sort: 'DESC',
    }).finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      setStoryList([...data?.items])
      setRecordsTotal(data?.totalCount)
    } else {
      setStoryList([])
      setRecordsTotal(0)
    }
  }

  const statusDrop = (idx, mode) => {
    document.getElementById(`${mode}_dropDown_${idx}`).classList.toggle('active')
  }
  const statusChg = async (value, idx, mode) => {
    document.getElementById(`${mode}_${idx}`).innerHTML = 
      mode == 'bestYn' 
      ? (
        value 
        ? '노출' 
        : '미노출'
      )
      : (
        value 
        ? '공개' 
        : '비공개'
      )
      if(storyList[idx][mode] != value) {
        dispatch(setLoading(true))

        await updateInternshipStoryYn(storyList[idx].id, { [mode]: value }).finally(() => {
          dispatch(setLoading(false))
        })
      }
  }

  const pageReplace = (param) => {
    navigate(
      `/contents/internship/story?filter=${encodeURI(
        JSON.stringify(param.filter)
      )}&pageNum=${param.pageNum}&listNum=${param.listNum}`,
      { replace: true }
    )
  }

  useEffect(() => {
    if (Object.keys(getUrlParams(window.location.href)).length == 0) {
      pageReplace(params)
    }
  }, [getUrlParams(window.location.href)])

  useEffect(() => {
    init()
  }, [
    urlParam['filter'],
    urlParam['pageNum'],
    urlParam['listNum'],
  ])

  const init = () => {
    setParams({
      filter: urlParam['filter']
        ? JSON.parse(decodeURI(urlParam['filter']))
        : params.filter,
      pageNum: urlParam['pageNum'] ? urlParam['pageNum'] : params.pageNum,
      listNum: urlParam['listNum'] ? urlParam['listNum'] : params.listNum,
    })
    setFilter(
      urlParam['filter']
        ? JSON.parse(decodeURI(urlParam['filter']))
        : params.filter
    )
    setComplete(true)
  }

  useEffect(() => {
    if (complete) {
      callApplication()
    }
  }, [params.filter, params.pageNum, params.listNum])

  return (
    <Fragment>
      <Container>
        <div className="contents">
          <SearchFilter
            filter={filter}
            setFilter={(filter) => {
              setFilter({ ...filter })
            }}
            search={() =>
              pageReplace({ ...params, filter: { ...filter }, pageNum: 1 })
            }
          />
          {storyList && (
            <div className="table">
              <div className="table-head">
                <p className="total">
                  총 <strong>{comma(recordsTotal)}</strong>건
                </p>
                <div className="table-filter d-flex">
                  <label htmlFor="number-outputs" className="lbl me-5">
                    출력갯수 :
                  </label>
                  <select
                    name="number-outputs"
                    id="number-outputs"
                    className="int me-5"
                    value={params.listNum}
                    onChange={(e) => {
                      pageReplace({
                        ...params,
                        listNum: Number(e.target.value),
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  {/* 기획 없음 */}
                  {/* <select name="" id="" className="int">
                      <option value="">정렬방식</option>
                      <option value="" selected>
                        등록일 빠른순
                      </option>
                      <option value="">등록일 느린순</option>
                    </select> */}
                </div>
              </div>
              {/* //table-head */}
              <div className="table-body">
                <table border="0" cellSpacing="0" cellPadding="0">
                  <colgroup>
                    <col width="55px" />
                    <col width="15%" />
                    <col width="*" />
                    <col width="15%" />
                    <col width="15%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <div className="th-move">No</div>
                      </th>
                      <th>작성 일시</th>
                      <th>게시물</th>
                      <th>게시 여부</th>
                      <th>Best Story 관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storyList?.length != 0 ? (
                      storyList.map((item, idx) => (
                        <tr key={idx}>
                          <td className="tac">
                            {recordsTotal - ((params.pageNum - 1) * params.listNum + idx)}
                          </td>
                          <td className="tac">
                            {item?.createdAt != '' && item?.createdAt 
                              ? setDetailDateFormat(item?.createdAt) 
                              : '-'}
                          </td>
                          <td className="tac">{
                            item?.title 
                            ? 
                              <Link className="text-btn btn-primary" to={`/contents/internship/story/detail?mode=edit&sid=${item?.id}`}>
                                { item?.title }
                              </Link>
                            : '-'
                          }</td>
                          <td className="tac overflow">
                            <div className="dropdown" id={`activeYn_dropDown_${idx}`}
                              onClick={() => {
                                statusDrop(idx, 'activeYn')
                              }}
                            >
                              <a href="#" onClick={(e) => { e.preventDefault()}} className="int dropdown-title" id={`activeYn_${idx}`}>{item?.activeYn ? '공개' : '비공개'}</a>
                              <div className="dropdown-menu">
                                  <div className="dim"></div>
                                  <ul className="dropdown-list">
                                    <li onClick={() => {
                                      statusChg(true, idx, 'activeYn')
                                    }}><a className="dropdown-li-a" href="#" onClick={(e) => { e.preventDefault()}}>공개</a></li>
                                    <li onClick={() => {
                                      statusChg(false, idx, 'activeYn')
                                    }}><a className="dropdown-li-a" href="#" onClick={(e) => { e.preventDefault()}}>비공개</a></li>
                                  </ul>
                              </div>
                            </div>
                          </td>
                          <td className="tac overflow">
                            <div className="dropdown" id={`bestYn_dropDown_${idx}`}
                              onClick={() => {
                                statusDrop(idx, 'bestYn')
                              }}
                            >
                              <a href="#" onClick={(e) => { e.preventDefault()}} className="int dropdown-title" id={`bestYn_${idx}`}>{item?.bestYn ? '노출' : '미노출'}</a>
                              <div className="dropdown-menu">
                                  <div className="dim"></div>
                                  <ul className="dropdown-list">
                                      <li onClick={() => {
                                        statusChg(true, idx, 'bestYn')
                                      }}><a className="dropdown-li-a" href="#" onClick={(e) => { e.preventDefault()}}>노출</a></li>
                                      <li onClick={() => {
                                        statusChg(false, idx, 'bestYn')
                                      }}><a className="dropdown-li-a" href="#" onClick={(e) => { e.preventDefault()}}>미노출</a></li>
                                  </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">
                          <div className="table-nopost">
                            조회된 데이터가 없습니다.
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="table-foot me-10">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-center"></div>
                    <a href={`/contents/internship/story/detail?mode=reg`} className="comm-btn btn-md btn-gray bd-radius-3">
                      작성
                    </a>
                  </div>
                </div>
                {storyList?.length != 0 && (
                  <PaginationComponent
                    page={params.pageNum}
                    pageSize={params.listNum}
                    total={recordsTotal == 0 ? 1 : recordsTotal}
                    pageClick={(page) => {
                      pageReplace({ ...params, pageNum: page })
                    }}
                    paging={0}
                  />
                )}
              </div>
            </div>
          )}
          {/* //table */}
        </div>
      </Container>
    </Fragment>
  )
}

export default ContentsManagement
