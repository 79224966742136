import ApiBase from "./apiBase";

// API instance
const Api = new ApiBase("/deposit-bank");

// 결제 관리 > 입금 정보
export const getDepositBank = async () => {
  // return await Api.submit("get", ``);
  return await Api.submit("get", `history`);
};

// 결제 관리 > 입금 정보 추가
export const insertDepositBank = async (params) => {
  return await Api.submit("post", ``, params);
};

// 결제 관리 > 입금 정보 수정
export const updateDepositBank = async (id, params) => {
  return await Api.submit("post", `${id}`, params);
};

// 결제 관리 > 입금 정보 삭제
export const deleteDepositBank = async (id) => {
  return await Api.submit("post", `delete/${id}`);
};
