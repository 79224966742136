/* 
  Redux Toolkit 
*/

/* 
  Auth Token 관리
*/
import axios from 'axios'
import { createSlice } from '@reduxjs/toolkit'

function setDefaultAuthHeaders(state) {
  if (state) {
    axios.defaults.headers.common.Authorization = `Bearer ${state}`
  } else {
    axios.defaults.headers.common.Authorization = ``
  }
}

function initState() {
  const state = {
    token: '',
    refresh: '',
    error: '',
  }

  // try {
  //   // @ts-ignore
  //   const tmpToken = JSON.parse(window.localStorage.getItem('token'))

  //   if (!tmpToken) throw 'error'

  //   state.token = tmpToken?.token || ''
  //   state.expire = tmpToken?.expire || 0
  //   state.refreshToken = tmpToken?.refreshToken || ''
  // } catch (_) {
  //   state.token = ''
  //   state.expire = 0
  //   state.refreshToken = ''
  // }

  return state
}

export const initialState = initState()

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    // Actions

    /* 로그인 성공 */
    setToken(state, action) {
      const token = {
        token: action.payload.token,
        refresh: action.payload.refresh,
        error: '',
      }

      // console.log('setToken', token)
      state.token = token.token
      state.refresh = token.refresh
      state.error = token.error

      // localstorage 저장
      // localStorage.setItem('token', JSON.stringify(token))

      // Axios Header 설정
      setDefaultAuthHeaders(state.token)
    },
    /* 로그인 실패 & 로그아웃시 */
    delToken(state) {
      state.token = ''
      state.refresh = ''
      state.error = ''

      // localStorage.removeItem('token')
      setDefaultAuthHeaders(null)
    },
    errToken(state, action) {
      state.token = ''
      state.refresh = ''
      state.error = action?.payload?.msg

      // yarnlocalStorage.removeItem('token')
      setDefaultAuthHeaders(null)
    },
  },
})

export default tokenSlice.reducer

// actions
export const { setToken, delToken, errToken } = tokenSlice.actions
