import ApiBase from "./apiBase";

// API instance
const Api = new ApiBase("/statistics");

// 통계 > 가입/탈퇴 통계
export const getJoinSecession = async (params) => {
  return await Api.submit("get", `join-secession`, params);
};

// 통계 > 매칭/합격 통계
export const getMatchingPass = async (params) => {
  return await Api.submit("get", `matching-pass`, params);
};

// 결제 관리 > 매출/환불 내역
export const getSalesRefund = async (params) => {
  return await Api.submit("get", `sales-refund`, params);
};
