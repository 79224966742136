import React, { Fragment, useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import dayjs from 'dayjs'
import { MENUITEMS } from '../../data/menu'

const SearchFilter = ({ filter, setFilter = () => {}, search = () => {} }) => {
  const winUrl = window.location.pathname
  const menu = MENUITEMS.Items.filter(
    (menu) => menu.children.filter((ch) => winUrl.includes(ch.path)).length != 0
  )
  const [ filterExtend, setFilterExtend ] = useState(false)
  const [ recruitmentExtend, setRecruitmentExtend ] = useState(false)
  const [ applicationType, setApplicationType ] = useState('')
  useEffect(() => {
    if(menu.length > 0 && Object.keys(menu[0]).includes('children')) {
      const contain = ['/user/internship', '/user/globalcamp', '/contents/internship/applications', '/contents/globalcamp/application']
      const exceptContain = ['/contents/internship/recruitments']
      if(contain.indexOf(winUrl) > -1) {
        setFilterExtend(true)
        if(winUrl.indexOf('internship') > -1) {
          setApplicationType('internship')
        }else if(winUrl.indexOf('globalcamp') > -1) {
          setApplicationType('globalcamp')
        }
      }else {
        setFilterExtend(false)
      }

      if(exceptContain.indexOf(winUrl) > -1) {
        setRecruitmentExtend(true)
      }else {
        setRecruitmentExtend(false)
      }
    }
  }, [menu])
  return (
    <Fragment>
      <Container>
        <div className="filter on-bg mb-30">
          <div className="filter-col form-col mb-30">
            <label htmlFor="keyword-search" className="lbl">
              기간
            </label>
            <div className="col-int">
              <div className="filter-int" style={{ alignItems: 'center' }}>
                <input
                  type="date"
                  id="a_date"
                  className="int"
                  value={dayjs(filter.startDate).format('YYYY-MM-DD')}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      startDate: new Date(e.target.value),
                    })
                  }
                  max={dayjs(filter.endDate).format('YYYY-MM-DD')}
                  style={{ width: '150px', marginRight: '10px' }}
                ></input>
                ~
                <input
                  type="date"
                  id="a_date"
                  className="int"
                  value={dayjs(filter.endDate).format('YYYY-MM-DD')}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      endDate: new Date(e.target.value),
                    })
                  }
                  min={dayjs(filter.startDate).format('YYYY-MM-DD')}
                  style={{
                    width: '150px',
                    marginLeft: '10px',
                    marginRight: '30px',
                  }}
                ></input>
                <button
                  type="button"
                  className={'comm-btn btn-secondary'}
                  style={{
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    const date = dayjs()
                    setFilter({
                      ...filter,
                      endDate: date.toDate(),
                      startDate: date.subtract(1, 'w').toDate(),
                    })
                  }}
                >
                  1주일
                </button>
                <button
                  type="button"
                  className={'comm-btn btn-secondary'}
                  style={{
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    const date = dayjs()
                    setFilter({
                      ...filter,
                      endDate: date.toDate(),
                      startDate: date.subtract(1, 'M').toDate(),
                    })
                  }}
                >
                  1개월
                </button>
                <button
                  type="button"
                  className={'comm-btn btn-secondary'}
                  style={{
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    const date = dayjs()
                    setFilter({
                      ...filter,
                      endDate: date.toDate(),
                      startDate: date.subtract(3, 'M').toDate(),
                    })
                  }}
                >
                  3개월
                </button>
                <button
                  type="button"
                  className={'comm-btn btn-secondary'}
                  style={{
                    marginRight: '10px',
                    width: '82px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    const date = dayjs()
                    setFilter({
                      ...filter,
                      endDate: date.toDate(),
                      startDate: date.subtract(1, 'y').toDate(),
                    })
                  }}
                >
                  1년
                </button>
              </div>
            </div>
          </div>
          {
            filterExtend 
            ?
            <>
            <div className="filter-col form-col mb-20">
              <label htmlFor="keyword-application" className="lbl">
                지원/결제 상태
              </label>
              <div className="col-int">
                <div className="filter-int">
                  <select
                    name="keyword-application"
                    id="keyword-application"
                    className="int me-10"
                    style={{ width: '150px' }}
                    value={filter.applicationState}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        applicationState: e.target.value,
                      })
                    }
                  >
                    <option value="">지원 상태 전체</option>
                    {
                      applicationType === 'internship'
                      ? 
                      <>
                        <option value="ACCEPTED">ACCEPTED</option>
                        <option value="APPLICATION">APPLICATION</option>
                        <option value="BALANCE_PAID">BALANCE_PAID</option>
                        <option value="CANCEL">CANCEL</option>
                        <option value="DEPOSIT_PAID">DEPOSIT_PAID</option>
                        <option value="REJECTED">REJECTED</option>
                      </>
                      : 
                      <>
                        <option value="APPLICATION">APPLICATION</option>
                        <option value="CANCEL">CANCEL</option>
                        <option value="COMPLETE">COMPLETE</option>
                      </>
                    }
                  </select>
                  
                  {
                    applicationType === 'internship'
                    ? 
                    <select
                      name="keyword-application"
                      id="keyword-application"
                      className="int me-10"
                      style={{ width: '150px' }}
                      value={filter.paymentProgress}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          paymentProgress: e.target.value,
                        })
                      }
                    >
                      <option value="">1/2차 결제 전체</option>
                      <option value="DEPOSIT">DEPOSIT</option>
                      <option value="BALANCE">BALANCE</option>
                    </select>
                    : 
                    <></>
                  }

                  <select
                    name="keyword-application"
                    id="keyword-application"
                    className="int me-10"
                    style={{ width: '150px' }}
                    value={filter.paymentState}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        paymentState: e.target.value,
                      })
                    }
                  >
                    <option value="">결제 상태 전체</option>
                    <option value="PAID">PAID</option>
                    <option value="REFUNDED">REFUNDED</option>
                  </select>
                </div>
              </div>
            </div>
            </>
            : <></>
          }
          {
            recruitmentExtend
            ?
            <>
              <div className="filter-col form-col mb-20">
                <label htmlFor="keyword-application" className="lbl">
                  기업명 검색
                </label>
                <div className="col-int">
                  <div className="filter-int">
                    <input
                      type="text"
                      id="keyword-int"
                      value={filter.companyTxt}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          companyTxt: e.target.value,
                        })
                      }
                      onKeyDown={(e) => {
                        if(e.key === "Enter") {
                          search();
                        }
                      }}
                      className="int me-10"
                      placeholder="검색어를 입력하세요."
                    />
                  </div>
                </div>
              </div>
            </>
            :
            <></>
          }
          <div className="filter-col form-col">
            <label htmlFor="keyword-search" className="lbl">
              키워드 검색
            </label>
            <div className="col-int">
              <div className="filter-int">
                {/* <select
                  name="keyword-search"
                  id="keyword-search"
                  className="int me-10"
                  style={{ width: '150px' }}
                >
                  <option value="">전체</option>
                </select> */}
                <input
                  type="text"
                  id="keyword-int"
                  value={filter.searchTxt}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      searchTxt: e.target.value,
                    })
                  }
                  onKeyDown={(e) => {
                    if(e.key === "Enter") {
                      search();
                    }
                  }}
                  className="int me-10"
                  placeholder="검색어를 입력하세요."
                />
              </div>
              <div className="filter-btn">
                <button
                  type="button"
                  className="btn-refresh me-10"
                  onClick={() => {
                    const date = dayjs()
                    setFilter({
                      ...filter,
                      startDate: date.toDate(),
                      endDate: date.toDate(),
                      searchTxt: '',
                    })
                  }}
                ></button>
                <button
                  type="button"
                  className="comm-btn icon-btn btn-dark bd-radius-3"
                  onClick={() => search()}
                >
                  <i className="ico-search"></i> 검색
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default SearchFilter
