import ApiBase from "./apiBase";

// API instance
const Api = new ApiBase("/auth");

// 로그인 API
export const login = async (params) => {
  return await Api.submit("post", "login", params);
};

// 유저정보 API
export const getUserInfo = async () => {
  return await Api.submit("get", "info");
};

// 로그아웃 API
export const logout = async () => {
  return await Api.submit("post", "logout");
};

// 토큰 갱신 API
export const refreshToken = async () => {
  return await Api.submit("post", "refresh-token");
};
