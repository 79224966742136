import React, { Fragment } from 'react'
const Footer = () => {
  return (
    <Fragment>
      <footer id="footer" className="px-0">
        <div className="l-maxWidth tac">
          <address>
            Copyright © 2024 Thunderjob Pty. Ltd. All rights reserved.
          </address>
        </div>
      </footer>
    </Fragment>
  )
}

export default Footer
