/* 페이지네이션 컴포넌트 */
import React, { Fragment } from 'react'
import { Container } from 'reactstrap'

const PaginationComponent = ({
  total,
  pageSize,
  page,
  paging,
  pageClick = () => {
    return
  },
}) => {
  // 화면에 보여줄 페이지 수
  let displayPageSize
  let startPage = 0
  if (paging == null) {
    displayPageSize = 10
    startPage = page - 4
  } else {
    displayPageSize = 5
    startPage = page - 2
  }
  // 페이지 리스트
  const pageLength = Math.ceil(total / pageSize)

  // let startPage = (Math.ceil(page / displayPageSize) - 1) * displayPageSize - 1

  if (startPage <= 0) startPage = 1

  const endPageNum =
    startPage + displayPageSize > pageLength
      ? pageLength
      : startPage + displayPageSize - 1

  const pageArrLength = endPageNum - startPage + 1
  return (
    <Fragment>
      <Container>
        <div className="table-pagination pagi-bg">
          <nav>
            <ul className="pagination on-bg">
              <li>
                <a
                  href="#"
                  className={'pagi-first'}
                  onClick={(e) => {
                    e.preventDefault()
                    pageClick(1)
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.653 15.305L8 11.653L11.653 8"
                      stroke="#333333"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.7668 15.305L13.1138 11.653L16.7668 8"
                      stroke="#333333"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={'pagi-prev'}
                  onClick={(e) => {
                    e.preventDefault()
                    if (page > 1) {
                      pageClick(page - 1)
                    }
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.8"
                      d="M13.653 15.305L10 11.653L13.653 8"
                      stroke="#333333"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </li>
              {[...Array(pageArrLength)].map((_, i) => (
                <li className={startPage + i == page ? ' on' : ''} key={i}>
                  <a
                    href="#"
                    className="pagi-num bd-radius-50"
                    onClick={(e) => {
                      e.preventDefault()
                      pageClick(startPage + i)
                    }}
                  >
                    {startPage + i}
                  </a>
                </li>
              ))}
              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    if (page < pageLength) {
                      pageClick(page + 1)
                    }
                  }}
                  className="pagi-next"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.8"
                      d="M10.347 8.695L14 12.347L10.347 16"
                      stroke="#333333"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    pageClick(pageLength)
                  }}
                  className="pagi-last"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.347 8.695L16 12.347L12.347 16"
                      stroke="#333333"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.23323 8.695L10.8862 12.347L7.23323 16"
                      stroke="#333333"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </Container>
    </Fragment>
  )
}

export default PaginationComponent
