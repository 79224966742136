import UserManagement from '../components/user/management/index'
import InternUserDetail from '../components/user/management/internUserDetail'
import CorporateUserDetail from '../components/user/management/corporateUserDetail'
import UniversityUserDetail from '../components/user/management/universityUserDetail'

import InternshipApplication from '../components/user/internship/index'
import InternshipApplicationDetail from '../components/user/internship/detail'

import GlobalCampApplication from '../components/user/globalcamp/index'
import GlobalCampApplicationDetail from '../components/user/globalcamp/detail'
import Withdrawn from '../components/user/withdrawn/index'

// eslint-disable-next-line no-undef, import/no-anonymous-default-export
export default [
  { path: `/user/management`, Component: <UserManagement /> },
  { path: `/user/management/detail/intern`, Component: <InternUserDetail /> },
  { path: `/user/management/detail/corporate`, Component: <CorporateUserDetail /> },
  { path: `/user/management/detail/university`, Component: <UniversityUserDetail /> },

  { path: `/user/internship`, Component: <InternshipApplication /> },
  { path: `/user/internship/detail`, Component: <InternshipApplicationDetail /> },

  { path: `/user/globalcamp`, Component: <GlobalCampApplication /> },
  { path: `/user/globalcamp/detail`, Component: <GlobalCampApplicationDetail /> },
  
  { path: `/user/withdrawn`, Component: <Withdrawn /> },
]