import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Container } from 'reactstrap'
import { useNavigate, Link } from 'react-router-dom'
import { getAdminDetail, insertAdmins, updateAdmins, getAdminIdOverlap } from '../../../api/system'
import { getCountryCodes } from '../../../api/common'
import { getUrlParams, onlyNumber, isEmpty, phoneNum, telNum, setDetailDateFormat } from '../../../utils/index'
import { MENUITEMS } from '../../../data/menu'
import { setLoading } from '../../../redux/loading'
import { store, useAppDispatch } from '../../../redux/store'

const BannerManagement = () => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const [params, setParams] = useState(null)
  const [editMode, setEditMode] = useState(false)
  
  const [admin, setAdmin] = useState({})
  const [overlap, setOverlap] = useState(false)
  const [account, setAccount] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRe, setPasswordRe] = useState('')
  const [email, setEmail] = useState('')
  const [countryCode, setCountryCode] = useState([])

  const [telCountry, setTelCountry] = useState('')
  const [tel, setTel] = useState('')
  // const [fTel, setFtel] = useState('')
  // const [sTel, setStel] = useState('')
  // const [tTel, setTtel] = useState('')
  
  const [phoneCountry, setPhoneCountry] = useState('')
  const [phone, setPhone] = useState('')
  // const [fPhone, setFphone] = useState('')
  // const [sPhone, setSphone] = useState('')
  // const [tPhone, setTphone] = useState('')
  const [active, setActive] = useState(true)
  
  useEffect(() => {
    setOverlap(false)
  }, [account])

  useEffect(async () => {
    dispatch(setLoading(false))
    
    const urlParams = getUrlParams(window.location.href)
    setParams(urlParams)

    // 국가코드 호출
    dispatch(setLoading(true))

    const { code, data } = await getCountryCodes().finally(() => {
      dispatch(setLoading(false))
    })
    
    if(code === 200) {
      setCountryCode([ ...data ])
    }

    if(urlParams?.mode !== 'reg') {
      if(store.getState()?.user?.authorities?.[0]?.authority !== 'ADMN' && store.getState()?.user?.id != urlParams?.aid) {
        alert('접근 권한 오류')
        navigate('/system/admin')
        return
      }
      getAdmin(urlParams)
      setEditMode(false)
    }else {
      if(store.getState()?.user?.authorities?.[0]?.authority !== 'ADMN') {
        alert('접근 권한 오류')
        navigate('/system/admin')
        return
      }
      setEditMode(true)
    }

    const temp = MENUITEMS.Items.filter(
      (menu) =>
        menu.children.filter((ch) => ch.path == window.location.pathname)
          .length != 0
    )
  }, [])

  const dataCheck = () => {

    if(params?.mode === 'reg') {
      if(isEmpty(account)) {
        alert('필수 입력 항목을 입력해주세요')
        return false
      }

      if(!overlap) {
        alert('아이디 중복체크를 해주세요')
        return false
      }
    }

    if(isEmpty(name)
        || isEmpty(password)
        || isEmpty(passwordRe)
        || isEmpty(email)
        || isEmpty(phoneCountry)
        || isEmpty(phone)
    ) {
      alert('필수 입력 항목을 입력해주세요')
      return false
    }

    if(password !== passwordRe) {
      alert('비밀번호가 일치하지 않습니다')
      return false
    }

    return true
  }

  const cancelContents = () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm(`작성한 내용을 저장하지 않고\n상위 페이지로 이동하시겠습니까?`)) {
      window.location.reload()
    }
  }

  // 아이디 중복체크
  const idOverLapCheck = async() => {
    if(!overlap) {
      if(account.length > 0){
        const { code, data } = await getAdminIdOverlap(account)

        if(code == 200 && !data) {
          setOverlap(true)
        } else {
          alert('이미 존재하는 ID입니다')
          setOverlap(false)
        }
      }
    }
  }

  // 등록
  const insertAdmin = async () => {
    if(!dataCheck()) return

    let replacePhone = String(phone)
    let replaceTel = !isEmpty(tel) ? String(tel) : ''
    // 한국 
    if(phoneCountry === '+82') {
      replacePhone = replacePhone.indexOf('0') === 0 ? replacePhone.substring(1) : replacePhone
    }

    if(telCountry === '+82' && replaceTel?.length > 0) {
      replaceTel = replaceTel.indexOf('0') === 0 ? replaceTel.substring(1) : replaceTel
    }

    // 파라미터 정리
    const param = {
      account,
      name,
      password,
      phoneCountry: String(phoneCountry),
      telephoneCountry: String(telCountry),
      phone: replacePhone,
      telephone: replaceTel,
      role: 'COMN',
      isEnabled: active,
      email,
    }
    dispatch(setLoading(true))

    const { code } = await insertAdmins(param).finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      alert('등록 완료')
      navigate('/system/admin')
    }else {
      alert('등록 실패')
    }
  }

  // 상세
  const getAdmin = async (param) => {
    if(Object.keys(param?.aid).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getAdminDetail(param?.aid).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        setAdmin({ ...data })
        setAccount(data?.account)
        setActive(data?.enabled)
        setName(data?.name)
        setEmail(data?.email)
        if(!isEmpty(data?.phoneCountry)) {
          setPhoneCountry(data?.phoneCountry)
        }else {
          setPhoneCountry('+82')
        }
        setPhone(data?.phone)

        if(!isEmpty(data?.telephoneCountry)) {
          setTelCountry(data?.telephoneCountry)
        }else {
          setTelCountry('+82')
        }
        setTel(data?.telephone)

      } else {
        setAdmin({})
      }
    }
  }

  // 수정
  const updateAdmin = async () => {    
    if(!dataCheck()) return
    
    let replacePhone = String(phone)
    let replaceTel = !isEmpty(tel) ? String(tel) : ''
    // 한국 
    if(phoneCountry === '+82') {
      replacePhone = replacePhone.indexOf('0') === 0 ? replacePhone.substring(1) : replacePhone
    }

    if(telCountry === '+82' && replaceTel?.length > 0) {
      replaceTel = replaceTel.indexOf('0') === 0 ? replaceTel.substring(1) : replaceTel
    }

    // 파라미터 정리
    const param = {
      name,
      password,
      phoneCountry: String(phoneCountry),
      telephoneCountry: String(telCountry),
      phone: replacePhone,
      telephone: replaceTel,
      isEnabled: active,
      email,
    }
    dispatch(setLoading(true))

    const { code } = await updateAdmins(params?.aid, param).finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      alert('수정 완료')
    }else {
      alert('수정 실패')
    }

    // 리로드
    window.location.reload()
  }

  return (
    <Fragment>
      <Container>
        <div className="contents">
          <div className="card">
            <div className="card-head">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-center"><h6 className="card-title">{ params?.mode == 'reg' ? '등록' : '상세보기 및 수정' }</h6></div>
                {params?.mode != 'reg' && (
                    <a href="#" className="comm-btn btn-md btn-gray bd-radius-3" 
                      onClick={(e) => { 
                        e.preventDefault()
                        setEditMode(!editMode)
                      }}
                    >
                      { !editMode ? '수정' : '보기' }
                    </a>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="table mb-50 mt-10">
                <div className="table-head">
                    <h6 className="table-title">관리자 정보</h6>
                </div>
                <div className="table-body">
                  <table border="0" cellSpacing="0" cellPadding="0">
                    <colgroup>
                        <col width="15%" />
                        <col width="35%" />
                        <col width="15%" />
                        <col width="35%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td className="tac bg">ID <span className="required"><span>필수입력</span></span></td>
                        <td className="tac">
                          <div className="d-flex" style={{ alignItems: "center" }}>
                            <div className="me-10">
                              <input type="text" id="account" className="int" placeholder="아이디" value={account} disabled={params?.mode === 'edit' || !editMode ? true : false}
                                onChange={(e) => {
                                  setAccount(e.target.value)
                                }} />
                            </div>
                            <div>
                              { 
                                params?.mode === 'reg'
                                ? (
                                  overlap 
                                  ? (
                                    <span className="text-success"> 사용 가능한 아이디입니다. </span>
                                  ) 
                                  : (
                                    <button type="submit" className="comm-btn bd-radius-5 btn-primary" 
                                    onClick={() => idOverLapCheck()} >중복 확인</button>
                                  )
                                )
                                : (
                                  <></>
                                )
                              }
                            </div>
                          </div>
                        </td>
                        <td className="tac bg">이름 <span className="required"><span>필수입력</span></span></td>
                        <td className="tac">
                          <input type="text" id="name" className="int" placeholder="이름" value={name} disabled={!editMode}
                            onChange={(e) => {
                              setName(e.target.value)
                            }} />
                        </td>
                      </tr>
                      <tr>
                        <td className="tac bg">비밀번호 <span className="required"><span>필수입력</span></span></td>
                        <td className="tac">
                          <input type="password" id="password" className="int" placeholder="비밀번호" value={password} disabled={!editMode}
                            onChange={(e) => {
                              setPassword(e.target.value)
                            }} />
                        </td>
                        <td className="tac bg">비밀번호 확인 <span className="required"><span>필수입력</span></span></td>
                        <td className="tac">
                          <input type="password" id="passwordRe" className="int" placeholder="비밀번호 확인" value={passwordRe} disabled={!editMode}
                            onChange={(e) => {
                              setPasswordRe(e.target.value)
                            }} />
                        </td>
                      </tr>
                      <tr>
                        <td className="tac bg">이메일 <span className="required"><span>필수입력</span></span></td>
                        <td className="tac">
                          <input type="text" id="email" className="int" placeholder="이메일" value={email} disabled={!editMode}
                            onChange={(e) => {
                              setEmail(e.target.value)
                            }} />
                        </td>
                        <td className="tac bg">전화번호</td>
                        <td className="tac">
                          <div className="d-flex">
                            <select id="active" className="int me-10" style={{ width: '80px' }}
                              onChange={(e) => {
                                setTelCountry(e.target.value)
                              }} 
                              value={telCountry}
                              disabled={!editMode}
                            >
                              {countryCode?.length != 0 ? (
                                countryCode?.map((item, idx) => (
                                  <option value={ item?.code } key={ idx }>{item?.code}</option>
                                ))
                              ): (
                                <option value={ '+82' } key={ 0 }>+82</option>
                              )}
                            </select>
                            <input type="number" id="sTel" className="int me-10" style={{ width: '256px' }} value={tel} disabled={!editMode}
                              onInput={(e) => { e.target.value = onlyNumber(e.target.value) }}
                              onChange={(e) => {
                                if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11)
                                setTel(e.target.value)
                              }} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="tac bg">휴대폰번호 <span className="required"><span>필수입력</span></span></td>
                        <td className="tac">
                          <div className="d-flex">
                            <select id="active" className="int me-10" style={{ width: '80px' }}
                              onChange={(e) => {
                                setPhoneCountry(e.target.value)
                              }} 
                              value={phoneCountry}
                              disabled={!editMode}
                            >
                              {countryCode?.length != 0 ? (
                                countryCode?.map((item, idx) => (
                                  <option value={ item?.code } key={ idx }>{item?.code}</option>
                                ))
                              ): (
                                <option value={ '+82' } key={ 0 }>+82</option>
                              )}
                            </select>
                            <input type="number" id="sPhone" className="int me-10" style={{ width: '256px' }} value={phone} disabled={!editMode}
                              onInput={(e) => { e.target.value = onlyNumber(e.target.value) }}
                              onChange={(e) => {
                                if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11)
                                setPhone(e.target.value)
                              }} />
                          </div>
                        </td>
                        <td className="tac bg">비밀번호 변경일</td>
                        <td className="tac">
                          { admin?.passChangeAt != '' && admin?.passChangeAt 
                            ? setDetailDateFormat(admin?.passChangeAt) 
                            : '-' }
                        </td>
                      </tr>
                      <tr>
                        <td className="tac bg">최종로그인 성공일</td>
                        <td className="tac">
                          { admin?.loginAt != '' && admin?.loginAt 
                            ? setDetailDateFormat(admin?.loginAt) 
                            : '-' }
                        </td>
                        <td className="tac bg">최종로그인 실패일</td>
                        <td className="tac">
                          { admin?.lockedAt != '' && admin?.lockedAt 
                            ? setDetailDateFormat(admin?.lockedAt) 
                            : '-' }
                        </td>
                      </tr>
                      <tr>
                        <td className="tac bg">계정등록일</td>
                        <td className="tac">
                          { admin?.createdAt != '' && admin?.createdAt 
                          ? setDetailDateFormat(admin?.createdAt) 
                          : '-' }
                        </td>
                        <td className="tac bg">계정상태</td>
                        <td className="tac">
                          <select id="active" className="int" style={{ width: '128px' }}
                            onChange={(e) => {
                              setActive(e.target.value)
                            }} 
                            value={active}
                            disabled={!editMode}
                          >
                            <option value={ true } key={ true }>활성</option>
                            <option value={ false } key={ false }>비활성</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            { params?.mode == 'reg' 
              ? (
                <div className="form-btn justify-content-center mt-30">
                  <div className="me-10">
                    <button type="submit" className="comm-btn bd-radius-5 btn-danger" 
                      onClick={() => cancelContents()} >취소</button>
                  </div>
                  <div>
                    <button type="submit" className="comm-btn bd-radius-5 btn-gray" 
                      onClick={() => insertAdmin()} >저장</button>
                  </div>
                </div>
              )
              : editMode 
                ? (
                  <div className="form-btn justify-content-center mt-30">
                    <div className="me-10">
                      <button type="submit" className="comm-btn bd-radius-5 btn-danger" 
                      onClick={() => cancelContents()} >취소</button>
                    </div>
                    <div>
                      <button type="submit" className="comm-btn bd-radius-5 btn-gray" 
                        onClick={() => updateAdmin()} >수정</button>
                    </div>
                  </div>
                )
                : (
                  <div className="form-btn justify-content-center mt-30">
                    <Link to={`/system/admin`}>
                      <button type="submit" className="comm-btn bd-radius-5 btn-gray"
                        onClick={() => {}} >목록</button>
                    </Link>
                  </div>
                )
            }
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default BannerManagement
