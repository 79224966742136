import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { MENUITEMS } from '../../data/menu'

const SideMenu = () => {
  const winUrl = window.location.pathname
  const path = winUrl.split('/')
  const [selMenu, setSelMenu] = useState(-1)

  useEffect(() => {
    MENUITEMS.Items.map((menu, idx) =>
      menu.path.includes(path[1]) ? setSelMenu(idx) : null
    )
  }, [winUrl])

  return (
    <Fragment>
      <nav className="header-aside">
        <h1 className="header-logo">
          <a href="index.html" className="logo-main">
            <img
              src={require('../../assets/images/logo/logo.png')}
              alt="로고"
            />
          </a>
          {/* <a href="index.html" className="logo-icon">
            <img
              src={require('../../assets/images/logo/ico_logo.png')}
              alt=""
            />
          </a> */}
        </h1>
        <ul className="aside-menu">
          {MENUITEMS.Items.map((menu, idx) => (
            <li
              key={idx}
              className={
                (menu.path.includes(path[1]) ? 'on' : '') +
                (selMenu === idx ? ' ov' : '')
              }
            >
              {menu.type === 'sub' ? (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    if (selMenu === idx) {
                      setSelMenu(-1)
                    } else {
                      setSelMenu(idx)
                    }
                  }}
                >
                  <span className="menu-icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d={menu.icon} fill="#1D1D1D" />
                    </svg>
                  </span>
                  <span className="menu-text">{menu.title}</span>
                </a>
              ) : (
                <Link to={menu.path}>
                  <span className="menu-icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d={menu.icon} fill="#1D1D1D" />
                    </svg>
                  </span>
                  <span className="menu-text">{menu.title}</span>
                </Link>
              )}

              {menu.children.length > 0 && (
                <div className="aside-menu-sub">
                  <ul
                    style={{
                      display: selMenu === idx ? 'block' : 'none',
                    }}
                  >
                    {menu.children.map((ch, idx1) => (
                      <li
                        key={idx1}
                        className={winUrl.includes(ch.path) ? 'on' : ''}
                      >
                        <Link to={ch.path}>{ch.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
        {/* 기획 없음 */}
        {/* <div className="aside-banner">
          <a
            href="./pages/component/dropdown.html"
            target="_blank"
            className="d-flex align-center"
          >
            <span className="menu-icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_37_2)">
                  <path
                    d="M17 3H7C5.9 3 5.01 3.9 5.01 5L5 21L12 18L19 21V5C19 3.9 18.1 3 17 3Z"
                    fill="#238dc1"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_37_2">
                    <rect width="24" height="24" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </span>{' '}
            <span className="menu-text">Component</span>
          </a>
        </div> */}
      </nav>
    </Fragment>
  )
}

export default SideMenu
