import React, { Fragment, useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import { getCorporateDetail } from '../../../api/member'
import { getUrlParams, setDetailDateFormat, isEmpty } from '../../../utils/index'
import { MENUITEMS } from '../../../data/menu'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const UserManagement = () => {
  const dispatch = useAppDispatch()

  const [basicInfo, setBasicInfo] = useState(null)

  useEffect(() => {
    dispatch(setLoading(false))
    
    // ?cid=5
    // 기업 회원 기본정보
    callBasicInfo(getUrlParams(window.location.href))
    const temp = MENUITEMS.Items.filter(
      (menu) =>
        menu.children.filter((ch) => ch.path == window.location.pathname)
          .length != 0
    )
  }, [])

  const callBasicInfo = async (param) => {
    if(Object.keys(param).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getCorporateDetail(param.cid).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        const chkAddressKey = ['addressDetails', 'address', 'city', 'state', 'postcode']
        const joinAddress = []
        for(let i = 0; i<chkAddressKey.length; i++ ) {
          if(Object.keys(data?.companyContact?.company).includes(chkAddressKey[i])) {
            if(!isEmpty(data?.companyContact?.company[chkAddressKey[i]])) {
              joinAddress.push(data?.companyContact?.company[chkAddressKey[i]])
            }
          }
        }

        setBasicInfo({ ...data, addressSum: joinAddress.join(', ') || '-' })
      } else {
        setBasicInfo({})
      }
    }
  }

  const codeViewer = (param, param2) => {
    const arr = []
    if(!isEmpty(param)) {
      arr.push(param)
    }
    if(!isEmpty(param2)) {
      arr.push(param2)
    }
    const joinArr = arr.join(' - ')

    return joinArr.length > 0 ? joinArr : '-'
  }

  return (
    <Fragment>
      <Container>
        <div className="contents">
          {/** 기본정보 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
                <h6 className="table-title">기본정보</h6>
            </div>
            <div className="table-body not-hover">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                    <col width="15%" />
                    <col width="35%" />
                    <col width="15%" />
                    <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg">ID</td>
                    <td className="tac">{basicInfo?.user?.email || '-'}</td>
                    <td className="tac bg">담당자명</td>
                    <td className="tac">{basicInfo?.user?.name || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">담당자 소속팀</td>
                    <td className="tac">{ codeViewer(basicInfo?.companyContact?.department, basicInfo?.companyContact?.position) }</td>
                    <td className="tac bg">연락처</td>
                    <td className="tac">{basicInfo?.companyContact?.phone || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">기업명</td>
                    <td className="tac">{basicInfo?.companyContact?.company?.name || '-'}</td>
                    <td className="tac bg">사업자등록번호</td>
                    <td className="tac">{basicInfo?.companyContact?.company?.businessRegistrationNumber || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">기업 주소</td>
                    <td className="tac" colSpan="3">{basicInfo?.addressSum || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">산업군</td>
                    <td className="tac">{ codeViewer(basicInfo?.companyContact?.company?.industryCategoryEn, basicInfo?.companyContact?.company?.jobPositionEn) }</td>
                    <td className="tac bg">기업규모</td>
                    <td className="tac">{basicInfo?.companyContact?.company?.companySizeEn || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">대표 웹사이트</td>
                    <td className="tac">{basicInfo?.companyContact?.company?.website || '-'}</td>
                    <td className="tac bg">기업 닉네임</td>
                    <td className="tac">{basicInfo?.companyContact?.company?.nickname || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">회원가입일</td>
                    <td className="tac">
                      {basicInfo?.user?.createdAt != '' && basicInfo?.user?.createdAt 
                      ? setDetailDateFormat(basicInfo?.user?.createdAt) 
                      : '-'}
                    </td>
                    <td className="tac bg">최종로그인</td>
                    <td className="tac">
                      {basicInfo?.user?.lastLoginAt != '' && basicInfo?.user?.lastLoginAt 
                      ? setDetailDateFormat(basicInfo?.user?.lastLoginAt) 
                      : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default UserManagement
