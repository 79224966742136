// import styles from '../contents/QuillEditor.module.css';
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import htmlEditButton from "quill-html-edit-button"
import { useRef, useMemo, useState, useEffect } from 'react'

const Block = Quill.import('blots/block')
Block.tagName = 'DIV'
Block.className = 'main__content'
Quill.register(Block, true);
Quill.register('modules/htmlEditButton', htmlEditButton)

export default function QuillEditor({ html, setHtml, image, setImage = () => {}, readOnly}) {
  const quillRef = useRef(null);
  const [quillValue, setQuillValue] = useState(null);
  const [quillImage, setQuillImage] = useState([]);

  useEffect(() => {
    if(image && image.length > 0) {
      setQuillImage([...image])
    }
  }, [])

  useEffect(() => {
    setImage([ ...quillImage ])
  }, [quillImage])

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.addEventListener('change', async () => {
      const file = input.files[0];
      const reader = new FileReader();
      try {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const editor = quillRef.current.getEditor() 
          const range = editor.getSelection()
          editor.insertEmbed(range.index, 'image', reader.result)
          editor.setSelection(range.index + 1)
          setQuillImage((prev) => [...prev, {
            file,
            baseFile: reader.result
          }]);
        };
      } catch (error) {
        console.log(error);
      }
    });
  }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: '1' }, { header: '2' }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
          ['image'],
        ],
        handlers: { image: imageHandler },
      },
      clipboard: {
        matchVisual: false,
      },
      htmlEditButton: {
        debug: true, // logging, default:false
        msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
        okText: "Ok", // Text to display in the OK button, default: Ok,
        cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
        buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
        buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
        syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
        prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
        editorModules: {} // The default mod
      },
    }),
    [],
  );

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'align',
    'image',
  ];

  return (
    <div style={{width: "100%", height: "342px"}} >
      <ReactQuill
        readOnly={!readOnly}
        ref={quillRef}
        onChange={setHtml ?? ((e) => {
          setQuillValue(e);
        })}
        modules={modules}
        formats={formats}
        value={html ?? quillValue}
        placeholder={'내용을 작성해주세요.'}
        theme="snow"
        style={{height: "300px"}}
      />
    </div>
  );
}