export const MENUITEMS = {
  menutitle: '메뉴 타이틀',
  menucontent: '메뉴 내용',
  Items: [
    {
      title: 'Home',
      path: '/home',
      icon: 'M2 10.8889H9.11111V2H2V10.8889ZM2 18H9.11111V12.6667H2V18ZM10.8889 18H18V9.11111H10.8889V18ZM10.8889 2V7.33333H18V2H10.8889Z',
      type: 'link',
      active: false,
      children: [],
    },
    {
      title: '회원 관리',
      path: '/user',
      icon: 'M10.0001 1.66675C5.40008 1.66675 1.66675 5.40008 1.66675 10.0001C1.66675 14.6001 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6001 18.3334 10.0001C18.3334 5.40008 14.6001 1.66675 10.0001 1.66675ZM10.0001 4.16675C11.3834 4.16675 12.5001 5.28341 12.5001 6.66675C12.5001 8.05008 11.3834 9.16675 10.0001 9.16675C8.61675 9.16675 7.50008 8.05008 7.50008 6.66675C7.50008 5.28341 8.61675 4.16675 10.0001 4.16675ZM10.0001 16.0001C7.91675 16.0001 6.07508 14.9334 5.00008 13.3167C5.02508 11.6584 8.33341 10.7501 10.0001 10.7501C11.6584 10.7501 14.9751 11.6584 15.0001 13.3167C13.9251 14.9334 12.0834 16.0001 10.0001 16.0001Z',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/user/management`,
          title: '회원 정보',
          type: 'link',
          children: [
            {
              path: `/user/management/detail/intern`,
              title: '인턴 회원 상세',
              type: 'link',
            },
            {
              path: `/user/management/detail/corporate`,
              title: '기업 회원 상세',
              type: 'link',
            },
            {
              path: `/user/management/detail/university`,
              title: '대학 회원 상세',
              type: 'link',
            },
          ],
        },
        {
          path: `/user/withdrawn`,
          title: '탈퇴 회원 정보',
          type: 'link',
          children: [],
        },
        {
          path: `/user/internship`,
          title: '인턴쉽 지원 현황',
          type: 'link',
          children: [
            {
              path: `/user/internship/detail`,
              title: '인턴쉽 지원 상세',
              type: 'link',
            },
          ],
        },
        {
          path: `/user/globalcamp`,
          title: '글로벌 캠프 지원 현황',
          type: 'link',
          children: [
            {
              path: `/user/globalcamp/detail`,
              title: '글로벌 캠프 지원 상세',
              type: 'link',
            },
          ],
        },
      ],
    },
    {
      title: '컨텐츠 관리',
      path: '/contents',
      icon: 'M15.8333 1.66667H12.35C12 0.7 11.0833 0 10 0C8.91667 0 8 0.7 7.65 1.66667H4.16667C3.25 1.66667 2.5 2.41667 2.5 3.33333V16.6667C2.5 17.5833 3.25 18.3333 4.16667 18.3333H15.8333C16.75 18.3333 17.5 17.5833 17.5 16.6667V3.33333C17.5 2.41667 16.75 1.66667 15.8333 1.66667ZM10 1.66667C10.4583 1.66667 10.8333 2.04167 10.8333 2.5C10.8333 2.95833 10.4583 3.33333 10 3.33333C9.54167 3.33333 9.16667 2.95833 9.16667 2.5C9.16667 2.04167 9.54167 1.66667 10 1.66667ZM15.8333 16.6667H4.16667V3.33333H5.83333V5.83333H14.1667V3.33333H15.8333V16.6667Z',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/contents/internship/recruitments`,
          title: '인턴십 채용 공고',
          type: 'link',
          children: [
            {
              path: `/contents/internship/recruitments/detail`,
              title: '인턴십 채용 공고 상세 및 수정',
              type: 'link',
            },
          ],
        },
        {
          path: `/contents/internship/applications`,
          title: '인턴십 지원 현황',
          type: 'link',
          children: [],
        },
        {
          path: `/contents/internship/story`,
          title: '인턴십 스토리 관리',
          type: 'link',
          children: [
            {
              path: `/contents/internship/story/detail`,
              title: '인턴십 스토리 상세 및 수정',
              type: 'link',
            },
          ],
        },
        {
          path: `/contents/globalcamp/manage`,
          title: '글로벌 캠프 관리',
          type: 'link',
          children: [
            {
              path: `/contents/globalcamp/manage/detail`,
              title: '글로벌 캠프 상세 및 수정',
              type: 'link',
            },
          ],
        },
        {
          path: `/contents/globalcamp/application`,
          title: '글로벌 캠프 지원 현황',
          type: 'link',
          children: [
            {
              path: `/contents/globalcamp/application/detail`,
              title: '글로벌 캠프 상세 및 수정',
              type: 'link',
            },
          ],
        },
        {
          path: `/contents/preInterview`,
          title: '사전 인터뷰 관리',
          type: 'link',
          children: [],
        },
      ],
    },
    {
      title: '결제 관리',
      path: '/payment',
      icon: 'M15.8333 5.83333H7.5C6.58333 5.83333 5.83333 6.58333 5.83333 7.5V15.8333C5.83333 16.75 6.58333 17.5 7.5 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V7.5C17.5 6.58333 16.75 5.83333 15.8333 5.83333ZM15.8333 7.5V9.16667H7.5V7.5H15.8333ZM10.8333 12.5V10.8333H12.5V12.5H10.8333ZM12.5 14.1667V15.8333H10.8333V14.1667H12.5ZM9.16667 12.5H7.5V10.8333H9.16667V12.5ZM14.1667 10.8333H15.8333V12.5H14.1667V10.8333ZM7.5 14.1667H9.16667V15.8333H7.5V14.1667ZM14.1667 15.8333V14.1667H15.8333V15.8333H14.1667ZM5 14.1667H4.16667C3.25 14.1667 2.5 13.4167 2.5 12.5V4.16667C2.5 3.25 3.25 2.5 4.16667 2.5H12.5C13.4167 2.5 14.1667 3.25 14.1667 4.16667V5H12.5V4.16667H4.16667V12.5H5V14.1667Z',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/payment/information`,
          title: '입금 정보',
          type: 'link',
          children: [],
        },
        {
          path: `/payment/history`,
          title: '매출 내역',
          type: 'link',
          children: [],
        },
      ],
    },
    {
      title: '고객센터',
      path: '/customer',
      icon: 'M10.0001 1.66675C5.40008 1.66675 1.66675 5.40008 1.66675 10.0001C1.66675 14.6001 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6001 18.3334 10.0001C18.3334 5.40008 14.6001 1.66675 10.0001 1.66675ZM10.0001 4.16675C11.3834 4.16675 12.5001 5.28341 12.5001 6.66675C12.5001 8.05008 11.3834 9.16675 10.0001 9.16675C8.61675 9.16675 7.50008 8.05008 7.50008 6.66675C7.50008 5.28341 8.61675 4.16675 10.0001 4.16675ZM10.0001 16.0001C7.91675 16.0001 6.07508 14.9334 5.00008 13.3167C5.02508 11.6584 8.33341 10.7501 10.0001 10.7501C11.6584 10.7501 14.9751 11.6584 15.0001 13.3167C13.9251 14.9334 12.0834 16.0001 10.0001 16.0001Z',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/customer/faq`,
          title: 'FAQ',
          type: 'link',
          children: [
            {
              path: `/customer/faq/detail`,
              title: 'FAQ 상세 및 수정',
              type: 'link',
            },
          ],
        },
      ],
    },
    {
      title: '통계',
      path: '/statistics',
      icon: 'M4.16675 7.66663H6.66675V15.8333H4.16675V7.66663ZM8.83342 4.16663H11.1667V15.8333H8.83342V4.16663ZM13.5001 10.8333H15.8334V15.8333H13.5001V10.8333Z',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/statistics/joinSecession`,
          title: '가입/탈퇴 통계',
          type: 'link',
          children: [],
        },
        {
          path: `/statistics/matchingPass`,
          title: '매칭/합격 통계',
          type: 'link',
          children: [],
        },
      ],
    },
    {
      title: '시스템 관리',
      path: '/system',
      icon: 'M15.8333 1.66667H12.35C12 0.7 11.0833 0 10 0C8.91667 0 8 0.7 7.65 1.66667H4.16667C3.25 1.66667 2.5 2.41667 2.5 3.33333V16.6667C2.5 17.5833 3.25 18.3333 4.16667 18.3333H15.8333C16.75 18.3333 17.5 17.5833 17.5 16.6667V3.33333C17.5 2.41667 16.75 1.66667 15.8333 1.66667ZM10 1.66667C10.4583 1.66667 10.8333 2.04167 10.8333 2.5C10.8333 2.95833 10.4583 3.33333 10 3.33333C9.54167 3.33333 9.16667 2.95833 9.16667 2.5C9.16667 2.04167 9.54167 1.66667 10 1.66667ZM15.8333 16.6667H4.16667V3.33333H5.83333V5.83333H14.1667V3.33333H15.8333V16.6667Z',
      type: 'sub',
      active: false,
      children: [
        {
          path: `/system/banner`,
          title: '배너 관리',
          type: 'link',
          children: [
            {
              path: `/system/banner/detail`,
              title: '배너 상세 및 수정',
              type: 'link',
            },
          ],
        },
        // {
        //   path: `/system/popup`,
        //   title: '팝업 관리',
        //   type: 'link',
        //   children: [],
        // },
        {
          path: `/system/admin`,
          title: '관리자 관리',
          type: 'link',
          children: [
            {
              path: `/system/admin/detail`,
              title: '관리자 관리 상세 및 수정',
              type: 'link',
            },
          ],
        },
      ],
    },
  ],
}
