import ApiBase from "./apiBase";

// API instance
const Api = new ApiBase("/customer-support");

// 고객지원 > FAQ
export const getCustomerFaq = async (params) => {
  return await Api.submit("get", `faq-list`, params);
};

// 고객지원 > FAQ > 노출여부 수정
export const updateCustomerFaqYn = async (id, params) => {
  return await Api.submit("post", `faq-list/detail/setting/${id}`, params);
};

// 고객지원 > FAQ > 등록
export const insertCustomerFaq = async (params) => {
  return await Api.submit("post", `faq-list`, params);
};

// 고객지원 > FAQ > 상세
export const getCustomerFaqDetail = async (id) => {
  return await Api.submit("get", `faq-list/${id}`);
};

// 고객지원 > FAQ > 수정
export const updateCustomerFaq = async (id, params) => {
  return await Api.submit("post", `faq-list/${id}`, params);
};
