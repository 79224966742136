import ApiBase from "./apiBase";

// API instance
const Api = new ApiBase("/member");

// 인턴 조회 API
export const getIntern = async (params) => {
  return await Api.submit("get", "interns", params);
};

// 인턴 상세조회 API > 인턴 정보
export const getInternDetail = async (params) => {
  return await Api.submit("get", `interns/${params}`);
};

// 인턴 상세조회 API > 결제 내역
export const getInternPayment = async (params) => {
  return await Api.submit("get", `interns/${params}/payments`);
};

// 인턴 상세조회 API > 매칭 내역(지원 내역)
export const getInternDetailApplication = async (params) => {
  return await Api.submit("get", `interns/${params}/internship-applications`);
};

// 인턴 상세조회 API > 이력서
export const getInternResume = async (params) => {
  return await Api.submit("get", `interns/${params}/resume`);
};

// 기업 조회 API
export const getCorporate = async (params) => {
  return await Api.submit("get", "corporate-users", params);
};

// 기업 상세조회 API
export const getCorporateDetail = async (params) => {
  return await Api.submit("get", `corporate-users/${params}`);
};

// 대학 조회 API
export const getUniversity = async (params) => {
  return await Api.submit("get", "university-users", params);
};

// 대학 상세조회 API
export const getUniversityDetail = async (params) => {
  return await Api.submit("get", `university-users/${params}`);
};

// 대학 상세조회 API > 학생 리스트
export const getStudentUser = async (params) => {
  return await Api.submit("get", `student-users`, params);
};

// 대학 상세조회 API > 대학교 티어 수정
export const updateUniversityTier = async (id, params) => {
  return await Api.submit("post", `university/tier/${id}`, params);
};

// 지원 및 결제 > 채용 공고
export const getApplicationInternship = async (params) => {
  return await Api.submit("get", `application/internship`, params);
};

// 지원 및 결제 > 글로벌 캠프
export const getApplicationGlobalCamp = async (params) => {
  return await Api.submit("get", `application/global-camp`, params);
};

// 회원, 게시판 > 글로벌 캠프 지원 현황 > 결제 전 지원 취소
export const updateApplicationCancel = async (id) => {
  return await Api.submit("post", `application/global-camp/cancel/${id}`);
};

// 회원, 게시판 > 글로벌 캠프 지원 현황 > 결제 처리
export const updateApplicationPaid = async (id, params) => {
  return await Api.submit("post", `application/global-camp/paid/${id}`, params);
};

// 회원, 게시판 > 글로벌 캠프 지원 현황 > 결제 처리
export const updateApplicationRefund = async (id, params) => {
  return await Api.submit(
    "post",
    `application/global-camp/refund/${id}`,
    params
  );
};

// 회원 > 기업, 대학 회원 조회 > 승인 처리
export const updateStatusApproval = async (id) => {
  return await Api.submit("post", `users/status/${id}`);
};

// 회원, 게시판 > 인턴쉽 지원 현황 > 상세 > ThunderJob Score 수정
export const updateThunderJobScore = async (id, params) => {
  return await Api.submit("post", `application/internship/score/${id}`, params);
};
