import React, { Fragment, useState } from 'react'
import Header from '../layout/header'
import Footer from '../layout/footer'
import Navigation from '../layout/navigation'
import { ToastContainer } from 'react-toastify'
import { Outlet } from 'react-router-dom'

const AppLayout = ({ children, classNames, ...rest }) => {
  const [fold, setFold] = useState(false)
  return (
    <Fragment>
      <div id="wrap" className={fold ? 'folding' : ''}>
        <Header fold={fold} setFold={setFold} />
        <div id="container">
          <div className="l-maxWidth">
            <Navigation />
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer />
    </Fragment>
  )
}

export default AppLayout
