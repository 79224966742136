import React, { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'
import dayjs from 'dayjs'
import SearchFilter from '../../common/searchFilter'
import PaginationComponent from '../../common/pagination'
import {
  getInternshipRecruitment,
  updateStatus,
  updateHotPosition,
  updateSpecial,
} from '../../../api/post'
import { comma, setDateTime, setDetailDateFormat, getUrlParams, isEmpty } from '../../../utils/index'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const ContentsManagement = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const urlParam = getUrlParams(window.location.href)
  const [complete, setComplete] = useState(false)
  const [recordsTotal, setRecordsTotal] = useState(300)
  const [recruimentList, setRecruimentList] = useState(null)

  const [params, setParams] = useState({
    filter: {
      startDate: new Date(
        dayjs().subtract(1, 'y').format('YYYY.MM.DD 00:00:00')
      ),
      endDate: new Date(dayjs().format('YYYY.MM.DD 23:59:59')),
      searchTxt: '',
      companyTxt: '',
    },
    pageNum: 1,
    listNum: 10,
  })

  const [filter, setFilter] = useState({
    startDate: new Date(dayjs().subtract(1, 'y').format('YYYY.MM.DD 00:00:00')),
    endDate: new Date(dayjs().format('YYYY.MM.DD 23:59:59')),
    searchTxt: '',
    companyTxt: '',
  })

  const status = {
    APPROVAL_REQUEST: '승인 요청',
    APPROVAL: '승인',
    REJECT: '거절',
    CANCEL: '취소',
  }

  const callApplication = async () => {
    dispatch(setLoading(true))

    const { code, data } = await getInternshipRecruitment({
      searchWord: params.filter.searchTxt,
      companyWord: isEmpty(params.filter.companyTxt) ? null : params.filter.companyTxt,

      startAt: setDateTime(params.filter.startDate),
      endAt: setDateTime(params.filter.endDate),
      offset: (params.pageNum - 1) * params.listNum,
      limit: params.listNum,
      sort: 'DESC',
    }).finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      setRecruimentList([...data?.items])
      setRecordsTotal(data.totalCount)
    } else {
      setRecruimentList([])
      setRecordsTotal(0)
    }
  }

  const statusDrop = (idx, mode) => {
    document
      .getElementById(`${mode}_dropDown_${idx}`)
      .classList.toggle('active')
  }
  const statusChg = async (value, idx, mode) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`${status[value]} 상태로 변경 하시겠습니까?`)) {
      document.getElementById(`${mode}_${idx}`).innerHTML = status[value]
      if (recruimentList[idx][mode] !== value) {
        dispatch(setLoading(true))

        const { code } = await updateStatus(recruimentList[idx].id, {
          [mode]: value,
        }).finally(() => {
          dispatch(setLoading(false))
        })

        if (code == 200) {
          alert('변경 완료')
        } else {
          alert('변경 실패')
        }
      }
    }
  }

  const hotSpecialChg = async (value, idx, mode) => {
    document.getElementById(`${mode}_${idx}`).innerHTML = value
      ? '적용'
      : '미적용'
    if (recruimentList[idx][mode] !== value) {
      dispatch(setLoading(true))

      if (mode === 'hotPosition') {
        await updateHotPosition(recruimentList[idx].id, {
          [mode]: value,
        }).finally(() => {
          dispatch(setLoading(false))
        })
      } else if (mode === 'special') {
        await updateSpecial(recruimentList[idx].id, { [mode]: value }).finally(
          () => {
            dispatch(setLoading(false))
          }
        )
      }
    }
  }

  const pageReplace = (param) => {
    navigate(
      `/contents/internship/recruitments?filter=${encodeURI(
        JSON.stringify(param.filter)
      )}&pageNum=${param.pageNum}&listNum=${param.listNum}`,
      { replace: true }
    )
  }

  useEffect(() => {
    if (Object.keys(getUrlParams(window.location.href)).length == 0) {
      pageReplace(params)
    }
  }, [getUrlParams(window.location.href)])

  useEffect(() => {
    init()
  }, [
    urlParam['filter'],
    urlParam['pageNum'],
    urlParam['listNum'],
  ])

  const init = () => {
    setParams({
      filter: urlParam['filter']
        ? JSON.parse(decodeURI(urlParam['filter']))
        : params.filter,
      pageNum: urlParam['pageNum'] ? urlParam['pageNum'] : params.pageNum,
      listNum: urlParam['listNum'] ? urlParam['listNum'] : params.listNum,
    })
    setFilter(
      urlParam['filter']
        ? JSON.parse(decodeURI(urlParam['filter']))
        : params.filter
    )
    setComplete(true)
  }

  useEffect(() => {
    if (complete) {
      callApplication()
    }
  }, [params.filter, params.pageNum, params.listNum])

  return (
    <Fragment>
      <Container>
        <div className="contents">
          <SearchFilter
            filter={filter}
            setFilter={(filter) => {
              setFilter({ ...filter })
            }}
            search={() =>
              pageReplace({ ...params, filter: { ...filter }, pageNum: 1 })
            }
          />
          {recruimentList && (
            <div className="table">
              <div className="table-head">
                <p className="total">
                  총 <strong>{comma(recordsTotal)}</strong>건
                </p>
                <div className="table-filter d-flex">
                  <label htmlFor="number-outputs" className="lbl me-5">
                    출력갯수 :
                  </label>
                  <select
                    name="number-outputs"
                    id="number-outputs"
                    className="int me-5"
                    value={params.listNum}
                    onChange={(e) => {
                      pageReplace({
                        ...params,
                        listNum: Number(e.target.value),
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  {/* 기획 없음 */}
                  {/* <select name="" id="" className="int">
                      <option value="">정렬방식</option>
                      <option value="" selected>
                        등록일 빠른순
                      </option>
                      <option value="">등록일 느린순</option>
                    </select> */}
                </div>
              </div>
              {/* //table-head */}
              <div className="table-body">
                <table border="0" cellSpacing="0" cellPadding="0">
                  <colgroup>
                    <col width="55px" />
                    <col width="15%" />
                    <col width="10%" />
                    <col width="*" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="15%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <div className="th-move">No</div>
                      </th>
                      <th>ID</th>
                      <th>기업명</th>
                      <th>지원공고 명</th>
                      <th>hot position</th>
                      <th>special</th>
                      <th>진행상태</th>
                      <th>작성 일시</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recruimentList?.length != 0 ? (
                      recruimentList.map((item, idx) => (
                        <tr key={idx}>
                          <td className="tac">
                            {recordsTotal - ((params.pageNum - 1) * params.listNum + idx)}
                          </td>
                          <td className="tac">{item?.contactAccount || '-'}</td>
                          <td className="tac">{item?.company?.name || '-'}</td>
                          <td className="tac">
                            {item?.title ? (
                              <Link
                                className="text-btn btn-primary"
                                to={`/contents/internship/recruitments/detail?rid=${item?.id}`}
                              >
                                {item?.title}
                              </Link>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td className="tac overflow">
                            <div
                              className="dropdown"
                              id={`hotPosition_dropDown_${idx}`}
                              onClick={() => {
                                statusDrop(idx, 'hotPosition')
                              }}
                            >
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                }}
                                className="int dropdown-title"
                                id={`hotPosition_${idx}`}
                              >
                                {item?.hotPosition ? '적용' : '미적용'}
                              </a>
                              <div className="dropdown-menu">
                                <div className="dim"></div>
                                <ul className="dropdown-list">
                                  <li
                                    onClick={() => {
                                      hotSpecialChg(true, idx, 'hotPosition')
                                    }}
                                  >
                                    <a
                                      className="dropdown-li-a"
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault()
                                      }}
                                    >
                                      적용
                                    </a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      hotSpecialChg(false, idx, 'hotPosition')
                                    }}
                                  >
                                    <a
                                      className="dropdown-li-a"
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault()
                                      }}
                                    >
                                      미적용
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td className="tac overflow">
                            <div
                              className="dropdown"
                              id={`special_dropDown_${idx}`}
                              onClick={() => {
                                statusDrop(idx, 'special')
                              }}
                            >
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                }}
                                className="int dropdown-title"
                                id={`special_${idx}`}
                              >
                                {item?.special ? '적용' : '미적용'}
                              </a>
                              <div className="dropdown-menu">
                                <div className="dim"></div>
                                <ul className="dropdown-list">
                                  <li
                                    onClick={() => {
                                      hotSpecialChg(true, idx, 'special')
                                    }}
                                  >
                                    <a
                                      className="dropdown-li-a"
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault()
                                      }}
                                    >
                                      적용
                                    </a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      hotSpecialChg(false, idx, 'special')
                                    }}
                                  >
                                    <a
                                      className="dropdown-li-a"
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault()
                                      }}
                                    >
                                      미적용
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td className="tac overflow">
                            <div
                              className="dropdown"
                              id={`status_dropDown_${idx}`}
                              onClick={() => {
                                statusDrop(idx, 'status')
                              }}
                            >
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                }}
                                className="int dropdown-title"
                                id={`status_${idx}`}
                              >
                                {status[item?.status]}
                              </a>
                              <div className="dropdown-menu">
                                <div className="dim"></div>
                                <ul className="dropdown-list">
                                  <li
                                    onClick={() => {
                                      statusChg(
                                        'APPROVAL_REQUEST',
                                        idx,
                                        'status'
                                      )
                                    }}
                                  >
                                    <a
                                      className="dropdown-li-a"
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault()
                                      }}
                                    >
                                      승인 요청
                                    </a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      statusChg('APPROVAL', idx, 'status')
                                    }}
                                  >
                                    <a
                                      className="dropdown-li-a"
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault()
                                      }}
                                    >
                                      승인
                                    </a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      statusChg('REJECT', idx, 'status')
                                    }}
                                  >
                                    <a
                                      className="dropdown-li-a"
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault()
                                      }}
                                    >
                                      거절
                                    </a>
                                  </li>
                                  <li
                                    onClick={() => {
                                      statusChg('CANCEL', idx, 'status')
                                    }}
                                  >
                                    <a
                                      className="dropdown-li-a"
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault()
                                      }}
                                    >
                                      취소
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td className="tac">
                            {item?.createdAt != '' && item?.createdAt
                              ? setDetailDateFormat(item?.createdAt)
                              : '-'}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8">
                          <div className="table-nopost">
                            조회된 데이터가 없습니다.
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {recruimentList?.length != 0 && (
                  <PaginationComponent
                    page={params.pageNum}
                    pageSize={params.listNum}
                    total={recordsTotal == 0 ? 1 : recordsTotal}
                    pageClick={(page) => {
                      pageReplace({ ...params, pageNum: page })
                    }}
                    paging={0}
                  />
                )}
              </div>
            </div>
          )}
          {/* //table */}
        </div>
      </Container>
    </Fragment>
  )
}

export default ContentsManagement
