import React, { useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { login, logout, getUserInfo } from '../api/auth'
import { setToken, delToken } from '../redux/token'
import { setUser, delUser } from '../redux/user'
import { setLoading } from '../redux/loading'
import { useAppDispatch } from '../redux/store'

const Login = () => {
  const history = useNavigate()
  const dispatch = useAppDispatch()

  const [param, setParam] = useState({
    username: '',
    password: '',
  })

  const [idErr, setIdErr] = useState('')
  const [pwErr, setPwErr] = useState('')

  useEffect(() => {
    dispatch(setLoading(false))
  }, [])
  
  const callLogin = async () => {
    const regExpId = /[`~!@#$%^&*|\\\'\";:\/?]/gi
    const regExpPw = /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{8,20}$/
    let check = true
    if (param.username === '') {
      check = false
      setIdErr('아이디가 올바르지 않습니다. 다시 입력해주세요.')
    } else if (regExpId.test(param.username)) {
      check = false
      setIdErr('특수문자는 입력하실 수 없습니다.')
    } else {
      setIdErr('')
    }
    if (param.password === '') {
      check = false
      setPwErr(
        `비밀번호가 올바르지 않습니다.\n영문/숫자/특수문자를 조합하여 10~16자 이내로 입력해주세요.`
      )
    } else if (!regExpPw.test(param.password)) {
      check = false
      setPwErr(
        `비밀번호가 올바르지 않습니다.\n영문/숫자/특수문자를 조합하여 10~16자 이내로 입력해주세요.`
      )
    } else {
      setPwErr('')
    }

    if (check) {
      dispatch(setLoading(true))

      const { code, data } = await login({
        username: param.username,
        password: param.password,
        reactivation: false,
      }).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        if (data?.tokenPair?.accessToken?.token) {
          const token = {
            token: data.tokenPair.accessToken.token,
            refresh: data.tokenPair.refreshToken.token,
          }
          // 로그인 처리
          dispatch(setToken(token))

          // 유저정보 갱신
          callUserInfo()
        } else {
          alert('로그인 요청에 실패 하였습니다.')
          removeUser()
        }
      } else if (code == 601) {
        alert('비밀번호가 일치하지 않습니다.')
        removeUser()
      } else {
        alert('로그인 요청에 실패 하였습니다.')
        removeUser()
      }
    }
  }

  const removeUser = async () => {
    dispatch(setLoading(true))

    await logout().finally(() => {
      dispatch(setLoading(false))
    })
    
    dispatch(delToken())
    dispatch(delUser())
    history('/login')
  }

  const callUserInfo = async () => {
    dispatch(setLoading(true))

    const { code, data } = await getUserInfo().finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      // 유저 정보 넣기
      dispatch(setUser(data))
      history('/home')
    } else {
      // 정보 요청 실패시 로그아웃 처리
      removeUser()
    }
  }

  return (
    <Container>
      <div id="wrap">
        <div className="login_form" style={{ height: 'auto' }}>
          <div className="card">
            <div
              style={{
                padding: '110px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <article className="inner" style={{ width: '60%' }}>
                <div
                  className="inner-body"
                  style={{
                    padding: '70px',
                  }}
                >
                  <div
                    className="form-row"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '50px',
                      marginBottom: '100px',
                    }}
                  >
                    <h2 className="text-primary">THUNDERJOB ADMIN</h2>
                  </div>
                  <div
                    className="form-row mb-30"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div className="col-int" style={{ width: '70%' }}>
                      <input
                        type="text"
                        id="a_id"
                        className="int"
                        placeholder="아이디"
                        style={{ height: '50px' }}
                        value={param.username}
                        onChange={(e) =>
                          setParam({ ...param, username: e.target.value })
                        }
                        onKeyUp={(e) => {
                          if (e.keyCode == 13) {
                            callLogin()
                          }
                        }}
                      />
                      {idErr !== '' && <p className="text-error">{idErr}</p>}
                    </div>
                  </div>
                  <div
                    className="form-row mb-50"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div className="col-int" style={{ width: '70%' }}>
                      <input
                        type="password"
                        id="a_password"
                        className="int"
                        placeholder="비밀번호"
                        style={{ height: '50px' }}
                        value={param.password}
                        onChange={(e) =>
                          setParam({ ...param, password: e.target.value })
                        }
                        onKeyUp={(e) => {
                          if (e.keyCode == 13) {
                            callLogin()
                          }
                        }}
                      />
                      {pwErr !== '' && <p className="text-error">{pwErr}</p>}
                    </div>
                  </div>
                  <div
                    className="form-row mb-30"
                    style={{ marginLeft: '15%', width: '70%' }}
                  >
                    <p className="p2 text-secondary">
                      <strong
                        className="text-danger"
                        style={{ marginRight: '10px' }}
                      >
                        Tip
                      </strong>
                      비밀번호는 영문/숫자/특수문자 조합하여 10~16자 이내로
                      입력해주세요.
                    </p>
                  </div>
                  <div
                    className="form-row mb-30"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <button
                      type="button"
                      className="comm-btn btn-lg btn-primary"
                      style={{
                        width: '70%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      onClick={() => callLogin()}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <footer
          id="footer"
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '0px',
          }}
        >
          <address>
            Copyright ⓒ 2023 DesignTeam by <strong>bnbcompany</strong>. All
            Rights Reserved
          </address>
        </footer>
      </div>
    </Container>
  )
}

export default Login
