import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'
import dayjs from 'dayjs'
import SearchFilter from '../../common/searchFilter'
import PaginationComponent from '../../common/pagination'
import { getIntern, getCorporate, getUniversity } from '../../../api/member'
import {
  comma,
  setDateTime,
  phoneNum,
  setDateFormat,
  getUrlParams,
} from '../../../utils/index'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const UserManagement = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const urlParam = getUrlParams(window.location.href)
  const [complete, setComplete] = useState(false)
  const [recordsTotal, setRecordsTotal] = useState(300)
  const [userList, setUserList] = useState(null)
  const [params, setParams] = useState({
    tab: 1,
    filter: {
      startDate: new Date(
        dayjs().subtract(1, 'y').format('YYYY.MM.DD 00:00:00')
      ),
      endDate: new Date(dayjs().format('YYYY.MM.DD 23:59:59')),
      searchTxt: '',
    },
    pageNum: 1,
    listNum: 10,
  })

  const [filter, setFilter] = useState({
    startDate: new Date(dayjs().subtract(1, 'y').format('YYYY.MM.DD 00:00:00')),
    endDate: new Date(dayjs().format('YYYY.MM.DD 23:59:59')),
    searchTxt: '',
  })

  const callUser = async () => {
    dispatch(setLoading(true))
    
    const { code, data } = await (params.tab == 1
      ? getIntern
      : params.tab == 2
      ? getCorporate
      : getUniversity)({
      searchWord: params.filter.searchTxt,
      startAt: setDateTime(params.filter.startDate),
      endAt: setDateTime(params.filter.endDate),
      offset: (params.pageNum - 1) * params.listNum,
      limit: params.listNum,
      active: false,
    }).finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      setUserList([...data.items])
      setRecordsTotal(data.totalCount)
    } else {
      setUserList([])
      setRecordsTotal(0)
    }
  }

  const pageReplace = (param) => {
    navigate(
      `/user/withdrawn?tab=${param.tab}&filter=${encodeURI(
        JSON.stringify(param.filter)
      )}&pageNum=${param.pageNum}&listNum=${param.listNum}`,
      { replace: true }
    )
  }

  useEffect(() => {
    if (Object.keys(getUrlParams(window.location.href)).length == 0) {
      pageReplace(params)
    }
  }, [getUrlParams(window.location.href)])

  useEffect(() => {
    init()
  }, [
    urlParam['tab'],
    urlParam['filter'],
    urlParam['pageNum'],
    urlParam['listNum'],
  ])

  const init = () => {
    setParams({
      tab: urlParam['tab'] ? urlParam['tab'] : params.tab,
      filter: urlParam['filter']
        ? JSON.parse(decodeURI(urlParam['filter']))
        : params.filter,
      pageNum: urlParam['pageNum'] ? urlParam['pageNum'] : params.pageNum,
      listNum: urlParam['listNum'] ? urlParam['listNum'] : params.listNum,
    })
    setFilter(
      urlParam['filter']
        ? JSON.parse(decodeURI(urlParam['filter']))
        : params.filter
    )
    setComplete(true)
  }

  useEffect(() => {
    if (complete) {
      callUser()
    }
  }, [params.tab, params.filter, params.pageNum, params.listNum])

  return (
    <Fragment>
      <Container>
        <div className="contents">
          <div className="inner-body">
            <ul
              className="tabmenu-line column-3 gap-10"
              style={{ marginBottom: '30px' }}
            >
              <li className={params.tab == 1 ? 'active' : ''}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setUserList([])
                    pageReplace({
                      ...params,
                      tab: 1,
                    })
                  }}
                >
                  인턴 회원
                </a>
              </li>
              <li className={params.tab == 2 ? 'active' : ''}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setUserList([])
                    pageReplace({
                      ...params,
                      tab: 2,
                    })
                  }}
                >
                  기업 회원
                </a>
              </li>
              <li className={params.tab == 3 ? 'active' : ''}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setUserList([])
                    pageReplace({
                      ...params,
                      tab: 3,
                    })
                  }}
                >
                  대학교 회원
                </a>
              </li>
            </ul>
          </div>
          <SearchFilter
            filter={filter}
            setFilter={(filter) => {
              setFilter({ ...filter })
            }}
            search={() =>
              pageReplace({ ...params, filter: { ...filter }, pageNum: 1 })
            }
          />
          {userList && (
            <div className="table">
              <div className="table-head">
                <p className="total">
                  총 <strong>{comma(recordsTotal)}</strong>건
                </p>
                <div className="table-filter d-flex">
                  <label htmlFor="number-outputs" className="lbl me-5">
                    출력갯수 :
                  </label>
                  <select
                    name="number-outputs"
                    id="number-outputs"
                    className="int me-5"
                    value={params.listNum}
                    onChange={(e) => {
                      pageReplace({
                        ...params,
                        listNum: Number(e.target.value),
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  {/* 기획 없음 */}
                  {/* <select name="" id="" className="int">
                  <option value="">정렬방식</option>
                  <option value="" selected>
                    등록일 빠른순
                  </option>
                  <option value="">등록일 느린순</option>
                </select> */}
                </div>
              </div>
              {/* //table-head */}
              <div className="table-body">
                <table border="0" cellSpacing="0" cellPadding="0">
                  <colgroup>
                    <col width="55px" />
                    <col width="*" />
                    <col width="10%" />
                    <col width="12%" />
                    <col width="12%" />
                    {params.tab == 1 && <col width="10%" />}
                    <col width="15%" />
                    <col width="15%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <div className="th-move">
                          No
                          {/* 기획 없음 */}
                          {/* <div className="modal-move">
                          <a href="#" className="btn_ico_up"></a>
                          <a href="#" className="btn_ico_down"></a>
                        </div> */}
                        </div>
                      </th>
                      <th>ID</th>
                      <th>
                        {params.tab == 1
                          ? '이름'
                          : params.tab == 2
                          ? '기업명'
                          : '대학교명'}
                      </th>
                      <th>연락처</th>
                      <th>{params.tab == 1 ? '생년월일' : '담당자명'}</th>
                      {params.tab == 1 && <th>제휴대학</th>}
                      <th>회원 가입일</th>
                      <th>최종 로그인</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList?.length != 0 ? (
                      userList.map((item, idx) => (
                        <tr key={idx}>
                          <td className="tac">
                            {recordsTotal - ((params.pageNum - 1) * params.listNum + idx)}
                          </td>
                          <td className="tac">{item.user.username}</td>
                          <td className="tac">
                            {params.tab == 1
                              ? item.name
                              : params.tab == 2
                              ? item?.companyContact.company.name
                              : item?.universityContact.university.name}
                          </td>
                          <td className="tac">
                            {phoneNum(item.user.phone)}
                          </td>
                          <td className="tac">
                            {params.tab == 1 ? item.birthday : item.user.name}
                          </td>
                          {params.tab == 1 && (
                            <td className="tac">
                              {item?.affiliatedUniversity?.name || ''}
                            </td>
                          )}
                          <td className="tac">
                            {setDateFormat(item.createdAt)}
                          </td>
                          <td className="tac">
                            {setDateFormat(item.user.lastLoginAt)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="11">
                          <div className="table-nopost">
                            조회된 데이터가 없습니다.
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {userList?.length != 0 && (
                  <PaginationComponent
                    page={params.pageNum}
                    pageSize={params.listNum}
                    total={recordsTotal == 0 ? 1 : recordsTotal}
                    pageClick={(page) => {
                      pageReplace({ ...params, pageNum: page })
                    }}
                    paging={0}
                  />
                )}
              </div>
            </div>
          )}
          {/* //table */}
        </div>
      </Container>
    </Fragment>
  )
}

export default UserManagement
