import React, { Fragment, useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import dayjs from 'dayjs'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getJoinSecession } from '../../../api/statistics'
import {
  setDateTime,
} from '../../../utils/index'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const StatisticsManagement = () => {
  const dispatch = useAppDispatch()

  const [chartData, setChartData] = useState([])
  const [filter, setFilter] = useState({
    startDate: new Date(dayjs().subtract(1, 'y').format('YYYY.MM.DD 00:00:00')),
    endDate: new Date(dayjs().format('YYYY.MM.DD 23:59:59')),
  })
  const [format, setFormat] = useState('MONTH') 
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "가입 탈퇴 그래프",
      },
    },
  };
  let labels = [];
  if (chartData.length > 0) {
    labels = chartData.map((data) => data.selectedDate);
  }

  const data = {
    labels,
    datasets: [
      {
        label: "가입",
        data: chartData.map((data) => data.count1),
        borderColor: "rgb(255, 228, 0)",
        backgroundColor: "rgba(255, 228, 0, 0.5)",
      },
      {
        label: "탈퇴",
        data: chartData.map((data) => data.count2),
        borderColor: "rgb(255, 94, 0)",
        backgroundColor: "rgba(255, 94, 0, 0.5)",
      },
    ],
  };

  const callApplication = async () => {
    dispatch(setLoading(true))

    const { code, data } = await getJoinSecession({
      type: 'JOIN_SECESSION',
      format,
      startAt: setDateTime(filter.startDate),
      endAt: setDateTime(filter.endDate),
    }).finally(() => {
      dispatch(setLoading(false))
    })
    
    if (code == 200) {
      setChartData([...data?.items])
    } else {
      setChartData([])
    }
  }

  useEffect(() => {
    dispatch(setLoading(false))
    
    callApplication()
  }, [format, filter?.startDate, filter?.endDate])

  return (
    <Fragment>
      <Container>
        <div className="contents">
          {/* 검색 필터 */}
          <div className="filter on-bg mb-30">
            <div className="filter-col form-col">
              <label htmlFor="keyword-search" className="lbl">
                기간
              </label>
              <div className="col-int">
                <div className="filter-int" style={{ alignItems: 'center' }}>
                  <input
                    type="date"
                    id="a_date"
                    className="int"
                    value={dayjs(filter.startDate).format('YYYY-MM-DD')}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        startDate: new Date(e.target.value),
                      })
                    }
                    max={dayjs(filter.endDate).format('YYYY-MM-DD')}
                    style={{ width: '150px', marginRight: '10px' }}
                  ></input>
                  ~
                  <input
                    type="date"
                    id="a_date"
                    className="int"
                    value={dayjs(filter.endDate).format('YYYY-MM-DD')}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        endDate: new Date(e.target.value),
                      })
                    }
                    min={dayjs(filter.startDate).format('YYYY-MM-DD')}
                    style={{
                      width: '150px',
                      marginLeft: '10px',
                      marginRight: '30px',
                    }}
                  ></input>
                  <button
                    type="button"
                    className={`comm-btn ${format === 'DAY' ? 'btn-success' : 'btn-gray'}`}
                    style={{
                      marginRight: '10px',
                    }}
                    onClick={() => {
                      setFilter({
                        startDate: new Date(dayjs().subtract(1, 'M').format('YYYY.MM.DD 00:00:00')),
                        endDate: new Date(dayjs().format('YYYY.MM.DD 23:59:59')),
                      })
                      setFormat('DAY')
                    }}
                  >
                    일 단위
                  </button>
                  <button
                    type="button"
                    className={`comm-btn ${format === 'MONTH' ? 'btn-success' : 'btn-gray'}`}
                    style={{
                      marginRight: '10px',
                    }}
                    onClick={() => {
                      setFilter({
                        startDate: new Date(dayjs().subtract(1, 'y').format('YYYY.MM.DD 00:00:00')),
                        endDate: new Date(dayjs().format('YYYY.MM.DD 23:59:59')),
                      })
                      setFormat('MONTH')
                    }}
                  >
                    월 단위
                  </button>
                  <button
                    type="button"
                    className={`comm-btn ${format === 'YEAR' ? 'btn-success' : 'btn-gray'}`}
                    style={{
                      marginRight: '10px',
                    }}
                    onClick={() => {
                      setFilter({
                        startDate: new Date(dayjs().subtract(10, 'y').format('YYYY.MM.DD 00:00:00')),
                        endDate: new Date(dayjs().format('YYYY.MM.DD 23:59:59')),
                      })
                      setFormat('YEAR')
                    }}
                  >
                    년 단위
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* 검색 필터 */}
          <div className="form-col mb-15">
            <Line options={options} data={data} />
          </div>
          <div className="form-col mb-15">
            <div className="table">
              <div className="table-body">
                <table border="0" cellSpacing="0" cellPadding="0">
                  <colgroup>
                    <col width="15%" />
                    <col width="30%" />
                    <col width="30%" />
                    <col width="25%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>구분</th>
                      <th>가입</th>
                      <th>탈퇴</th>
                      <th>합계 (총 회원 수)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {chartData?.length > 0 ? (
                      chartData.map((item, idx) => (
                        <tr key={idx}>
                          <td className="tac bg">{item?.selectedDate || '-'}</td>
                          <td className="tac">{item?.count1 || 0}</td>
                          <td className="tac">{item?.count2 || 0}</td>
                          <td className="tac bg">{item?.countSum || 0}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">
                          <div className="table-nopost">
                            조회된 데이터가 없습니다.
                          </div>
                        </td>
                      </tr>
                    )}
                    {chartData?.length > 0 && (
                      <tr>
                        <td className="tac bg">합계</td>
                        <td className="tac">{chartData.map((item) => item?.count1).reduce((acc, cur) => acc+cur)}</td>
                        <td className="tac">{chartData.map((item) => item?.count2).reduce((acc, cur) => acc+cur)}</td>
                        <td className="tac bg">{chartData.map((item) => item?.countSum).reduce((acc, cur) => acc+cur)}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default StatisticsManagement
