import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import { getDepositBank, insertDepositBank, updateDepositBank, deleteDepositBank } from '../../../api/payment'
import { isEmpty } from '../../../utils/index'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const DepositManagement = () => {
  const dispatch = useAppDispatch()

  const [deposit, setDeposit] = useState([])
  const [editMode, setEditMode] = useState(false)
  
  const [newDeposit, setNewDeposit] = useState({
    bankName: '',
    accountNumber: '',
    accountHolder: '',
    status: true,
  })

  useEffect(() => {
    dispatch(setLoading(false))
    
    callDeposit()
  }, [])

  const callDeposit = async () => {
    dispatch(setLoading(true))

    const { code, data } = await getDepositBank().finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      // setDeposit({...data})
      setDeposit([...data?.items])
    } else {
      setDeposit({})
    }
  }

  const dataCheck = (param) => {
    const depositParam = isEmpty(param) ? newDeposit : param
    let activeCnt = 0

    deposit.map((x) => {
      if(x?.status) {
        activeCnt++
      }
    })
    if(activeCnt >= 3) {
      console.log('체크!', typeof depositParam?.status)
      if(depositParam?.status) {
        alert('활성화 할 수 있는 입금정보는 3개까지 입니다.')
        return false
      }
    }

    // 은행명 체크
    if(isEmpty(depositParam?.bankName)) {
      alert('은행명을 입력해주세요.')
      return false
    }
    // 계좌번호 체크
    if(isEmpty(depositParam?.accountNumber)) {
      alert('계좌번호를 입력해주세요.')
      return false
    }
    // 예금주 체크
    if(isEmpty(depositParam?.accountHolder)) {
      alert('예금주를 입력해주세요.')
      return false
    }

    // 추가할 입금정보 제한
    if(deposit.length >= 10) {
      alert('등록할 수 있는 입금정보는 10개까지 입니다.')
      return false
    }

    return true
  }

  // 추가
  const addDeposit = async () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('입금 정보를 추가하시겠습니까?')) {
      if(!dataCheck()) return 

      dispatch(setLoading(true))
      
      const { code } = await insertDepositBank(newDeposit).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        alert('추가 완료')
        // window.location.reload()
        await callDeposit()
        setNewDeposit({
          bankName: '',
          accountNumber: '',
          accountHolder: '',
          status: true,
        })
      }else {
        alert('추가 실패')
      }
    }
  }

  // 개별 수정
  const updateDeposit = async (param) => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('입금 정보를 수정하시겠습니까?')) {
      if(!dataCheck(param)) return 
      dispatch(setLoading(true))
      
      const { code } = await updateDepositBank(param?.id, param).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        alert('저장 완료')
        // window.location.reload()
        await callDeposit()
      }else {
        alert('저장 실패')
      }
    }
  }

  // 개별 삭제
  const deleteDeposit = async (param) => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('입금 정보를 삭제하시겠습니까?')) {
      dispatch(setLoading(true))
      
      const { code } = await deleteDepositBank(param?.id).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        alert('삭제 완료')
        await callDeposit()
        // window.location.reload()
      }else {
        alert('삭제 실패')
      }
    }
  }


  return (
    <Fragment>
      <Container>
        <div className="contents">
          <div className="card">
            <div className="card-head">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-center"><h6 className="card-title">입금 정보</h6></div>
                  <a href="#" className="comm-btn btn-md btn-gray bd-radius-3" 
                    onClick={(e) => { 
                      e.preventDefault()
                      setEditMode(!editMode)
                    }}
                  >
                    { !editMode ? '수정' : '보기' }
                  </a>
              </div>
            </div>
            <div className="card-body">
              {/* <div className="form-col mb-15">
                <label className="lbl">은행명 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int col-2">
                  <div className="d-flex">
                    <input type="text" className="int" placeholder="은행명" value={deposit?.bankName} disabled={!editMode}
                      onChange={(e) => {
                        setDeposit({ ...deposit, bankName: e.target.value})
                    }} />
                  </div>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl">계좌번호 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int col-2">
                  <div className="d-flex">
                    <input type="text" className="int" placeholder="계좌번호" value={deposit?.accountNumber} disabled={!editMode}
                      onChange={(e) => {
                        setDeposit({ ...deposit, accountNumber: e.target.value})
                    }} />
                  </div>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl">예금주 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int col-2">
                  <div className="d-flex">
                    <input type="text" className="int" placeholder="예금주" value={deposit?.accountHolder} disabled={!editMode}
                      onChange={(e) => {
                        setDeposit({ ...deposit, accountHolder: e.target.value})
                    }} />
                  </div>
                </div>
              </div> */}
              {/** 입금 정보 테이블 */}
              <div className="table mb-50 mt-40">
                <div className="table-head"></div>
                <div className="table-body">
                  <table border="0" cellSpacing="0" cellPadding="0">
                    <colgroup>
                        <col width="10%" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="15%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          <div className="th-move">
                            사용/미사용 <span className="required"><span>필수입력</span></span>
                          </div>
                        </th>
                        <th>은행명 <span className="required"><span>필수입력</span></span></th>
                        <th>계좌번호 <span className="required"><span>필수입력</span></span></th>
                        <th>예금주 <span className="required"><span>필수입력</span></span></th>
                        <th>저장 및 삭제</th>
                      </tr>
                    </thead>
                    <tbody>
                      {deposit?.length != 0 
                        ? (
                          deposit.map((item, idx) => (
                            <tr key={idx}>
                              <td className="tac">
                                <select id="active" className="int" style={{ width: '100px' }}
                                  onChange={(e) => {
                                    deposit[idx].status = e.target.value === 'false' ? false : true
                                    setDeposit([ ...deposit ])
                                  }} 
                                  value={item?.status}
                                  disabled={!editMode}
                                >
                                  <option value={ true } key={ true }>사용</option>
                                  <option value={ false } key={ false }>미사용</option>
                                </select>
                              </td>
                              <td className="tac">
                                <input type="text" className="int" placeholder="은행명" value={item?.bankName} disabled={!editMode}
                                  onChange={(e) => {
                                    deposit[idx].bankName = e.target.value
                                    setDeposit([ ...deposit ])
                                }} />
                              </td>
                              <td className="tac">
                                <input type="text" className="int" placeholder="계좌번호" value={item?.accountNumber} disabled={!editMode}
                                  onChange={(e) => {
                                    deposit[idx].accountNumber = e.target.value
                                    setDeposit([ ...deposit ])
                                }} />
                              </td>
                              <td className="tac">
                                <input type="text" className="int" placeholder="계좌번호" value={item?.accountHolder} disabled={!editMode}
                                  onChange={(e) => {
                                    deposit[idx].accountHolder = e.target.value
                                    setDeposit([ ...deposit ])
                                }} />
                              </td>
                              <td className="tac innter-flex justify-content-center">
                                <button type="submit" className="comm-btn bd-radius-5 btn-gray" style={{ margin: '0 5px' }} disabled={!editMode}
                                  onClick={() => {
                                    updateDeposit(deposit[idx])
                                  }} >저장</button>
                                <button type="submit" className="comm-btn bd-radius-5 btn-danger" style={{ margin: '0 5px' }} disabled={!editMode}
                                  onClick={() => {
                                    deleteDeposit(deposit[idx])
                                  }} >삭제</button>
                              </td>
                            </tr>
                          ))
                        )
                        : (
                          <></>
                        )
                      }
                      {/* 추가할 입금정보 */}
                      {
                        deposit.length >= 10 
                        ? (
                          <></>
                        )
                        : (
                          <>
                            <tr>
                              <td className="tac bg" colSpan="5">추가할 입금 정보</td>
                            </tr>
                            <tr>
                              <td className="tac">
                                <select id="active" className="int" style={{ width: '100px' }}
                                  onChange={(e) => {
                                    setNewDeposit({ ...newDeposit, status: e.target.value === 'false' ? false : true })
                                  }} 
                                  value={newDeposit?.status}
                                  disabled={!editMode}
                                >
                                  <option value={ true } key={ true }>활성화</option>
                                  <option value={ false } key={ false }>비활성화</option>
                                </select>
                              </td>
                              <td className="tac">
                                <input type="text" className="int" placeholder="은행명" value={newDeposit?.bankName} disabled={!editMode}
                                  onChange={(e) => {
                                    setNewDeposit({ ...newDeposit, bankName: e.target.value})
                                }} />
                              </td>
                              <td className="tac">
                                <input type="text" className="int" placeholder="계좌번호" value={newDeposit?.accountNumber} disabled={!editMode}
                                  onChange={(e) => {
                                    setNewDeposit({ ...newDeposit, accountNumber: e.target.value})
                                }} />
                              </td>
                              <td className="tac">
                                <input type="text" className="int" placeholder="예금주" value={newDeposit?.accountHolder} disabled={!editMode}
                                  onChange={(e) => {
                                    setNewDeposit({ ...newDeposit, accountHolder: e.target.value})
                                }} />
                              </td>
                              <td className="tac">
                                <button type="submit" className="comm-btn bd-radius-5 btn-success" disabled={!editMode}
                                  onClick={() => {
                                    addDeposit()
                                  }} >추가</button>
                              </td>
                            </tr>
                          </>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* { editMode
              ? (
                <div className="form-btn justify-content-center mt-30">
                  <div className="me-10">
                    <button type="submit" className="comm-btn bd-radius-5 btn-danger" 
                      onClick={() => window.location.reload()} >취소</button>
                  </div>
                  <div>
                    <button type="submit" className="comm-btn bd-radius-5 btn-gray" 
                      onClick={() => addDeposit()} >저장</button>
                  </div>
                </div>
              )
              : (
                <div className="form-btn justify-content-center mt-30"></div>
              )
            } */}
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default DepositManagement
