import React, { Fragment, useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import { getApplicationGlobalCamp } from '../../../api/member'
import { getUrlParams, setDateFormat, setDetailDateFormat, isEmpty } from '../../../utils/index'
import { MENUITEMS } from '../../../data/menu'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const UserManagement = () => {
  const dispatch = useAppDispatch()

  const [application, setApplication] = useState(null)

  useEffect(() => {
    dispatch(setLoading(false))
    
    // 기본정보
    callApplication(getUrlParams(window.location.href))
    const temp = MENUITEMS.Items.filter(
      (menu) =>
        menu.children.filter((ch) => ch.path == window.location.pathname)
          .length != 0
    )
  }, [])

  const callApplication = async (param) => {
    if(Object.keys(param).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getApplicationGlobalCamp({
        orderType: 'GLOBAL_CAMP',
        paymentId: param?.pid ?? '',
        orderId: param?.oid,
        searchType: 'APPLICATION',
      }).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200 && data.totalCount > 0) {
        setApplication({...data.payments[0]})
      } else {
        setApplication({})
      }
    }
  }

  return (
    <Fragment>
      <Container>
        <div className="contents">
          {/** 기본정보 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
                <h6 className="table-title">기본 정보</h6>
            </div>
            <div className="table-body not-hover">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                    <col width="15%" />
                    <col width="35%" />
                    <col width="15%" />
                    <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg">ID</td>
                    <td className="tac">{application?.order?.product?.account || '-'}</td>
                    <td className="tac bg">이름</td>
                    <td className="tac">{application?.order?.product?.userName || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">성별</td>
                    <td className="tac">{application?.order?.product?.genderNm || '-'}</td>
                    <td className="tac bg">생년월일</td>
                    <td className="tac">
                      {application?.order?.product?.birthday != '' && application?.order?.product?.birthday 
                        ? setDateFormat(application?.order?.product?.birthday) 
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">국적</td>
                    <td className="tac">{application?.order?.product?.nationalityNm || '-'}</td>
                    <td className="tac bg">연락처</td>
                    <td className="tac">{application?.order?.product?.phone || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">주소</td>
                    <td className="tac">{
                      !isEmpty(application?.order?.product?.address) || !isEmpty(application?.order?.product?.addressDetail)
                      ? application?.order?.product?.address + ' ' + application?.order?.product?.addressDetail
                      : '-'
                    }</td>
                    <td className="tac bg">SNS계정</td>
                    <td className="tac">{application?.order?.product?.snsId || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">제휴 대학</td>
                    <td className="tac">{application?.order?.product?.universityName || '-'}</td>
                    <td className="tac bg">기타사항</td>
                    <td className="tac">{application?.order?.product?.otherInformation || '-'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/** 신청 정보 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
                <h6 className="table-title">신청 정보</h6>
            </div>
            <div className="table-body not-hover">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                    <col width="15%" />
                    <col width="35%" />
                    <col width="15%" />
                    <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg">캠프명</td>
                    <td className="tac">{application?.order?.product?.name || '-'}</td>
                    <td className="tac bg">신청일시</td>
                    <td className="tac">
                      {application?.order?.product?.createdAt != '' && application?.order?.product?.createdAt 
                        ? setDetailDateFormat(application?.order?.product?.createdAt) 
                        : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/** 결제 정보 테이블 */}
          <div className="table mb-50 mt-40">
            <div className="table-head">
                <h6 className="table-title">결제 정보</h6>
            </div>
            <div className="table-body not-hover">
              <table border="0" cellSpacing="0" cellPadding="0">
                <colgroup>
                    <col width="15%" />
                    <col width="35%" />
                    <col width="15%" />
                    <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="tac bg">결제 상태</td>
                    <td className="tac">
                      {application?.status != 'REFUNDED' && !isEmpty(application?.status)
                        ? application?.status 
                        : '-'}
                    </td>
                    <td className="tac bg">결제 일시</td>
                    <td className="tac">
                      {application?.paidAt != '' && application?.paidAt 
                        ? setDetailDateFormat(application?.paidAt) 
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="tac bg">결제 수단</td>
                    <td className="tac">{application?.method || '-'}</td>
                    <td className="tac bg">결제 금액</td>
                    <td className="tac">{application?.amount || '-'}</td>
                  </tr>
                  <tr>
                    <td className="tac bg">환불 상태</td>
                    <td className="tac">
                      {application?.status == 'REFUNDED' 
                        ? application?.status 
                        : '-'}
                    </td>
                    <td className="tac bg">환불 일시</td>
                    <td className="tac">
                      {application?.refundedAt != '' && application?.refundedAt 
                        ? setDetailDateFormat(application?.refundedAt) 
                        : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default UserManagement
