import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
// Localstarage
import storage from 'redux-persist/lib/storage'

// * state 가 추가 될 때 마다 추가
import token from './token/index'
import user from './user/index'
// import filter from './filter/index'
import loading from './loading/index'

const persistConfig = {
  key: 'root',
  // localStorage에 저장합니다.
  storage,
  // 지정한 reducer만 localstorage에 저장합니다.
  whitelist: ['token', 'user', 'loading'],
  // blacklist : 제외
}

const reducer = combineReducers({ token, user, loading })
export default persistReducer(persistConfig, reducer)
