import Login from '../components/login'
import Home from '../components/home'

// 회원 관리
import UserRoutes from './UserRoutes.jsx'
// 컨텐츠 관리
import ContentsRoutes from './ContentsRoutes.jsx'
// 입금 관리
import PaymentRoutes from './PaymentRoutes.jsx'
// 고객 센터
import CustomerRoutes from './CustomerRoutes.jsx'
// 시스템 관리
import SystemRoutes from './SystemRoutes.jsx'
// 통계
import StatisticsRoutes from './StatisticsRoutes.jsx'

export const loginRoutes = [{ path: `/login`, Component: <Login /> }]
export const routes = [
  { path: `/home`, Component: <Home /> },
  ...UserRoutes,
  ...ContentsRoutes,
  ...PaymentRoutes,
  ...CustomerRoutes,
  ...StatisticsRoutes,
  ...SystemRoutes,
]
