import React, { Fragment, useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import { getDashboard } from '../api/dashboard'
import { setDetailDateFormat } from '../utils/index'
import { MENUITEMS } from '../data/menu'
import { setLoading } from '../redux/loading'
import { useAppDispatch } from '../redux/store'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [dashboard, setDashboard] = useState(null)

  useEffect(() => {
    dispatch(setLoading(false))

    // 기본정보
    callDashboard()
    const temp = MENUITEMS.Items.filter(
      (menu) =>
        menu.children.filter((ch) => ch.path == window.location.pathname)
          .length != 0
    )
  }, [])

  const callDashboard = async () => {
    dispatch(setLoading(true))

    const { code, data } = await getDashboard().finally(() => {
      dispatch(setLoading(false))
    })

    if (code === 200) {
      setDashboard({ ...data })
    } else {
      setDashboard({})
    }
  }

  return (
    <Fragment>
      <Container>
        <div className="contents">
          <div className="filter on-bg mb-30">
            <div className="d-flex align-center justify-content-between">
              <div className="d-flex align-center">
                <p className="p3 me-40">Dashboard Time</p>
                <p className="p3 me-30 text-success">{setDetailDateFormat()}</p>
                <p className="text-error mt-0">
                  지표 정보는 금일 기준 정보입니다.
                </p>
              </div>
              <div>
                <button
                  type="button"
                  className="btn-refresh"
                  onClick={() => callDashboard()}
                ></button>
              </div>
            </div>
          </div>
          {/* 인턴 회원 */}
          <div className="p1 mb-15">
            <strong>인턴 회원</strong>
          </div>
          <div className="d-grid column-3 gap-15">
            <div className="card">
              <div className="card-body tac bd-radius-10">
                <p className="p2 mb-10">회원 가입</p>
                <div
                  className="flex-12"
                  onClick={() => {
                    navigate(`/user/management?tab=1`)
                  }}
                >
                  <h3 className="d-inline me-10">
                    {dashboard?.internJoin || 0}
                  </h3>
                  명
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body tac bd-radius-10">
                <p className="p2 mb-10">공고 지원</p>
                <div
                  className="flex-12"
                  onClick={() => {
                    navigate(`/user/internship`)
                  }}
                >
                  <h3 className="d-inline me-10">
                    {dashboard?.internApplication || 0}
                  </h3>
                  건
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body tac bd-radius-10">
                <p className="p2 mb-10">결제</p>
                <div
                  className="flex-12"
                  onClick={() => {
                    navigate(`/user/internship`)
                  }}
                >
                  <h3 className="d-inline me-10">
                    {dashboard?.internPayment || 0}
                  </h3>
                  건
                </div>
              </div>
            </div>
          </div>
          {/* 인턴 회원 */}
          {/* 기업 회원 */}
          <div className="p1 mb-15">
            <strong>기업 회원</strong>
          </div>
          <div className="d-grid column-3 gap-15">
            <div className="card">
              <div className="card-body tac bd-radius-10">
                <p className="p2 mb-10">회원 가입</p>
                <div
                  className="flex-12"
                  onClick={() => {
                    navigate(`/user/management?tab=2`)
                  }}
                >
                  <h3 className="d-inline me-10">
                    {dashboard?.companyJoin || 0}
                  </h3>
                  명
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body tac bd-radius-10">
                <p className="p2 mb-10">공고 등록</p>
                <div
                  className="flex-12"
                  onClick={() => {
                    navigate(`/contents/internship/recruitments`)
                  }}
                >
                  <h3 className="d-inline me-10">
                    {dashboard?.companyRecruitmentRegist || 0}
                  </h3>
                  건
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body tac bd-radius-10">
                <p className="p2 mb-10">공고 마감</p>
                <div
                  className="flex-12"
                  onClick={() => {
                    navigate(`/contents/internship/recruitments`)
                  }}
                >
                  <h3 className="d-inline me-10">
                    {dashboard?.companyRecruitmentDeadline || 0}
                  </h3>
                  건
                </div>
              </div>
            </div>
          </div>
          {/* 기업 회원 */}
          {/* 대학교 회원 */}
          <div className="p1 mb-15">
            <strong>대학교 회원</strong>
          </div>
          <div className="d-grid column-3 gap-15">
            <div className="card">
              <div className="card-body tac bd-radius-10">
                <p className="p2 mb-10">회원 가입</p>
                <div
                  className="flex-12"
                  onClick={() => {
                    navigate(`/user/management?tab=3`)
                  }}
                >
                  <h3 className="d-inline me-10">
                    {dashboard?.universityJoin || 0}
                  </h3>
                  명
                </div>
              </div>
            </div>
          </div>
          {/* 대학교 회원 */}
        </div>
      </Container>
    </Fragment>
  )
}

export default Home
