import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Container } from 'reactstrap'
import { useNavigate, Link } from 'react-router-dom'
import { getCustomerFaqDetail, insertCustomerFaq, updateCustomerFaq } from '../../api/customer'
import { getUrlParams, isEmpty } from '../../utils/index'
import { MENUITEMS } from '../../data/menu'
import QuillEditor from '../common/quill'
import { setLoading } from '../../redux/loading'
import { useAppDispatch } from '../../redux/store'


const CustomerCenter = () => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const [params, setParams] = useState(null)
  const [editMode, setEditMode] = useState(false)
  
  const [useful, setUseful] = useState(false) 
  const [faq, setFaq] = useState(null)
  const [category, setCategory] = useState(null)
  const [title, setTitle] = useState(null)
  const [content, setContent] = useState(null)
  const [active, setActive] = useState(true)
  // 등록 시 활용
  const [addImage, setAddImage] = useState(null)

  useEffect(() => {
    dispatch(setLoading(false))
    
    const urlParams = getUrlParams(window.location.href)
    setParams(urlParams)

    if(urlParams?.mode != 'reg') {
      getFaq(urlParams)
      setEditMode(false)
    }else {
      setEditMode(true)
    }

    const temp = MENUITEMS.Items.filter(
      (menu) =>
        menu.children.filter((ch) => ch.path == window.location.pathname)
          .length != 0
    )
  }, [])


  const cancelContents = () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm(`작성한 내용을 저장하지 않고\n상위 페이지로 이동하시겠습니까?`)) {
      window.location.reload()
    }
  }

  const dataCheck = () => {
    if(isEmpty(title) || isEmpty(content)) {
      alert('제목을 입력해주세요.')
      return false
    }

    return true
  }

  // 등록
  const insertFaq = async () => {
    if(!dataCheck()) return
    const formData = new FormData()

    formData.append('title', isEmpty(category) ? title : `${category}<divider>${title}`)
    formData.append('content', content)
    formData.append('active', active)

    for(let i=0;i<addImage.length; i++) {
      if(content.indexOf(addImage[i].baseFile) < 0) {
        continue
      }
      formData.append('addImage', addImage[i].file)
      formData.append('addBaseImage', addImage[i].baseFile)
    }
    setAddImage([])
    
    dispatch(setLoading(true))

    const { code, data } = await insertCustomerFaq(formData).finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      alert('등록 완료')
      navigate('/customer/faq')
    }else {
      alert('등록 실패')
    }
  }

  // 상세
  const getFaq = async (param) => {
    if(Object.keys(param?.sid).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getCustomerFaqDetail(param?.sid).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        if(data?.images.length > 0) {
          for(let i=0; i<data?.images.length; i++) {
            data.content = data?.content.replace(data?.images[i]?.url, `${data?.awsS3Url}/${data?.images[i]?.url}`)
          }   
        }
        setFaq({ ...data })
        const titleDivider = data?.title.split('<divider>')
        if(titleDivider.length > 1) {
          setCategory(titleDivider[0])
          setTitle(titleDivider[1])
        }else {
          setTitle(data?.title)
        }
        setContent(data?.content)
        setActive(data?.active)
      } else {
        setFaq({})
      }
    }
  }

  // 수정
  const updateFaq = async () => {     
    if(!dataCheck()) return
    
    const formData = new FormData()
    formData.append('title', isEmpty(category) ? title : `${category}<divider>${title}`)
    formData.append('content', content.replace(new RegExp(`${faq?.awsS3Url}/`, "gi"), ''))
    formData.append('active', active)

    for(let i=0;i<addImage.length; i++) {
      if(content.indexOf(addImage[i].baseFile) < 0) {
        continue
      }
      formData.append('addImage', addImage[i].file)
      formData.append('addBaseImage', addImage[i].baseFile)
    }
    setAddImage([])

    const deleteFiles = []
    if(faq?.images.length > 0) {
      for(let i=0; i<faq?.images.length; i++) {
        if(content.indexOf(faq?.images[i]?.url) < 0) {
          deleteFiles.push(faq?.images[i])
        }
      }   
    }

    if(deleteFiles.length > 0) {
      for(let i=0;i<deleteFiles.length; i++) {
        formData.append('deleteFileIds', deleteFiles[i]?.id)
        formData.append('deleteFileUrls', deleteFiles[i]?.url)
      }
    }

    dispatch(setLoading(true))

    const { code, data } = await updateCustomerFaq(params?.sid, formData).finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      alert('수정 완료')
    }else {
      alert('수정 실패')
    }
    
    setUseful(false)
    // 리로드
    window.location.reload()
  }

  return (
    <Fragment>
      <Container>
        <div className="contents">
          <div className="card">
            <div className="card-head">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-center"><h6 className="card-title">{ params?.mode == 'reg' ? '등록' : '상세보기 및 수정' }</h6></div>
                {params?.mode != 'reg' && (
                    <a href="#" className="comm-btn btn-md btn-gray bd-radius-3" 
                      onClick={(e) => { 
                        e.preventDefault()
                        setEditMode(!editMode)
                      }}
                    >
                      { !editMode ? '수정' : '보기' }
                    </a>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="form-col mb-15">
                <label htmlFor="category" className="lbl">카테고리 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int col-2">
                  <div className="d-flex">
                    <select id="category" className="int" style={{ width: '128px' }}
                      onChange={(e) => {
                        setCategory(e.target.value)
                      }} 
                      value={category ?? 'Internship'}
                      disabled={!editMode}
                    >
                      <option value={ 'Internship' } key={ 'Internship' }>Internship</option>
                      <option value={ 'Globalcamp' } key={ 'Globalcamp' }>Globalcamp</option>
                      <option value={ 'Payment' } key={ 'Payment' }>Payment</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-col mb-15">
                <label htmlFor="title" className="lbl">제목 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int col-2">
                  <div className="d-flex">
                    <input type="text" id="title" className="int" placeholder="제목" value={title} disabled={!editMode}
                      onChange={(e) => {
                        setTitle(e.target.value)
                    }} />
                  </div>
                </div>
              </div>
              <div className="form-col mb-15">
                <label htmlFor="active" className="lbl">노출여부 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int">
                  <select id="active" className="int" style={{ width: '128px' }}
                    onChange={(e) => {
                      setActive(e.target.value)
                    }} 
                    value={active ?? true}
                    disabled={!editMode}
                  >
                    <option value={ true } key={ true }>노출</option>
                    <option value={ false } key={ false }>미노출</option>
                  </select>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl">내용 <span className="required"><span>필수입력</span></span></label>
              </div>
              <div className="form-col mb-15">
                <QuillEditor
                  html={content}
                  setHtml={setContent}
                  image={addImage}
                  setImage={setAddImage}
                  readOnly={editMode}
                />
              </div>
            </div>
            { params?.mode == 'reg' 
              ? (
                <div className="form-btn justify-content-center mt-30">
                  <button type="submit" className="comm-btn bd-radius-5 btn-gray" 
                    onClick={() => insertFaq()} >등록</button>
                </div>
              )
              : editMode 
                ? (
                  <div className="form-btn justify-content-center mt-30">
                    <div className="me-10">
                      <button type="submit" className="comm-btn bd-radius-5 btn-danger" 
                      onClick={() => cancelContents()} >취소</button>
                    </div>
                    <div>
                      <button type="submit" className="comm-btn bd-radius-5 btn-gray" 
                        disabled={useful}
                        onClick={() => {
                          setUseful(true)
                          updateFaq()
                        }} >수정</button>
                    </div>
                  </div>
                )
                : (
                  <div className="form-btn justify-content-center mt-30">
                    <Link to={`/customer/faq`}>
                      <button type="submit" className="comm-btn bd-radius-5 btn-gray"
                        onClick={() => {}} >목록</button>
                    </Link>
                  </div>
                )
            }
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default CustomerCenter
