import React, { useEffect, useState } from 'react'
import store from '../../redux/store'

export const Loading = () => {
  const [show, setShow] = useState(store.getState().loading)

  const onChange = () => {
    setShow(store.getState().loading || false)
  }
  store.subscribe(onChange)

  return <>{show ? <div id="loading"></div> : <></>}</>
}
