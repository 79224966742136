import InternshipRecruiments from '../components/contents/internshipRecruiment/index'
import InternshipRecruimentsDetail from '../components/contents/internshipRecruiment/detail'
import InternshipApplications from '../components/contents/internshipApplication/index'
import InternshipStory from '../components/contents/internshipStory/index'
import InternshipStoryDetail from '../components/contents/internshipStory/detail'
import Globalcamp from '../components/contents/globalcampManage/index'
import GlobalcampDetail from '../components/contents/globalcampManage/detail'

import GlobalcampApplication from '../components/contents/globalcampApplication/index'
import GlobalcampApplicationDetail from '../components/contents/globalcampApplication/detail'

import PreInterview from '../components/contents/preInterview/index'
// eslint-disable-next-line no-undef, import/no-anonymous-default-export
export default [
  {
    path: `/contents/internship/recruitments`,
    Component: <InternshipRecruiments />,
  },
  {
    path: `/contents/internship/recruitments/detail`,
    Component: <InternshipRecruimentsDetail />,
  },
  {
    path: `/contents/internship/applications`,
    Component: <InternshipApplications />,
  },
  { path: `/contents/internship/story`, Component: <InternshipStory /> },
  {
    path: `/contents/internship/story/detail`,
    Component: <InternshipStoryDetail />,
  },
  { path: `/contents/globalcamp/manage`, Component: <Globalcamp /> },
  {
    path: `/contents/globalcamp/manage/detail`,
    Component: <GlobalcampDetail />,
  },

  { path: `/contents/globalcamp/application`, Component: <GlobalcampApplication /> },
  { path: `/contents/globalcamp/application/detail`, Component: <GlobalcampApplicationDetail /> },

  { path: `/contents/preInterview`, Component: <PreInterview /> },
]
