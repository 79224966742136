import React, { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'
import dayjs from 'dayjs'
import SearchFilter from '../../common/searchFilter'
import PaginationComponent from '../../common/pagination'
import { getInternshipApplication } from '../../../api/post'
import {
  comma,
  setDateTime,
  setDetailDateFormat,
  isEmpty,
  getUrlParams
} from '../../../utils/index'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const ContentsManagement = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const urlParam = getUrlParams(window.location.href)
  const [complete, setComplete] = useState(false)
  const [recordsTotal, setRecordsTotal] = useState(300)
  const [applicationList, setApplicationList] = useState(null)

  const [params, setParams] = useState({
    filter: {
      startDate: new Date(
        dayjs().subtract(1, 'y').format('YYYY.MM.DD 00:00:00')
      ),
      endDate: new Date(dayjs().format('YYYY.MM.DD 23:59:59')),
      searchTxt: '',
      applicationState: '',
      paymentProgress: '',
      paymentState: '',
    },
    pageNum: 1,
    listNum: 10,
  })

  const [filter, setFilter] = useState({
    startDate: new Date(dayjs().subtract(1, 'y').format('YYYY.MM.DD 00:00:00')),
    endDate: new Date(dayjs().format('YYYY.MM.DD 23:59:59')),
    searchTxt: '',
    applicationState: '',
    paymentProgress: '',
    paymentState: '',
  })

  const callApplication = async () => {
    dispatch(setLoading(true))

    const { code, data } = await getInternshipApplication({
      orderType: 'INTERNSHIP',
      searchType: 'APPLICATION',
      searchWord: params.filter.searchTxt,
      applicationState: isEmpty(params.filter.applicationState) ? null : params.filter.applicationState,
      paymentProgress: isEmpty(params.filter.paymentProgress) ? null : params.filter.paymentProgress,
      paymentState: isEmpty(params.filter.paymentState) ? null : params.filter.paymentState,

      startAt: setDateTime(params.filter.startDate),
      endAt: setDateTime(params.filter.endDate),
      offset: (params.pageNum - 1) * params.listNum,
      limit: params.listNum,
      sort: 'DESC'
    }).finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      setApplicationList([...data?.payments])
      setRecordsTotal(data.totalCount)
    } else {
      setApplicationList([])
      setRecordsTotal(0)
    }
  }

  const pageReplace = (param) => {
    navigate(
      `/contents/internship/applications?filter=${encodeURI(
        JSON.stringify(param.filter)
      )}&pageNum=${param.pageNum}&listNum=${param.listNum}`,
      { replace: true }
    )
  }

  useEffect(() => {
    if (Object.keys(getUrlParams(window.location.href)).length == 0) {
      pageReplace(params)
    }
  }, [getUrlParams(window.location.href)])

  useEffect(() => {
    init()
  }, [
    urlParam['filter'],
    urlParam['pageNum'],
    urlParam['listNum'],
  ])

  const init = () => {
    setParams({
      filter: urlParam['filter']
        ? JSON.parse(decodeURI(urlParam['filter']))
        : params.filter,
      pageNum: urlParam['pageNum'] ? urlParam['pageNum'] : params.pageNum,
      listNum: urlParam['listNum'] ? urlParam['listNum'] : params.listNum,
    })
    setFilter(
      urlParam['filter']
        ? JSON.parse(decodeURI(urlParam['filter']))
        : params.filter
    )
    setComplete(true)
  }

  useEffect(() => {
    if (complete) {
      callApplication()
    }
  }, [params.filter, params.pageNum, params.listNum])

  const combineViewer = (param, param2, divider = null) => {
    const arr = []
    if(!isEmpty(param)) {
      arr.push(param)
    }
    if(!isEmpty(param2)) {
      arr.push(param2)
    }
    const joinArr = arr.join(divider ? divider : ' ')

    return joinArr.length > 0 ? joinArr : '-'
  }

  return (
    <Fragment>
      <Container>
        <div className="contents">
          <SearchFilter
            filter={filter}
            setFilter={(filter) => {
              setFilter({ ...filter })
            }}
            search={() =>
              pageReplace({ ...params, filter: { ...filter }, pageNum: 1 })
            }
          />
          {applicationList && (
            <div className="table">
              <div className="table-head">
                <p className="total">
                  총 <strong>{comma(recordsTotal)}</strong>건
                </p>
                <div className="table-filter d-flex">
                  <label htmlFor="number-outputs" className="lbl me-5">
                    출력갯수 :
                  </label>
                  <select
                    name="number-outputs"
                    id="number-outputs"
                    className="int me-5"
                    value={params.listNum}
                    onChange={(e) => {
                      pageReplace({
                        ...params,
                        listNum: Number(e.target.value),
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  {/* 기획 없음 */}
                  {/* <select name="" id="" className="int">
                      <option value="">정렬방식</option>
                      <option value="" selected>
                        등록일 빠른순
                      </option>
                      <option value="">등록일 느린순</option>
                    </select> */}
                </div>
              </div>
              {/* //table-head */}
              <div className="table-body">
                <table border="0" cellSpacing="0" cellPadding="0">
                  <colgroup>
                    <col width="55px" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="*" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="12%" />
                    <col width="12%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <div className="th-move">No</div>
                      </th>
                      <th>ID</th>
                      <th>이름</th>
                      <th>지원공고 명</th>
                      <th>합격여부</th>
                      <th>결제상태</th>
                      <th>지원일시</th>
                      <th>결제일시</th>
                    </tr>
                  </thead>
                  <tbody>
                    {applicationList?.length != 0 ? (
                      applicationList.map((item, idx) => (
                        <tr key={idx}>
                          <td className="tac">
                            {recordsTotal - ((params.pageNum - 1) * params.listNum + idx)}
                          </td>
                          <td className="tac">
                            {item?.order?.product?.account || '-'}
                          </td>
                          <td className="tac">
                            {combineViewer(item?.order?.product?.firstName, item?.order?.product?.lastName)}
                          </td>
                          <td className="tac">{
                            item?.order?.product?.name 
                            ? 
                              <Link className="text-btn btn-primary" to={`/user/internship/detail?oid=${item?.order?.product?.orderId}&pid=${!isEmpty(item?.order?.product?.paymentId) ? item?.order?.product?.paymentId : ''}`}>
                                { item?.order?.product?.name }
                              </Link>
                            : '-'
                          }
                          </td>
                          <td className="tac">
                            {item?.order?.product?.applicationStatus || '-'}
                          </td>
                          <td className="tac">
                            {item?.status || '-'}
                          </td>
                          <td className="tac">
                            {item?.order?.product?.createdAt != '' && item?.order?.product?.createdAt 
                              ? setDetailDateFormat(item?.order?.product?.createdAt) 
                              : '-'}
                          </td>
                          <td className="tac">
                            {item?.createdAt != '' && item?.createdAt 
                              ? setDetailDateFormat(item?.createdAt) 
                              : '-'}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8">
                          <div className="table-nopost">
                            조회된 데이터가 없습니다.
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {applicationList?.length != 0 && (
                  <PaginationComponent
                    page={params.pageNum}
                    pageSize={params.listNum}
                    total={recordsTotal == 0 ? 1 : recordsTotal}
                    pageClick={(page) => {
                      pageReplace({ ...params, pageNum: page })
                    }}
                    paging={0}
                  />
                )}
              </div>
            </div>
          )}
          {/* //table */}
        </div>
      </Container>
    </Fragment>
  )
}

export default ContentsManagement
