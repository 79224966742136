import React, { Fragment, useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import {
  getUrlParams,
  chunk,
  setServerUrl,
  setDateTime,
} from '../../../utils/index'
import {
  getInternshipRecruitmentDetail,
  updateInternshipRecruitment,
  getPreInterview,
} from '../../../api/post'
import { getUniversity } from '../../../api/university'
import { getCommonCodes } from '../../../api/common'
import dayjs from 'dayjs'

const InternshipRecruitments = () => {
  const navigate = useNavigate()
  const param = getUrlParams(decodeURIComponent(window.location.href))
  const [recruitment, setRecruitment] = useState(null)
  const [edit, setEdit] = useState(false)
  const [industry, setIndustry] = useState(null)
  const [position, setPosition] = useState(null)
  const [workType, setWorkType] = useState(null)
  const [duration, setDuration] = useState(null)
  const [skill, setSkill] = useState(null)
  const [country, setCountry] = useState(null)
  const [division, setDivision] = useState(null)
  const [degree, setDegree] = useState(null)
  const [language, setLanguage] = useState(null)
  const [proficiency, setProficiency] = useState(null)
  const [certificate, setCertificate] = useState(null)
  const [gender, setGender] = useState(null)
  const [objective, setObjective] = useState(null)
  const [culture, setCulture] = useState(null)
  const [question, setQuestion] = useState(null)
  const [university, setUniversity] = useState(null)
  const [tip, setTip] = useState(false)

  const [support1, setSupport1] = useState(null)
  const [support2, setSupport2] = useState(null)
  const [support3, setSupport3] = useState(null)
  const [support4, setSupport4] = useState(null)
  const [support5, setSupport5] = useState(null)
  const trueFalse = [
    { value: 'true', txt: 'Yes' },
    { value: 'false', txt: 'No' },
  ]
  const documents = [
    '재학/졸업증명서',
    '여권사본',
    '성적증명서',
    '주거지 증명서',
    '자격증 사본',
    '언어성적표 사본',
    '운전면허증 사본',
  ]
  const process = [
    '썬더잡 성공매칭율',
    '비디오 인터뷰',
    'Requiered documents Verification',
    'OFFER LETTER 발행',
    'First day instruction letter 발행',
    'Welcoming & Induction',
  ]
  const dressCode = ['Business Attire', 'Smart Casual', 'Casual', 'Do not care']

  // 상세
  const getContents = async () => {
    if (Object.keys(param['rid']).length > 0) {
      const { code, data } = await getInternshipRecruitmentDetail(param['rid'])

      if (code == 200) {
        setRecruitment({
          ...data,
          uploadIntroductionImages: [...data.introductionImages],
          removeFiles: [],
        })
        initSupport(data)
      } else {
        navigate(-1)
      }
    }
  }

  const getDuration = async () => {
    const { code, data, msg } = await getCommonCodes('DR')
    if (code == 200) {
      setDuration([...data])
    } else {
      setDuration([])
    }
  }

  const getIndustry = async () => {
    const { code, data, msg } = await getCommonCodes('IC')
    if (code == 200) {
      setIndustry([...data])
    } else {
      setIndustry([])
    }
  }

  const getPosition = async () => {
    const { code, data, msg } = await getCommonCodes('IS')
    if (code == 200) {
      setPosition([...data])
    } else {
      setPosition([])
    }
  }

  const getWorkType = async () => {
    const { code, data, msg } = await getCommonCodes('WT')
    if (code == 200) {
      setWorkType([...data])
    } else {
      setWorkType([])
    }
  }

  const getSkill = async () => {
    const { code, data, msg } = await getCommonCodes('SK')
    if (code == 200) {
      setSkill([...data])
    } else {
      setSkill([])
    }
  }

  const getCounty = async () => {
    const { code, data, msg } = await getCommonCodes('CN')
    if (code == 200) {
      setCountry([...data])
    } else {
      setCountry([])
    }
  }

  const getDivision = async () => {
    const { code, data, msg } = await getCommonCodes('SF')
    if (code == 200) {
      setDivision([...data])
    } else {
      setDivision([])
    }
  }

  const getDegree = async () => {
    const { code, data, msg } = await getCommonCodes('EQ')
    if (code == 200) {
      setDegree([...data])
    } else {
      setDegree([])
    }
  }

  const getLanguage = async () => {
    const { code, data, msg } = await getCommonCodes('LN')
    if (code == 200) {
      setLanguage([...data])
    } else {
      setLanguage([])
    }
  }

  const getProficiency = async () => {
    const { code, data, msg } = await getCommonCodes('LP')
    if (code == 200) {
      setProficiency([...data])
    } else {
      setProficiency([])
    }
  }

  const getCertificate = async () => {
    const { code, data, msg } = await getCommonCodes('LC')
    if (code == 200) {
      setCertificate([...data])
    } else {
      setCertificate([])
    }
  }

  const getGender = async () => {
    const { code, data, msg } = await getCommonCodes('GN')
    if (code == 200) {
      setGender([...data])
    } else {
      setGender([])
    }
  }

  const getObjective = async () => {
    const { code, data, msg } = await getCommonCodes('IO')
    if (code == 200) {
      setObjective([...data])
    } else {
      setObjective([])
    }
  }

  const getCurture = async () => {
    const { code, data, msg } = await getCommonCodes('CC')
    if (code == 200) {
      setCulture([...data])
    } else {
      setCulture([])
    }
  }

  const getQuestion = async () => {
    const { code, data, msg } = await getPreInterview()
    if (code == 200) {
      setQuestion([...data])
    } else {
      setQuestion([])
    }
  }

  const callUniversity = async () => {
    const { code, data, msg } = await getUniversity()
    if (code == 200) {
      setUniversity([...data.items])
    } else {
      setUniversity([])
    }
  }

  const initSupport = (data) => {
    setSupport1([
      {
        id: 'SP001',
        nameEn: 'Wage (Minimum wage 100%)',
        check:
          (
            data.companySupports.filter(
              (item) => item.companySupportCode == 'SP001'
            ) || []
          ).length > 0,
      },
      {
        id: 'SP003',
        nameEn: 'Stipend (Minimum wage 75%)',
        check:
          (
            data.companySupports.filter(
              (item) => item.companySupportCode == 'SP003'
            ) || []
          ).length > 0,
      },
      {
        id: 'SP002',
        nameEn: 'Stipend (Minimum wage 50%)',
        check:
          (
            data.companySupports.filter(
              (item) => item.companySupportCode == 'SP002'
            ) || []
          ).length > 0,
      },
      {
        id: 'SP004',
        nameEn: 'Unpaid',
        check:
          (
            data.companySupports.filter(
              (item) => item.companySupportCode == 'SP004'
            ) || []
          ).length > 0,
      },
    ])
    setSupport2([
      {
        id: 'SP005',
        nameEn: 'Lunch and Transfortation cost',
        check:
          (
            data.companySupports.filter(
              (item) => item.companySupportCode == 'SP005'
            ) || []
          ).length > 0,
      },
    ])
    setSupport3([
      {
        id: 'SP006',
        nameEn: 'Airfare (Return)',
        check:
          (
            data.companySupports.filter(
              (item) => item.companySupportCode == 'SP006'
            ) || []
          ).length > 0,
      },
      {
        id: 'SP007',
        nameEn: 'Airfare (Single)',
        check:
          (
            data.companySupports.filter(
              (item) => item.companySupportCode == 'SP007'
            ) || []
          ).length > 0,
      },
    ])
    setSupport4([
      {
        id: 'SP009',
        nameEn: 'Support accommodation cost (100%)',
        check:
          (
            data.companySupports.filter(
              (item) => item.companySupportCode == 'SP009'
            ) || []
          ).length > 0,
      },
      {
        id: 'SP008',
        nameEn: 'Support accommodation cost (50%)',
        check:
          (
            data.companySupports.filter(
              (item) => item.companySupportCode == 'SP008'
            ) || []
          ).length > 0,
      },
    ])
    setSupport5([
      {
        id: 'SP010',
        nameEn: 'Thunderjob Matching fee (100%)',
        check:
          (
            data.companySupports.filter(
              (item) => item.companySupportCode == 'SP010'
            ) || []
          ).length > 0,
      },
      {
        id: 'SP011',
        nameEn: 'Thunderjob Matching fee (50%)',
        check:
          (
            data.companySupports.filter(
              (item) => item.companySupportCode == 'SP011'
            ) || []
          ).length > 0,
      },
      {
        id: 'SP012',
        nameEn: 'Thunderjob Matching fee (30%)',
        check:
          (
            data.companySupports.filter(
              (item) => item.companySupportCode == 'SP012'
            ) || []
          ).length > 0,
      },
    ])
  }

  const saveImage = (idx, files, url = null) => {
    const temp = recruitment.uploadIntroductionImages || []
    if (files !== null && files?.size > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(files)
      reader.onloadend = () => {
        temp[idx] = {
          type: temp[idx].type,
          file: files,
          preview: reader.result,
        }
        setRecruitment({
          ...recruitment,
          uploadIntroductionImages: [...temp],
        })
      }
    } else if (files !== null && files?.url) {
      temp[idx] = {
        ...temp[idx],
        file: files,
        preview: `${url}/${files?.url}`,
      }
      setRecruitment({
        ...recruitment,
        uploadIntroductionImages: [...temp],
      })
    }
  }

  const addIntroductionImage = (type) => {
    const temp = recruitment.uploadIntroductionImages || []
    temp.push({
      type: type,
      file: null,
    })
    setRecruitment({ ...recruitment, uploadIntroductionImages: [...temp] })
  }

  const init = () => {
    getDuration()
    getIndustry()
    getPosition()
    getWorkType()
    getSkill()
    getCounty()
    getDivision()
    getDegree()
    getLanguage()
    getProficiency()
    getCertificate()
    getGender()
    getObjective()
    getCurture()
    getQuestion()
    callUniversity()
  }

  const cancelContents = () => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(`작성한 내용을 저장하지 않고\n상위 페이지로 이동하시겠습니까?`)
    ) {
      navigate(-1)
    }
  }

  const updateContents = () => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(`작성한 내용을 저장하시겠습니까?`)
    ) {
      validate()
    }
  }

  const validate = () => {
    if (
      recruitment?.uploadIntroductionImages?.filter(
        (item) => item.type == 'logo' && item.file != null
      ).length == 0
    ) {
      return alert('Please enter logo image.')
    }
    if (
      recruitment?.uploadIntroductionImages?.filter(
        (item) => item.type == 'main' && item.file != null
      ).length == 0
    ) {
      return alert('Please enter main image.')
    }
    if (recruitment?.title.trim() == '') {
      return alert('Please enter title.')
    }
    if (recruitment?.industryCategoryCode.trim() == '') {
      return alert('Please select 1st industry.')
    }
    if (recruitment?.jobPositionCode.trim() == '') {
      return alert('Please select 2nd industry.')
    }
    if (recruitment?.skills.length == 0) {
      return alert('Please select required skill.')
    }
    if (recruitment?.nationalities.length == 0) {
      return alert('Please select required nationality.')
    }
    if (recruitment?.firstLanguageCode.trim() == '') {
      return alert('Please select required first language.')
    }
    if (recruitment?.otherLanguages.length == 0) {
      return alert('Please select required foreign language.')
    }
    if (recruitment?.otherLanguages.length != 0) {
      recruitment?.otherLanguages.map((item) => {
        if (item.languageCode.trim() == '') {
          return alert(
            'Please select required foreign language of other language.'
          )
        }
        if (item.languageProficiencyCode.trim() == '') {
          return alert('Please select required proficiency of other language.')
        }
      })
    }
    if (recruitment?.universities.length == 0) {
      return alert('Please select required university tier.')
    } else {
      if (recruitment?.universities[0].name == '') {
        return alert('Please select required university tier.')
      }
    }
    if (recruitment?.studyFields.length == 0) {
      return alert('Please select required study field.')
    }
    if (recruitment?.educationQualificationCode.trim() == '') {
      return alert('Please select required education qualification.')
    }
    if (recruitment?.certificates.length == 0) {
      return alert('Please select required certificate.')
    }
    if (recruitment?.experiences.length == 0) {
      return alert('Please select required experience.')
    }
    if (recruitment?.experiences.length != 0) {
      recruitment?.experiences.map((item) => {
        if (item.industryCategoryCode.trim() == '') {
          return alert('Please select required industry of experience.')
        }
        if (item.durationCode.trim() == '') {
          return alert('Please select required duration of experience.')
        }
      })
    }
    if (recruitment?.minAge == '' || recruitment?.maxAge == '') {
      return alert('Please select required age.')
    }
    if (Number(recruitment?.minAge) > Number(recruitment?.maxAge)) {
      return alert('Minimum age is larger than maximum age.')
    }
    if (recruitment?.objectives.length == 0) {
      return alert('Please select required objective.')
    }
    if (recruitment?.countryCode.trim() == '') {
      return alert('Please select location.')
    }
    if (recruitment?.companySupports.length == 0) {
      return alert('Please select company support.')
    }
    if (recruitment?.companyCultures.length == 0) {
      return alert('Please select company culture.')
    }
    if (recruitment?.processes.length == 0) {
      return alert('Please enter process.')
    } else {
      recruitment?.processes.map((item) => {
        if (item.process.trim() == '') {
          return alert('Please enter process of process.')
        }
      })
    }
    if (
      recruitment?.questions.length != 0 &&
      recruitment?.questions.length != 10
    ) {
      return alert('Please register only 10 interviews.')
    }

    save()
  }

  const save = async () => {
    let params = { ...recruitment }
    if (param['rid']) {
      let uploadTemp = recruitment?.uploadIntroductionImages
      uploadTemp = uploadTemp?.filter((item) => item?.preview)
      let removeTemp = []
      if (
        recruitment?.introductionImages &&
        recruitment?.uploadIntroductionImages
      ) {
        for (let i = 0; i < recruitment.introductionImages.length; i++) {
          let isRemove = true
          for (
            let j = 0;
            j < recruitment.uploadIntroductionImages.length;
            j++
          ) {
            if (
              recruitment.introductionImages[i].id ==
              recruitment.uploadIntroductionImages[j]?.id
            ) {
              // 원래 이미지와 업로드할 이미지가 동일하다면 체크
              isRemove = false
            }
            if (
              j == recruitment.uploadIntroductionImages.length - 1 &&
              isRemove
            ) {
              // 원래 이미지와 업로드할 이미지가 동일하지 않을 때
              removeTemp.push(recruitment.introductionImages[i])
            }
          }
        }
      }
      params.uploadIntroductionImages = uploadTemp
      params.removeFiles = removeTemp
    }

    const formData = new FormData()
    for (const [k, v] of Object.entries(params || {})) {
      if (k == 'startAt' || k == 'endAt') {
        // 날짜 데이터
        formData.append(k, setDateTime(dayjs(v).toDate()))
      } else if (k == 'internshipStartDate') {
        // 날짜 데이터
        formData.append(k, dayjs(dayjs(v).toDate()).format('YYYY-MM-DD'))
      } else if ((k == 'depositCode' || k == 'balanceCost') && v == '') {
        // 가격 데이터
        formData.append(k, '0')
      } else if (
        k == 'certificates' ||
        k == 'companyCultures' ||
        k == 'companySupports' ||
        k == 'dressCodes' ||
        k == 'experiences' ||
        k == 'nationalities' ||
        k == 'objectives' ||
        k == 'otherLanguages' ||
        k == 'processes' ||
        k == 'questions' ||
        k == 'skills' ||
        k == 'studyFields' ||
        k == 'universities' ||
        k == 'uploadIntroductionImages'
      ) {
        // Array 데이터
        v.forEach((data, idx) => {
          for (const [k1, v1] of Object.entries(data)) {
            if (k1 != 'preview' && v1 != null) {
              formData.append(`${k}[${idx}].${k1}`, v1)
            }
          }
        })
      } else if (k == 'introductionImages' || k == 'removeFiles') {
        // Array 데이터
        v.forEach((data, idx) => {
          for (const [k1, v1] of Object.entries(data)) {
            if (k1 == 'file') {
              for (const [k2, v2] of Object.entries(v1)) {
                if (v2 != null) {
                  formData.append(`${k}[${idx}].${k1}.${k2}`, v2)
                }
              }
            } else {
              if (v1 != null) {
                formData.append(`${k}[${idx}].${k1}`, v1)
              }
            }
          }
        })
      } else if (k == 'matchUpWithIntern' || k == 'company') {
        // Object 데이터
        for (const [k1, v1] of Object.entries(v)) {
          if (v1 != null) {
            formData.append(`${k}.${k1}`, v1)
          }
        }
      } else {
        if (k != 'applicantTotal' && v != null) {
          formData.append(k, v)
        }
      }
    }

    const { code, data } = await updateInternshipRecruitment(
      param['rid'],
      formData
    )

    if (code == 200) {
      getContents()
      alert('Save successed')
      setEdit(!edit)
    } else {
      return alert('Save failed')
    }
  }

  useEffect(() => {
    setRecruitment(null)
    getContents()
    init()
  }, [])

  return (
    <Fragment>
      <Container>
        {recruitment && (
          <div className="contents">
            <div className="card">
              <div className="d-flex justify-content-between">
                <div></div>
                <a
                  href="#"
                  className="comm-btn btn-md btn-gray bd-radius-3"
                  onClick={(e) => {
                    e.preventDefault()
                    setEdit(!edit)
                  }}
                >
                  {!edit ? '수정' : '보기'}
                </a>
              </div>
              <div className="card-head mt-10">
                <h6 className="card-title">기업정보</h6>
              </div>
              <div className="card-body">
                <section className="d-grid gap-30 flex-wrap">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">기업명</p>
                    <p className="p2">{recruitment.company.name}</p>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      기업 규모
                    </p>
                    <p className="p2">{recruitment.companySizeEn}</p>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      기업 웹사이트
                    </p>
                    <p className="p2">{recruitment.company.website}</p>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      기업 주소
                    </p>
                    <p className="p2">
                      {recruitment.company.address + ' '}
                      {recruitment.company.addressDetails}
                    </p>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-25">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      기업 로고
                    </p>
                    <div className="col-int">
                      {recruitment.uploadIntroductionImages &&
                        recruitment.uploadIntroductionImages.map(
                          (item, idx) =>
                            item.type == 'logo' && (
                              <React.Fragment key={idx}>
                                {edit && (
                                  <div
                                    className="int-filebox wid-100"
                                    style={{ height: 'auto' }}
                                  >
                                    <label
                                      htmlFor="a_fileName"
                                      className="comm-btn btn-secondary"
                                    >
                                      파일 선택
                                    </label>
                                    <input
                                      className="upload-name int"
                                      placeholder="파일을 선택하세요."
                                    />
                                    <input
                                      type="file"
                                      id="a_fileName"
                                      className="upload-hidden"
                                      onChange={(e) => {
                                        if (e.target.files && e.target.value) {
                                          saveImage(idx, e.target.files[0])
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                                {item.preview ? (
                                  <img src={item.preview} alt="미리보기" />
                                ) : item.file?.url ? (
                                  <img
                                    style={{ maxWidth: '120px' }}
                                    src={setServerUrl(item.file.url)}
                                    alt="미리보기"
                                  />
                                ) : (
                                  <div></div>
                                )}
                              </React.Fragment>
                            )
                        )}
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-25">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      대표이미지
                    </p>
                    <div className="col-int">
                      {recruitment.uploadIntroductionImages &&
                        recruitment.uploadIntroductionImages.map(
                          (item, idx) =>
                            item.type == 'main' && (
                              <React.Fragment key={idx}>
                                {edit && (
                                  <div
                                    className="int-filebox wid-100"
                                    style={{ height: 'auto' }}
                                  >
                                    <label
                                      htmlFor="b_fileName"
                                      className="comm-btn btn-secondary"
                                    >
                                      파일 선택
                                    </label>
                                    <input
                                      className="upload-name int"
                                      placeholder="파일을 선택하세요."
                                    />
                                    <input
                                      type="file"
                                      id="b_fileName"
                                      className="upload-hidden"
                                      onChange={(e) => {
                                        if (e.target.files && e.target.value) {
                                          saveImage(idx, e.target.files[0])
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                                {item.preview ? (
                                  <img src={item.preview} alt="미리보기" />
                                ) : item.file?.url ? (
                                  <img
                                    style={{ maxWidth: '600px' }}
                                    src={setServerUrl(item.file.url)}
                                    alt="미리보기"
                                  />
                                ) : (
                                  <div></div>
                                )}
                              </React.Fragment>
                            )
                        )}
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-25">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      기업이미지
                    </p>
                    <div className="wid-75">
                      {recruitment.uploadIntroductionImages &&
                        recruitment.uploadIntroductionImages.length > 0 &&
                        recruitment.uploadIntroductionImages.map(
                          (item, idx) =>
                            item.type == 'sub' && (
                              <div
                                className={idx != 0 ? 'mt-30' : ''}
                                key={idx}
                              >
                                {edit && (
                                  <div className="btn_form">
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        const temp =
                                          recruitment.uploadIntroductionImages.filter(
                                            (item, index) =>
                                              index != idx ? item : null
                                          )
                                        setRecruitment({
                                          ...recruitment,
                                          uploadIntroductionImages: [...temp],
                                        })
                                      }}
                                      className="form_btn"
                                    >
                                      delete
                                    </a>
                                  </div>
                                )}
                                <div
                                  className="grid-text"
                                  style={{
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                  }}
                                >
                                  {edit && (
                                    <div
                                      className="int-filebox wid-100"
                                      style={{ height: 'auto' }}
                                    >
                                      <label
                                        htmlFor={`c_fileName_${idx}`}
                                        className="comm-btn btn-secondary"
                                      >
                                        파일 선택
                                      </label>
                                      <input
                                        className="upload-name int"
                                        placeholder="파일을 선택하세요."
                                      />
                                      <input
                                        type="file"
                                        id={`c_fileName_${idx}`}
                                        className="upload-hidden"
                                        onChange={(e) => {
                                          if (
                                            e.target.files &&
                                            e.target.value
                                          ) {
                                            saveImage(idx, e.target.files[0])
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                                  {item.preview ? (
                                    <img src={item.preview} alt="미리보기" />
                                  ) : item.file?.url ? (
                                    <img
                                      style={{ maxWidth: '600px' }}
                                      src={setServerUrl(item.file.url)}
                                      alt="미리보기"
                                    />
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                                {edit && (
                                  <div className="btn_form">
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        addIntroductionImage('sub')
                                      }}
                                      className="form_btn"
                                    >
                                      add
                                    </a>
                                  </div>
                                )}
                              </div>
                            )
                        )}
                      {recruitment.uploadIntroductionImages.filter(
                        (item) => item.type == 'sub'
                      ).length == 0 && (
                        <div>
                          {edit && (
                            <div className="btn_form">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                  addIntroductionImage('sub')
                                }}
                                className="form_btn"
                              >
                                add
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-25">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">Title</p>
                    <input
                      type="text"
                      value={recruitment.title}
                      disabled={!edit}
                      onChange={(e) =>
                        setRecruitment({
                          ...recruitment,
                          title: e.target.value,
                        })
                      }
                      className="int wid-50"
                      placeholder="Please input title"
                    />
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Posting Period
                    </p>
                    <div className="form-row">
                      <div className="col-int">
                        <input
                          type="date"
                          value={dayjs(recruitment.startAt).format(
                            'YYYY-MM-DD'
                          )}
                          disabled={!edit}
                          onChange={(e) => {
                            const date = new Date(e.target.value)
                            if (date > dayjs(recruitment.endAt).toDate()) {
                              setRecruitment({
                                ...recruitment,
                                startAt: date,
                                endAt: date,
                              })
                            } else {
                              setRecruitment({ ...recruitment, startAt: date })
                            }
                          }}
                          className="int"
                          placeholder="text input"
                        />
                      </div>
                    </div>
                    <span className="h-hyphen">-</span>
                    <div className="form-row">
                      <input
                        type="date"
                        value={dayjs(recruitment.endAt).format('YYYY-MM-DD')}
                        disabled={!edit}
                        mindate={dayjs(recruitment.startAt).format(
                          'YYYY-MM-DD'
                        )}
                        className="int"
                        placeholder="text input"
                        onChange={(e) => {
                          setRecruitment({
                            ...recruitment,
                            endAt: new Date(e.target.value),
                          })
                        }}
                      />
                    </div>
                  </article>
                </section>
              </div>
            </div>

            <div className="card">
              <div className="card-head">
                <h6 className="card-title">직무 정보</h6>
              </div>
              <div className="card-body">
                <section className="d-grid gap-30 flex-wrap">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Job Position
                    </p>
                    <select
                      className="int wid-30 me-30"
                      disabled={!edit}
                      value={recruitment.industryCategoryCode}
                      onChange={(e) => {
                        setRecruitment({
                          ...recruitment,
                          industryCategoryCode: e.target.value,
                        })
                      }}
                    >
                      <option value={''} disabled>
                        Select 1st industry
                      </option>
                      {industry?.map((item, idx) => (
                        <option value={item.id} key={idx}>
                          {item.nameEn}
                        </option>
                      ))}
                    </select>
                    <select
                      className="int wid-30"
                      disabled={!edit}
                      value={recruitment.jobPositionCode}
                      onChange={(e) => {
                        setRecruitment({
                          ...recruitment,
                          jobPositionCode: e.target.value,
                        })
                      }}
                    >
                      <option value={''} disabled>
                        Select 2nd industry
                      </option>
                      {position
                        ?.filter(
                          (po) =>
                            po.parentId == recruitment.industryCategoryCode
                        )
                        .map((item, idx) => (
                          <option value={item.id} key={idx}>
                            {item.nameEn}
                          </option>
                        ))}
                    </select>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Work Type
                    </p>
                    <div className="col-int">
                      <div className="d-flex">
                        {workType?.map((wt, idx) => (
                          <React.Fragment key={idx}>
                            <input
                              type="radio"
                              name="work_radio"
                              id={`work_radio${idx}`}
                              checked={wt.id == recruitment.workTypeCode}
                              onChange={() => {
                                if (edit) {
                                  setRecruitment({
                                    ...recruitment,
                                    workTypeCode: wt.id,
                                  })
                                }
                              }}
                            />
                            <label
                              style={{ cursor: edit ? 'pointer' : 'default' }}
                              htmlFor={`work_radio${idx}`}
                              className="me-25"
                            >
                              <span
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                              ></span>
                              {wt.nameEn}
                            </label>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Work Period
                    </p>
                    <div className="form-row">
                      <div className="col-int">
                        <input
                          type="month"
                          disabled={!edit}
                          value={
                            recruitment.internshipStartDate
                              ? dayjs(recruitment.internshipStartDate).format(
                                  'YYYY-MM'
                                )
                              : null
                          }
                          onChange={(e) => {
                            setRecruitment({
                              ...recruitment,
                              internshipStartDate: e.target.value,
                            })
                          }}
                          className="int"
                          placeholder="text input"
                        />
                      </div>
                    </div>
                    <span className="h-hyphen">-</span>
                    <select
                      className="int wid-20"
                      disabled={!edit}
                      value={recruitment.internshipDurationCode}
                      onChange={(e) => {
                        setRecruitment({
                          ...recruitment,
                          internshipDurationCode: e.target.value,
                        })
                      }}
                    >
                      <option value={''} disabled>
                        Select duration
                      </option>
                      {duration?.map((item, idx) => (
                        <option value={item.id} key={idx}>
                          {item.nameEn}
                        </option>
                      ))}
                    </select>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Work Description
                    </p>
                    <div className="int-title int-text wid-75">
                      <textarea
                        type="text"
                        id="a_title"
                        value={recruitment.jobDescription}
                        disabled={!edit}
                        cols="30"
                        rows="10"
                        className="int int_textarea height_1"
                        placeholder="Please input work description."
                        onChange={(e) => {
                          if (e.target.value.length < 1000) {
                            setRecruitment({
                              ...recruitment,
                              jobDescription: e.target.value,
                            })
                          }
                        }}
                      />
                      <span className="element-text">
                        {recruitment.jobDescription.length}/
                        <span className="text-primary">1000</span>자
                      </span>
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Required Skill
                    </p>
                    <div className="col-int">
                      {chunk(skill, 3).map((item, idx) => (
                        <div className="d-flex" key={idx}>
                          {item.map((sk, idx2) => (
                            <React.Fragment key={idx2}>
                              <input
                                type="checkbox"
                                name="skill"
                                id={`skill_${idx}_${idx2}`}
                                checked={
                                  recruitment.skills.filter(
                                    (item) => item.skillCode == sk.id
                                  ).length > 0
                                }
                                onChange={(e) => {
                                  if (edit) {
                                    if (e.target.checked) {
                                      const temp = recruitment.skills
                                      temp.push({
                                        skillCode: sk.id,
                                      })
                                      setRecruitment({
                                        ...recruitment,
                                        skills: [...temp],
                                      })
                                    } else {
                                      const temp = recruitment.skills
                                      setRecruitment({
                                        ...recruitment,
                                        skills: [
                                          ...temp.filter(
                                            (delTemp) =>
                                              delTemp.skillCode != sk.id
                                          ),
                                        ],
                                      })
                                    }
                                  }
                                }}
                              />
                              <label
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                                htmlFor={`skill_${idx}_${idx2}`}
                                className="me-25"
                              >
                                <span
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                ></span>
                                {sk.nameEn}
                              </label>
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Nationality
                    </p>
                    <div className="col-int">
                      {chunk(country, 5).map((item, idx) => (
                        <div className="d-flex" key={idx}>
                          {item.map((cn, idx2) => (
                            <React.Fragment key={idx2}>
                              <input
                                type="checkbox"
                                name="country"
                                id={`country_${idx}_${idx2}`}
                                checked={
                                  recruitment.nationalities.filter(
                                    (item) => item.nationalityCode == cn.id
                                  ).length > 0
                                }
                                onChange={(e) => {
                                  if (edit) {
                                    if (e.target.checked) {
                                      const temp = recruitment.nationalities
                                      temp.push({
                                        nationalityCode: cn.id,
                                      })
                                      setRecruitment({
                                        ...recruitment,
                                        nationalities: [...temp],
                                      })
                                    } else {
                                      const temp = recruitment.nationalities
                                      setRecruitment({
                                        ...recruitment,
                                        nationalities: [
                                          ...temp.filter(
                                            (delTemp) =>
                                              delTemp.nationalityCode != cn.id
                                          ),
                                        ],
                                      })
                                    }
                                  }
                                }}
                              />
                              <label
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                                htmlFor={`country_${idx}_${idx2}`}
                                className="me-25"
                              >
                                <span
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                ></span>
                                {cn.nameEn}
                              </label>
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-30 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      First Language
                    </p>
                    <select
                      className="int wid-30"
                      disabled={!edit}
                      value={recruitment.firstLanguageCode}
                      onChange={(e) => {
                        setRecruitment({
                          ...recruitment,
                          firstLanguageCode: e.target.value,
                        })
                      }}
                    >
                      <option value={''}>-</option>
                      {language?.map((item, idx) => (
                        <option value={item.id} key={idx}>
                          {item.nameEn}
                        </option>
                      ))}
                    </select>
                  </article>
                </section>
                <section className="d-grid gap-30 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Other Language
                    </p>
                    <div className="wid-75">
                      {recruitment.otherLanguages.length > 0 &&
                        recruitment.otherLanguages.map((item, idx) => (
                          <div className={idx != 0 ? 'mt-30' : ''} key={idx}>
                            {edit && (
                              <div className="btn_form">
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const temp =
                                      recruitment.otherLanguages.filter(
                                        (item, index) =>
                                          index != idx ? item : null
                                      )
                                    setRecruitment({
                                      ...recruitment,
                                      otherLanguages: [...temp],
                                    })
                                  }}
                                  className="form_btn"
                                >
                                  delete
                                </a>
                              </div>
                            )}
                            <div
                              className="grid-text"
                              style={{ marginTop: '5px', marginBottom: '5px' }}
                            >
                              <div className="innter-flex">
                                <p className="p3 wid-50 me-30">
                                  Foreign language
                                </p>
                                <p className="p3 wid-50">Proficiency</p>
                              </div>
                              <div className="innter-flex mt-20">
                                <select
                                  className="int wid-50 me-30"
                                  disabled={!edit}
                                  value={item.languageCode}
                                  onChange={(e) => {
                                    const temp = recruitment.otherLanguages
                                    temp[idx].languageCode = e.target.value
                                    setRecruitment({
                                      ...recruitment,
                                      otherLanguages: [...temp],
                                    })
                                  }}
                                >
                                  <option value={''}>-</option>
                                  {language?.map((item, idx) => (
                                    <option value={item.id} key={idx}>
                                      {item.nameEn}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  className="int wid-50"
                                  disabled={!edit}
                                  value={item.languageProficiencyCode}
                                  onChange={(e) => {
                                    const temp = recruitment.otherLanguages
                                    temp[idx].languageProficiencyCode =
                                      e.target.value
                                    setRecruitment({
                                      ...recruitment,
                                      otherLanguages: [...temp],
                                    })
                                  }}
                                >
                                  <option value={''}>-</option>
                                  {proficiency?.map((item, idx) => (
                                    <option value={item.id} key={idx}>
                                      {item.nameEn}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {edit &&
                              idx + 1 == recruitment.otherLanguages.length && (
                                <div className="btn_form">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      const temp = recruitment.otherLanguages
                                      temp.push({
                                        languageCode: '',
                                        languageProficiencyCode: '',
                                      })
                                      setRecruitment({
                                        ...recruitment,
                                        otherLanguages: [...temp],
                                      })
                                    }}
                                    className="form_btn"
                                  >
                                    add
                                  </a>
                                </div>
                              )}
                          </div>
                        ))}
                      {recruitment.otherLanguages.length == 0 && (
                        <div>
                          {edit && (
                            <div className="btn_form">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                  const temp = recruitment.otherLanguages
                                  temp.push({
                                    languageCode: '',
                                    languageProficiencyCode: '',
                                  })
                                  setRecruitment({
                                    ...recruitment,
                                    otherLanguages: [...temp],
                                  })
                                }}
                                className="form_btn"
                              >
                                add
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-30 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      University Tier
                    </p>
                    <select
                      className="int wid-10"
                      disabled={!edit}
                      value={recruitment.universities[0]?.name || ''}
                      onChange={(e) => {
                        const temp = recruitment.universities
                        temp[0].name = e.target.value
                        setRecruitment({
                          ...recruitment,
                          universities: [...temp],
                        })
                      }}
                    >
                      <option value={''}>-</option>
                      <option value={'1'}>1 only</option>
                      <option value={'2'}>1~2 tier</option>
                      <option value={'3'}>1~3 tier</option>
                      <option value={'4'}>1~4 tier</option>
                      <option value={'5'}>1~5 tier</option>
                    </select>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        setTip(true)
                      }}
                      className="form_btn"
                      style={{
                        marginLeft: '20px',
                        width: '116px',
                      }}
                    >
                      University Tier
                    </a>
                    <div
                      className="l__popup pg__popup"
                      id="popup__pg1"
                      style={{ display: tip ? 'block' : 'none' }}
                    >
                      <div className="dim"></div>
                      <div className="l-table">
                        <div className="l-cell">
                          <div className="popup__area">
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <button
                                type="button"
                                className="btn-close"
                                style={{
                                  marginTop: '10px',
                                  marginRight: '10px',
                                }}
                                onClick={() => {
                                  setTip(false)
                                }}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                                    fill="#666"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div className="popup__inner">
                              <p className="tit">University Tier</p>
                              <div className="conbox scrollcon">
                                <div className="scroll_box_p">
                                  <p className="popup_t1">
                                    <p>TIER 1</p>
                                    {university
                                      ?.filter((uv) => uv.tier == 1)
                                      ?.map((item, idx) => (
                                        <p
                                          key={idx}
                                          style={{ marginLeft: '10px' }}
                                        >
                                          {item.name}
                                        </p>
                                      ))}
                                    <p style={{ marginTop: '10px' }}>TIER 2</p>
                                    {university
                                      ?.filter((uv) => uv.tier == 2)
                                      ?.map((item, idx) => (
                                        <p
                                          key={idx}
                                          style={{ marginLeft: '10px' }}
                                        >
                                          {item.name}
                                        </p>
                                      ))}
                                    <p style={{ marginTop: '10px' }}>TIER 3</p>
                                    {university
                                      ?.filter((uv) => uv.tier == 3)
                                      ?.map((item, idx) => (
                                        <p
                                          key={idx}
                                          style={{ marginLeft: '10px' }}
                                        >
                                          {item.name}
                                        </p>
                                      ))}
                                    <p style={{ marginTop: '10px' }}>TIER 4</p>
                                    {university
                                      ?.filter((uv) => uv.tier == 4)
                                      ?.map((item, idx) => (
                                        <p
                                          key={idx}
                                          style={{ marginLeft: '10px' }}
                                        >
                                          {item.name}
                                        </p>
                                      ))}
                                    <p style={{ marginTop: '10px' }}>TIER 5</p>
                                    {university
                                      ?.filter((uv) => uv.tier == 5)
                                      ?.map((item, idx) => (
                                        <p
                                          key={idx}
                                          style={{ marginLeft: '10px' }}
                                        >
                                          {item.name}
                                        </p>
                                      ))}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Division
                    </p>
                    <div className="col-int">
                      {chunk(division, 4).map((item, idx) => (
                        <div className="d-flex" key={idx}>
                          {item.map((dv, idx2) => (
                            <React.Fragment key={idx2}>
                              <input
                                type="checkbox"
                                name="division"
                                id={`division_${idx}_${idx2}`}
                                checked={
                                  recruitment.studyFields.filter(
                                    (item) => item.studyFieldCode == dv.id
                                  ).length > 0
                                }
                                onChange={(e) => {
                                  if (edit) {
                                    if (e.target.checked) {
                                      const temp = recruitment.studyFields
                                      temp.push({
                                        studyFieldCode: dv.id,
                                      })
                                      setRecruitment({
                                        ...recruitment,
                                        studyFields: [...temp],
                                      })
                                    } else {
                                      const temp = recruitment.studyFields
                                      setRecruitment({
                                        ...recruitment,
                                        studyFields: [
                                          ...temp.filter(
                                            (delTemp) =>
                                              delTemp.studyFieldCode != dv.id
                                          ),
                                        ],
                                      })
                                    }
                                  }
                                }}
                              />
                              <label
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                                htmlFor={`division_${idx}_${idx2}`}
                                className="me-25"
                              >
                                <span
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                ></span>
                                {dv.nameEn}
                              </label>
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-30 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Minimum Education Qualification
                    </p>
                    <select
                      className="int wid-30"
                      disabled={!edit}
                      value={recruitment.educationQualificationCode}
                      onChange={(e) => {
                        setRecruitment({
                          ...recruitment,
                          educationQualificationCode: e.target.value,
                        })
                      }}
                    >
                      <option value={''}>-</option>
                      {degree?.map((item, idx) => (
                        <option value={item.id} key={idx}>
                          {item.nameEn}
                        </option>
                      ))}
                    </select>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Certificate
                    </p>
                    <div className="col-int">
                      {chunk(certificate, 2).map((item, idx) => (
                        <div className="d-flex" key={idx}>
                          {item.map((cf, idx2) => (
                            <React.Fragment key={idx2}>
                              <input
                                type="checkbox"
                                name="certificate"
                                id={`certificate_${idx}_${idx2}`}
                                checked={
                                  recruitment.certificates.filter(
                                    (item) => item.certificateCode == cf.id
                                  ).length > 0
                                }
                                onChange={(e) => {
                                  if (edit) {
                                    if (e.target.checked) {
                                      const temp = recruitment.certificates
                                      temp.push({
                                        certificateCode: cf.id,
                                      })
                                      setRecruitment({
                                        ...recruitment,
                                        certificates: [...temp],
                                      })
                                    } else {
                                      const temp = recruitment.certificates
                                      setRecruitment({
                                        ...recruitment,
                                        certificates: [
                                          ...temp.filter(
                                            (delTemp) =>
                                              delTemp.certificateCode != cf.id
                                          ),
                                        ],
                                      })
                                    }
                                  }
                                }}
                              />
                              <label
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                                htmlFor={`certificate_${idx}_${idx2}`}
                                className="me-25"
                              >
                                <span
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                ></span>
                                {cf.nameEn}
                              </label>
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-30 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Experience
                    </p>
                    <div className="wid-75">
                      {recruitment.experiences.length > 0 &&
                        recruitment.experiences.map((item, idx) => (
                          <div key={idx} className={idx != 0 ? 'mt-30' : ''}>
                            {edit && (
                              <div className="btn_form">
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const temp = recruitment.experiences.filter(
                                      (item, index) =>
                                        index != idx ? item : null
                                    )
                                    setRecruitment({
                                      ...recruitment,
                                      experiences: [...temp],
                                    })
                                  }}
                                  className="form_btn"
                                >
                                  delete
                                </a>
                              </div>
                            )}
                            <div
                              className="grid-text"
                              style={{ marginTop: '5px', marginBottom: '5px' }}
                            >
                              <div className="innter-flex">
                                <p className="p3 wid-50 me-30">Industry</p>
                                <p className="p3 wid-50">Duration</p>
                              </div>
                              <div className="innter-flex mt-20">
                                <select
                                  className="int wid-50 me-30"
                                  disabled={!edit}
                                  value={item.industryCategoryCode}
                                  onChange={(e) => {
                                    const temp = recruitment.experiences
                                    temp[idx].industryCategoryCode =
                                      e.target.value
                                    setRecruitment({
                                      ...recruitment,
                                      experiences: [...temp],
                                    })
                                  }}
                                >
                                  <option value={''}>-</option>
                                  {industry?.map((item, idx) => (
                                    <option value={item.id} key={idx}>
                                      {item.nameEn}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  className="int wid-50"
                                  disabled={!edit}
                                  value={item.durationCode}
                                  onChange={(e) => {
                                    const temp = recruitment.experiences
                                    temp[idx].durationCode = e.target.value
                                    setRecruitment({
                                      ...recruitment,
                                      experiences: [...temp],
                                    })
                                  }}
                                >
                                  <option value={''}>-</option>
                                  {duration?.map((item, idx) => (
                                    <option value={item.id} key={idx}>
                                      {item.nameEn}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {edit &&
                              idx + 1 == recruitment.experiences.length && (
                                <div className="btn_form">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      const temp = recruitment.experiences
                                      temp.push({
                                        industryCategoryCode: '',
                                        durationCode: '',
                                      })
                                      setRecruitment({
                                        ...recruitment,
                                        experiences: [...temp],
                                      })
                                    }}
                                    className="form_btn"
                                  >
                                    add
                                  </a>
                                </div>
                              )}
                          </div>
                        ))}
                      {recruitment.experiences.length == 0 && (
                        <div>
                          {edit && (
                            <div className="btn_form">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                  const temp = recruitment.experiences
                                  temp.push({
                                    industryCategoryCode: '',
                                    durationCode: '',
                                  })
                                  setRecruitment({
                                    ...recruitment,
                                    experiences: [...temp],
                                  })
                                }}
                                className="form_btn"
                              >
                                add
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-30 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">Gender</p>
                    <select
                      className="int wid-20"
                      disabled={!edit}
                      value={recruitment.genderCode}
                      onChange={(e) => {
                        setRecruitment({
                          ...recruitment,
                          genderCode: e.target.value,
                        })
                      }}
                    >
                      <option value={''}>Both</option>
                      {gender?.map((item, idx) => (
                        <option value={item.id} key={idx}>
                          {item.nameEn}
                        </option>
                      ))}
                    </select>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">Age</p>
                    <input
                      type="number"
                      id=""
                      className="int wid-10"
                      placeholder={0}
                      disabled={!edit}
                      value={recruitment.minAge}
                      onChange={(e) => {
                        setRecruitment({
                          ...recruitment,
                          minAge: e.target.value,
                        })
                      }}
                    />
                    <span className="h-hyphen">-</span>
                    <input
                      type="number"
                      id=""
                      className="int wid-10"
                      placeholder={0}
                      disabled={!edit}
                      value={recruitment.maxAge}
                      onChange={(e) => {
                        setRecruitment({
                          ...recruitment,
                          maxAge: e.target.value,
                        })
                      }}
                    />
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Required Description
                    </p>
                    <div className="int-title int-text wid-75">
                      <textarea
                        type="text"
                        id="a_title"
                        cols="30"
                        rows="10"
                        className="int int_textarea height_1"
                        placeholder="Please input required description."
                        disabled={!edit}
                        onChange={(e) =>
                          setRecruitment({
                            ...recruitment,
                            jobRequirementsDescription: e.target.value,
                          })
                        }
                      />
                      <span className="element-text">
                        {recruitment.jobRequirementsDescription.length}/
                        <span className="text-primary">1000</span>자
                      </span>
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Internship Purpose
                    </p>
                    <div className="col-int">
                      {chunk(objective, 3).map((item, idx) => (
                        <div className="d-flex" key={idx}>
                          {item.map((oj, idx2) => (
                            <React.Fragment key={idx2}>
                              <input
                                type="checkbox"
                                name="objective"
                                id={`objective_${idx}_${idx2}`}
                                checked={
                                  recruitment.objectives.filter(
                                    (item) => item.objectiveCode == oj.id
                                  ).length > 0
                                }
                                onChange={(e) => {
                                  if (edit) {
                                    if (e.target.checked) {
                                      const temp = recruitment.objectives
                                      temp.push({
                                        objectiveCode: oj.id,
                                      })
                                      setRecruitment({
                                        ...recruitment,
                                        objectives: [...temp],
                                      })
                                    } else {
                                      const temp = recruitment.objectives
                                      setRecruitment({
                                        ...recruitment,
                                        objectives: [
                                          ...temp.filter(
                                            (delTemp) =>
                                              delTemp.objectiveCode != oj.id
                                          ),
                                        ],
                                      })
                                    }
                                  }
                                }}
                              />
                              <label
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                                htmlFor={`objective_${idx}_${idx2}`}
                                className="me-25"
                              >
                                <span
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                ></span>
                                {oj.nameEn}
                              </label>
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Required Documents
                    </p>
                    <div className="col-int">
                      {chunk(documents, 3).map((item, idx) => (
                        <div className="d-flex" key={idx}>
                          {item.map((rd, idx2) => (
                            <React.Fragment key={idx2}>
                              <input
                                type="checkbox"
                                name="documents"
                                id={`documents_${idx}_${idx2}`}
                                checked={
                                  recruitment.requiredDocuments
                                    .split('\r\n')
                                    .filter((item) => item == rd).length > 0
                                }
                                onChange={(e) => {
                                  if (edit) {
                                    if (e.target.checked) {
                                      let temp = recruitment.requiredDocuments
                                      temp += `${rd}\r\n`
                                      setRecruitment({
                                        ...recruitment,
                                        requiredDocuments: temp,
                                      })
                                    } else {
                                      let temp = recruitment.requiredDocuments
                                      temp = temp.replace(`${rd}\r\n`, '')
                                      setRecruitment({
                                        ...recruitment,
                                        requiredDocuments: temp,
                                      })
                                    }
                                  }
                                }}
                              />
                              <label
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                                htmlFor={`documents_${idx}_${idx2}`}
                                className="me-25"
                              >
                                <span
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                ></span>
                                {rd}
                              </label>
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </article>
                </section>
              </div>
            </div>

            <div className="card">
              <div className="card-head">
                <h6 className="card-title">지원 사항</h6>
              </div>
              <div className="card-body">
                <section className="d-grid gap-30 flex-wrap">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Location
                    </p>
                    <select
                      className="int wid-20"
                      disabled={!edit}
                      value={recruitment.countryCode}
                      onChange={(e) => {
                        setRecruitment({
                          ...recruitment,
                          countryCode: e.target.value,
                        })
                      }}
                    >
                      <option value={''}>-</option>
                      {country?.map((item, idx) => (
                        <option value={item.id} key={idx}>
                          {item.nameEn}
                        </option>
                      ))}
                    </select>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Deposit Cost
                    </p>
                    <p
                      className="p1 me-10"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      $
                    </p>
                    <input
                      type="number"
                      id=""
                      className="int wid-20"
                      placeholder={0}
                      disabled={!edit}
                      value={recruitment.depositCost}
                      onChange={(e) => {
                        setRecruitment({
                          ...recruitment,
                          depositCost: e.target.value,
                        })
                      }}
                    />
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Balance Cost
                    </p>
                    <p
                      className="p1 me-10"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      $
                    </p>
                    <input
                      type="number"
                      id=""
                      className="int wid-20"
                      placeholder={0}
                      disabled={!edit}
                      value={recruitment.balanceCost}
                      onChange={(e) => {
                        setRecruitment({
                          ...recruitment,
                          balanceCost: e.target.value,
                        })
                      }}
                    />
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Company Support
                    </p>
                    <div className="col-int">
                      {support1 &&
                        chunk(support1, 2).map((item, idx) => (
                          <div className="d-flex" key={idx}>
                            {item.map((sp, idx2) => (
                              <React.Fragment key={`${idx}_${idx2}`}>
                                <input
                                  type="radio"
                                  name="support_radio"
                                  id={`support1_${idx}_${idx2}`}
                                  checked={
                                    recruitment.companySupports.filter(
                                      (item) => item.companySupportCode == sp.id
                                    ).length > 0
                                  }
                                  onChange={() => {
                                    if (edit) {
                                      let temp = recruitment.companySupports
                                      const check = support1.filter(
                                        (item) => item.check
                                      )
                                      if (check.length > 0) {
                                        temp = temp.filter(
                                          (item) =>
                                            item.companySupportCode !=
                                            check[0].id
                                        )
                                      }
                                      temp.push({
                                        companySupportCode: sp.id,
                                      })
                                      const support = support1.map(
                                        (item, idx3) => ({
                                          ...item,
                                          check:
                                            idx3 == idx * 2 + idx2
                                              ? true
                                              : false,
                                        })
                                      )
                                      setRecruitment({
                                        ...recruitment,
                                        companySupports: [...temp],
                                      })
                                      setSupport1([...support])
                                    }
                                  }}
                                />
                                <label
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                  htmlFor={`support1_${idx}_${idx2}`}
                                  className="me-25"
                                >
                                  <span
                                    style={{
                                      cursor: edit ? 'pointer' : 'default',
                                    }}
                                  ></span>
                                  {sp.nameEn}
                                </label>
                              </React.Fragment>
                            ))}
                          </div>
                        ))}
                      <div className="d-flex">
                        {support2 &&
                          support2.map((sp, idx) => (
                            <React.Fragment key={idx}>
                              <input
                                type="checkbox"
                                name="support_checkbox2"
                                id={`support2_${idx}`}
                                checked={
                                  recruitment.companySupports.filter(
                                    (item) => item.companySupportCode == sp.id
                                  ).length > 0
                                }
                                onChange={(e) => {
                                  if (edit) {
                                    if (e.target.checked) {
                                      let temp = recruitment.companySupports
                                      temp.push({
                                        companySupportCode: sp.id,
                                      })
                                      const support = support2.map(
                                        (item, idx2) => ({
                                          ...item,
                                          check: idx == idx2 ? true : false,
                                        })
                                      )
                                      setRecruitment({
                                        ...recruitment,
                                        companySupports: [...temp],
                                      })
                                      setSupport2([...support])
                                    } else {
                                      let temp = recruitment.companySupports
                                      const check = support2.filter(
                                        (item) => item.check
                                      )
                                      if (check.length > 0) {
                                        temp = temp.filter(
                                          (item) =>
                                            item.companySupportCode !=
                                            check[0].id
                                        )
                                      }
                                      const support = support2.map(
                                        (item, idx2) => ({
                                          ...item,
                                          check: idx == idx2 ? true : false,
                                        })
                                      )
                                      setRecruitment({
                                        ...recruitment,
                                        companySupports: [...temp],
                                      })
                                      setSupport2([...support])
                                    }
                                  }
                                }}
                              />
                              <label
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                                htmlFor={`support2_${idx}`}
                                className="me-25"
                              >
                                <span
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                ></span>
                                {sp.nameEn}
                              </label>
                            </React.Fragment>
                          ))}
                      </div>
                      <div className="d-flex">
                        {support3 &&
                          support3.map((sp, idx) => (
                            <React.Fragment key={idx}>
                              <input
                                type="checkbox"
                                name="support_checkbox3"
                                id={`support3_${idx}`}
                                checked={
                                  recruitment.companySupports.filter(
                                    (item) => item.companySupportCode == sp.id
                                  ).length > 0
                                }
                                onChange={(e) => {
                                  if (edit) {
                                    if (e.target.checked) {
                                      let temp = recruitment.companySupports
                                      const check = support3.filter(
                                        (item) => item.check
                                      )
                                      if (check.length > 0) {
                                        temp = temp.filter(
                                          (item) =>
                                            item.companySupportCode !=
                                            check[0].id
                                        )
                                      }
                                      temp.push({
                                        companySupportCode: sp.id,
                                      })
                                      const support = support3.map(
                                        (item, idx2) => ({
                                          ...item,
                                          check: idx == idx2 ? true : false,
                                        })
                                      )
                                      setRecruitment({
                                        ...recruitment,
                                        companySupports: [...temp],
                                      })
                                      setSupport3([...support])
                                    } else {
                                      let temp = recruitment.companySupports
                                      const check = support3.filter(
                                        (item) => item.check
                                      )
                                      if (check.length > 0) {
                                        temp = temp.filter(
                                          (item) =>
                                            item.companySupportCode !=
                                            check[0].id
                                        )
                                      }
                                      const support = support3.map(
                                        (item, idx2) => ({
                                          ...item,
                                          check: idx == idx2 ? true : false,
                                        })
                                      )
                                      setRecruitment({
                                        ...recruitment,
                                        companySupports: [...temp],
                                      })
                                      setSupport3([...support])
                                    }
                                  }
                                }}
                              />
                              <label
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                                htmlFor={`support3_${idx}`}
                                className="me-25"
                              >
                                <span
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                ></span>
                                {sp.nameEn}
                              </label>
                            </React.Fragment>
                          ))}
                      </div>
                      <div className="d-flex">
                        {support4 &&
                          support4.map((sp, idx) => (
                            <React.Fragment key={idx}>
                              <input
                                type="checkbox"
                                name="support_checkbox4"
                                id={`support4_${idx}`}
                                checked={
                                  recruitment.companySupports.filter(
                                    (item) => item.companySupportCode == sp.id
                                  ).length > 0
                                }
                                onChange={(e) => {
                                  if (edit) {
                                    if (e.target.checked) {
                                      let temp = recruitment.companySupports
                                      const check = support4.filter(
                                        (item) => item.check
                                      )
                                      if (check.length > 0) {
                                        temp = temp.filter(
                                          (item) =>
                                            item.companySupportCode !=
                                            check[0].id
                                        )
                                      }
                                      temp.push({
                                        companySupportCode: sp.id,
                                      })
                                      const support = support4.map(
                                        (item, idx2) => ({
                                          ...item,
                                          check: idx == idx2 ? true : false,
                                        })
                                      )
                                      setRecruitment({
                                        ...recruitment,
                                        companySupports: [...temp],
                                      })
                                      setSupport4([...support])
                                    } else {
                                      let temp = recruitment.companySupports
                                      const check = support4.filter(
                                        (item) => item.check
                                      )
                                      if (check.length > 0) {
                                        temp = temp.filter(
                                          (item) =>
                                            item.companySupportCode !=
                                            check[0].id
                                        )
                                      }
                                      const support = support4.map(
                                        (item, idx2) => ({
                                          ...item,
                                          check: idx == idx2 ? true : false,
                                        })
                                      )
                                      setRecruitment({
                                        ...recruitment,
                                        companySupports: [...temp],
                                      })
                                      setSupport4([...support])
                                    }
                                  }
                                }}
                              />
                              <label
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                                htmlFor={`support4_${idx}`}
                                className="me-25"
                              >
                                <span
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                ></span>
                                {sp.nameEn}
                              </label>
                            </React.Fragment>
                          ))}
                      </div>
                      <div className="d-flex">
                        {support5 &&
                          support5.map((sp, idx) => (
                            <React.Fragment key={idx}>
                              <input
                                type="checkbox"
                                name="support_checkbox4"
                                id={`support5_${idx}`}
                                checked={
                                  recruitment.companySupports.filter(
                                    (item) => item.companySupportCode == sp.id
                                  ).length > 0
                                }
                                onChange={(e) => {
                                  if (edit) {
                                    if (e.target.checked) {
                                      let temp = recruitment.companySupports
                                      const check = support5.filter(
                                        (item) => item.check
                                      )
                                      if (check.length > 0) {
                                        temp = temp.filter(
                                          (item) =>
                                            item.companySupportCode !=
                                            check[0].id
                                        )
                                      }
                                      temp.push({
                                        companySupportCode: sp.id,
                                      })
                                      const support = support5.map(
                                        (item, idx2) => ({
                                          ...item,
                                          check: idx == idx2 ? true : false,
                                        })
                                      )
                                      setRecruitment({
                                        ...recruitment,
                                        companySupports: [...temp],
                                      })
                                      setSupport5([...support])
                                    } else {
                                      let temp = recruitment.companySupports
                                      const check = support5.filter(
                                        (item) => item.check
                                      )
                                      if (check.length > 0) {
                                        temp = temp.filter(
                                          (item) =>
                                            item.companySupportCode !=
                                            check[0].id
                                        )
                                      }
                                      const support = support5.map(
                                        (item, idx2) => ({
                                          ...item,
                                          check: idx == idx2 ? true : false,
                                        })
                                      )
                                      setRecruitment({
                                        ...recruitment,
                                        companySupports: [...temp],
                                      })
                                      setSupport5([...support])
                                    }
                                  }
                                }}
                              />
                              <label
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                                htmlFor={`support5_${idx}`}
                                className="me-25"
                              >
                                <span
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                ></span>
                                {sp.nameEn}
                              </label>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Company Culture
                    </p>
                    <div className="col-int">
                      {chunk(culture, 6).map((item, idx) => (
                        <div className="d-flex" key={idx}>
                          {item.map((cc, idx2) => (
                            <React.Fragment key={idx2}>
                              <input
                                type="checkbox"
                                name="culture"
                                id={`culture_${idx}_${idx2}`}
                                checked={
                                  recruitment.companyCultures.filter(
                                    (item) => item.companyCultureCode == cc.id
                                  ).length > 0
                                }
                                onChange={(e) => {
                                  if (edit) {
                                    if (e.target.checked) {
                                      const temp = recruitment.companyCultures
                                      temp.push({
                                        companyCultureCode: cc.id,
                                      })
                                      setRecruitment({
                                        ...recruitment,
                                        companyCultures: [...temp],
                                      })
                                    } else {
                                      const temp = recruitment.companyCultures
                                      setRecruitment({
                                        ...recruitment,
                                        companyCultures: [
                                          ...temp.filter(
                                            (delTemp) =>
                                              delTemp.companyCultureCode !=
                                              cc.id
                                          ),
                                        ],
                                      })
                                    }
                                  }
                                }}
                              />
                              <label
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                                htmlFor={`culture_${idx}_${idx2}`}
                                className="me-25"
                              >
                                <span
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                ></span>
                                {cc.nameEn}
                              </label>
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </article>
                </section>
                <section className="d-grid gap-50 flex-wrap mt-20">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Video Interview
                    </p>
                    <div className="col-int">
                      <div className="d-flex">
                        {trueFalse?.map((tf, idx) => (
                          <React.Fragment key={idx}>
                            <input
                              type="radio"
                              name="video_radio"
                              id={`video_radio${idx}`}
                              checked={
                                String(recruitment.videoInterviewRequired) ==
                                tf.value
                              }
                              onChange={() => {
                                if (edit) {
                                  setRecruitment({
                                    ...recruitment,
                                    videoInterviewRequired:
                                      tf.value == 'true' ? true : false,
                                  })
                                }
                              }}
                            />
                            <label
                              style={{ cursor: edit ? 'pointer' : 'default' }}
                              htmlFor={`video_radio${idx}`}
                              className="me-25"
                            >
                              <span
                                style={{ cursor: edit ? 'pointer' : 'default' }}
                              ></span>
                              {tf.txt}
                            </label>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </article>
                </section>
              </div>
            </div>

            <div className="card">
              <div className="card-head">
                <h6 className="card-title">채용 절차</h6>
              </div>
              <div className="card-body">
                <section className="d-grid gap-30 flex-wrap">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Recruitment Process
                    </p>
                    <div className="wid-75">
                      {recruitment.processes.length > 0 &&
                        recruitment.processes.map((item, idx) => (
                          <div className={idx != 0 ? 'mt-30' : ''} key={idx}>
                            {edit && (
                              <div className="btn_form">
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const temp = recruitment.processes.filter(
                                      (item, index) =>
                                        index != idx ? item : null
                                    )
                                    setRecruitment({
                                      ...recruitment,
                                      processes: [...temp],
                                    })
                                  }}
                                  className="form_btn"
                                >
                                  delete
                                </a>
                              </div>
                            )}
                            <p className="p2">{idx + 1} Step</p>
                            <div
                              className="grid-text"
                              style={{ marginTop: '5px', marginBottom: '5px' }}
                            >
                              <div>
                                <p className="p3 wid-50">Process</p>
                              </div>
                              <div className="innter-flex mt-20">
                                {/* <input
                                  type="text"
                                  id=""
                                  className="int wid-100"
                                  placeholder="Please input process"
                                  disabled={!edit}
                                  value={item.process}
                                  onChange={(e) => {
                                    const temp = recruitment.processes
                                    temp[idx].process = e.target.value
                                    setRecruitment({
                                      ...recruitment,
                                      processes: [...temp],
                                    })
                                  }}
                                /> */}
                                <select
                                  className="int wid-40"
                                  disabled={!edit}
                                  value={item.process}
                                  onChange={(e) => {
                                    const temp = recruitment.processes
                                    temp[idx].process = e.target.value
                                    setRecruitment({
                                      ...recruitment,
                                      processes: [...temp],
                                    })
                                  }}
                                >
                                  <option value={''}>-</option>
                                  {process.map((item, idx) => (
                                    <option value={item} key={idx}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="mt-20">
                                <p className="p3 wid-50">Description</p>
                              </div>
                              <div className="int-title int-text wid-100 mt-20">
                                <textarea
                                  type="text"
                                  id="a_title"
                                  cols="30"
                                  rows="10"
                                  className="int int_textarea height_1"
                                  placeholder="Please input work description."
                                  disabled={!edit}
                                  value={item.description}
                                  onChange={(e) => {
                                    if (e.target.value.length < 1000) {
                                      const temp = recruitment.processes
                                      temp[idx].description = e.target.value
                                      setRecruitment({
                                        ...recruitment,
                                        processes: [...temp],
                                      })
                                    }
                                  }}
                                />
                                <span className="element-text">
                                  {item.description.length}/
                                  <span className="text-primary">1000</span>자
                                </span>
                              </div>
                            </div>
                            {edit &&
                              idx + 1 == recruitment.processes.length && (
                                <div className="btn_form">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      const temp = recruitment.processes
                                      temp.push({
                                        process: '',
                                        description: '',
                                      })
                                      setRecruitment({
                                        ...recruitment,
                                        processes: [...temp],
                                      })
                                    }}
                                    className="form_btn"
                                  >
                                    add
                                  </a>
                                </div>
                              )}
                          </div>
                        ))}
                      {recruitment.processes.length == 0 && (
                        <div>
                          {edit && (
                            <div className="btn_form">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                  const temp = recruitment.processes
                                  temp.push({
                                    process: '',
                                    description: '',
                                  })
                                  setRecruitment({
                                    ...recruitment,
                                    processes: [...temp],
                                  })
                                }}
                                className="form_btn"
                              >
                                add
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </article>
                </section>
              </div>
            </div>

            <div className="card">
              <div className="card-head">
                <h6 className="card-title">Questions</h6>
              </div>
              <div className="card-body">
                <section className="d-grid gap-50 flex-wrap">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Questions
                    </p>
                    <div className="col-int">
                      {chunk(question, 1).map((item, index) => (
                        <div key={index}>
                          {item.map((qs1, idx1) =>
                            qs1.question.map((qs2, idx2) => (
                              <React.Fragment key={idx2}>
                                <input
                                  type="checkbox"
                                  name="question"
                                  id={`question_${idx1}_${idx2}`}
                                  value={`${idx1}_${idx2}`}
                                  checked={
                                    recruitment.questions.filter(
                                      (item) => item.questionId == qs2.id
                                    ).length > 0
                                  }
                                  onChange={(e) => {
                                    if (edit) {
                                      if (e.target.checked) {
                                        const temp = recruitment.questions
                                        temp.push({
                                          questionId: qs2.id,
                                          question: qs2.question,
                                        })
                                        console.log(temp)
                                        setRecruitment({
                                          ...recruitment,
                                          questions: [...temp],
                                        })
                                      } else {
                                        const temp = recruitment.questions
                                        setRecruitment({
                                          ...recruitment,
                                          questions: [
                                            ...temp.filter(
                                              (delTemp) =>
                                                delTemp.questionId != qs2.id
                                            ),
                                          ],
                                        })
                                      }
                                    }
                                  }}
                                />
                                <label
                                  style={{
                                    cursor: edit ? 'pointer' : 'default',
                                  }}
                                  htmlFor={`question_${idx1}_${idx2}`}
                                  className="me-25"
                                >
                                  <span
                                    style={{
                                      cursor: edit ? 'pointer' : 'default',
                                    }}
                                  ></span>
                                  {qs2.question}
                                </label>
                              </React.Fragment>
                            ))
                          )}
                        </div>
                      ))}
                    </div>
                  </article>
                </section>
              </div>
            </div>

            <div className="card">
              <div className="card-head">
                <h6 className="card-title">기타 정보</h6>
              </div>
              <div className="card-body">
                <section className="d-grid gap-30 flex-wrap">
                  <article className="innter-flex">
                    <p className="p2 text-secondary recruitment-form">
                      Dress Code
                    </p>
                    <div className="wid-75">
                      {recruitment.dressCodes.length > 0 &&
                        recruitment.dressCodes.map((item, idx) => (
                          <div className={idx != 0 ? 'mt-30' : ''} key={idx}>
                            {edit && (
                              <div className="btn_form">
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const temp = recruitment.dressCodes.filter(
                                      (item, index) =>
                                        index != idx ? item : null
                                    )
                                    setRecruitment({
                                      ...recruitment,
                                      dressCodes: [...temp],
                                    })
                                  }}
                                  className="form_btn"
                                >
                                  delete
                                </a>
                              </div>
                            )}
                            <div
                              className="grid-text"
                              style={{ marginTop: '5px', marginBottom: '5px' }}
                            >
                              {/* <input
                                type="text"
                                id=""
                                className="int wid-100"
                                placeholder="Please input dress code"
                                disabled={!edit}
                                value={item.dressCode}
                                onChange={(e) => {
                                  const temp = recruitment.dressCodes
                                  temp[idx].dressCode = e.target.value
                                  setRecruitment({
                                    ...recruitment,
                                    dressCodes: [...temp],
                                  })
                                }}
                              /> */}
                              <select
                                className="int wid-40"
                                disabled={!edit}
                                value={item.dressCode}
                                onChange={(e) => {
                                  const temp = recruitment.dressCodes
                                  temp[idx].dressCode = e.target.value
                                  setRecruitment({
                                    ...recruitment,
                                    dressCodes: [...temp],
                                  })
                                }}
                              >
                                <option value={''}>-</option>
                                {dressCode.map((item, idx) => (
                                  <option value={item} key={idx}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {edit &&
                              idx + 1 == recruitment.dressCodes.length && (
                                <div className="btn_form">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      const temp = recruitment.dressCodes
                                      temp.push({
                                        dressCode: '',
                                      })
                                      setRecruitment({
                                        ...recruitment,
                                        dressCodes: [...temp],
                                      })
                                    }}
                                    className="form_btn"
                                  >
                                    add
                                  </a>
                                </div>
                              )}
                          </div>
                        ))}
                      {recruitment.dressCodes.length == 0 && (
                        <div>
                          {edit && (
                            <div className="btn_form">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                  const temp = recruitment.dressCodes
                                  temp.push({
                                    dressCode: '',
                                  })
                                  setRecruitment({
                                    ...recruitment,
                                    dressCodes: [...temp],
                                  })
                                }}
                                className="form_btn"
                              >
                                add
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </article>
                </section>
              </div>
              <div className="form-btn justify-content-center mt-30">
                <div className="me-10">
                  <button
                    type="submit"
                    className="comm-btn bd-radius-5 btn-danger"
                    onClick={() => cancelContents()}
                  >
                    취소
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    className="comm-btn bd-radius-5 btn-gray"
                    onClick={() => updateContents()}
                  >
                    수정
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </Fragment>
  )
}

export default InternshipRecruitments
