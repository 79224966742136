import React, { Fragment, useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import { Container } from 'reactstrap'
import { useNavigate, Link } from 'react-router-dom'
import { getGlobalcampDetail, insertGlobalcamp, updateGlobalcamp } from '../../../api/post'
import { getUrlParams, isEmpty, setDateTime } from '../../../utils/index'
import { MENUITEMS } from '../../../data/menu'
import QuillEditor from '../../common/quill'
import { setLoading } from '../../../redux/loading'
import { useAppDispatch } from '../../../redux/store'

const InternshipStory = () => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const [params, setParams] = useState({})
  const [editMode, setEditMode] = useState(false)
  
  const [recruitments, setRecruitments] = useState([])

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [active, setActive] = useState(true)
  // const [numberOfRecruits, setNumberOfRecruits] = useState(0)
  // const [cost, setCost] = useState(0)

  // 모집 일정(시작)
  const [startAt, setStartAt] = useState(new Date(dayjs().subtract(1, 'y').format('YYYY.MM.DD 00:00:00')))
  // 모집 일정(종료)
  const [endAt, setEndAt] = useState(new Date(dayjs().format('YYYY.MM.DD 23:59:59')))

  const [fileName, setFileName] = useState('')
  const [preview, setPreview] = useState('')
  // 등록 시 활용
  const [addImage, setAddImage] = useState([])

  // 대표 이미지
  const [addRepImage, setAddRepImage] = useState([])

  useEffect(() => {
    dispatch(setLoading(false))
    
    const urlParams = getUrlParams(window.location.href)
    setParams(urlParams)

    if(urlParams?.mode != 'reg') {
      getContents(urlParams)
      setEditMode(false)
    }else {
      setEditMode(true)
    }

    const temp = MENUITEMS.Items.filter(
      (menu) =>
        menu.children.filter((ch) => ch.path == window.location.pathname)
          .length != 0
    )
  }, [])

  const savePreview = (files, url = null) => {
    if(files !== null && files?.size > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(files)
      reader.onloadend = () => {
        setPreview(reader.result)
      }
    } else if (files !== null && files?.url) {
      setPreview(`${url}/${files?.url}`)
    } else {
      setPreview(null)
    }
  }

  const dataCheck = () => {
    if((startAt == '' || startAt == null || startAt == undefined) 
      || (endAt == '' || endAt == null || endAt == undefined)
      || isEmpty(title)
      || isEmpty(content)
      || (!(addRepImage !== null && addRepImage?.size > 0) && recruitments?.files.length === 0)
    ) {
      alert('필수 입력 항목을 입력해주세요')
      return false
    }

    return true
  }
  
  // 등록
  const insertContents = async () => {
    if(!dataCheck()) return

    const formData = new FormData()
    formData.append('title', title)
    formData.append('content', content)
    formData.append('active', active)
    formData.append('startAt', setDateTime(startAt))
    formData.append('endAt', setDateTime(endAt))
    // formData.append('campStartAt', setDateTime(campStartAt))
    // formData.append('campEndAt', setDateTime(campEndAt))
    // formData.append('numberOfRecruits', numberOfRecruits)
    // formData.append('cost', cost)

    for(let i=0;i<addImage.length; i++) {
      if(content.indexOf(addImage[i].baseFile) < 0) {
        continue
      }
      formData.append('addImage', addImage[i].file)
      formData.append('addBaseImage', addImage[i].baseFile)
    }
    setAddImage([])

    // 대표 이미지 추가
    formData.append('uploadFiles', addRepImage)
    
    dispatch(setLoading(true))
    
    const { code, data } = await insertGlobalcamp(formData).finally(() => {
      dispatch(setLoading(false))
    })
    if (code == 200) {
      alert('등록 완료')
      navigate('/contents/globalcamp/manage')
    }else {
      alert('등록 실패')
    }
  }

  const cancelContents = () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm(`작성한 내용을 저장하지 않고\n상위 페이지로 이동하시겠습니까?`)) {
      window.location.reload()
    }
  }

  // 상세
  const getContents = async (param) => {
    if(Object.keys(param?.gid).length > 0) {
      dispatch(setLoading(true))
      
      const { code, data } = await getGlobalcampDetail(param?.gid).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        if(data?.images.length > 0) {
          for(let i=0; i<data?.images.length; i++) {
            data.content = data?.content.replace(data?.images[i]?.url, `${data?.awsS3Url}/${data?.images[i]?.url}`)
          }   
        }
        setRecruitments({ ...data })
        setTitle(data?.title)
        setContent(data?.content)
        setActive(data?.active)
        setStartAt(data?.startAt)
        setEndAt(data?.endAt)

        setFileName(data?.files?.[0]?.originalFileName ?? '')
        savePreview(data?.files?.[0], data?.awsS3Url)
      } else {
        setRecruitments({})
      }
    }
  }
  // 수정
  const updateContents = async () => {    
    if(!dataCheck()) return

    const formData = new FormData()
    formData.append('title', title)
    formData.append('content', content.replace(new RegExp(`${recruitments?.awsS3Url}/`, "gi"), ''))
    formData.append('active', active)
    formData.append('startAt', setDateTime(startAt))
    formData.append('endAt', setDateTime(endAt))
    // formData.append('campStartAt', setDateTime(campStartAt))
    // formData.append('campEndAt', setDateTime(campEndAt))
    // formData.append('numberOfRecruits', numberOfRecruits)
    // formData.append('cost', cost)


    for(let i=0;i<addImage.length; i++) {
      if(content.indexOf(addImage[i].baseFile) < 0) {
        continue
      }
      formData.append('addImage', addImage[i].file)
      formData.append('addBaseImage', addImage[i].baseFile)
    }
    setAddImage([])


    // 컨텐츠 이미지 정리 start
    const deleteFiles = []
    if(recruitments?.images.length > 0) {
      for(let i=0; i<recruitments?.images.length; i++) {
        if(content.indexOf(recruitments?.images[i]?.url) < 0) {
          deleteFiles.push(recruitments?.images[i])
        }
      }   
    }

    if(deleteFiles.length > 0) {
      for(let i=0;i<deleteFiles.length; i++) {
        formData.append('deleteFileIds', deleteFiles[i]?.id)
        formData.append('deleteFileUrls', deleteFiles[i]?.url)
      }
    }
    // 컨텐츠 이미지 정리 end

    // 대표 이미지 정리 start
    if(addRepImage !== null && addRepImage?.size > 0) {
      formData.append('uploadFiles', addRepImage)
      const key = Object.keys(recruitments?.files[0])
      for(let i=0; i<key.length; i++) {
        if(recruitments?.files[0][key[i]] !== null) {
          formData.append(`removeFiles[0].${key[i]}`, recruitments?.files[0][key[i]])
        }
      }
    }
    // 대표 이미지 정리 end

    dispatch(setLoading(true))
    
    const { code, data } = await updateGlobalcamp(params?.gid, formData).finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      alert('수정 완료')
    }else {
      alert('수정 실패')
    }

    // 리로드
    window.location.reload()
  }

  return (
    <Fragment>
      <Container>
        <div className="contents">
          <div className="card">
            <div className="card-head">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-center"><h6 className="card-title">{ params?.mode == 'reg' ? '등록' : '상세보기 및 수정' }</h6></div>
                {params?.mode != 'reg' && (
                    <a href="#" className="comm-btn btn-md btn-gray bd-radius-3" 
                      onClick={(e) => { 
                        e.preventDefault()
                        setEditMode(!editMode)
                      }}
                    >
                      { !editMode ? '수정' : '보기' }
                    </a>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="form-col mb-15">
                <label className="lbl">모집 일정 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int">
                  <div className="filter-int" style={{ alignItems: 'center' }}>
                    <input
                      type="date"
                      id="startAt"
                      className="int"
                      value={dayjs(startAt).format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setStartAt(e.target.value)
                      }
                      max={dayjs(endAt).format('YYYY-MM-DD')}
                      style={{ width: '150px', marginRight: '10px' }}
                      disabled={!editMode}
                    ></input>
                    ~
                    <input
                      type="date"
                      id="endAt"
                      className="int"
                      value={dayjs(endAt).format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setEndAt(e.target.value)
                      }
                      min={dayjs(startAt).format('YYYY-MM-DD')}
                      style={{
                        width: '150px',
                        marginLeft: '10px',
                        marginRight: '30px',
                      }}
                      disabled={!editMode}
                    ></input>
                    <button
                      type="button"
                      className={'comm-btn btn-secondary'}
                      style={{
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        const date = dayjs(endAt)
                        setStartAt(date.subtract(1, 'w').toDate())
                      }}
                      disabled={!editMode}
                    >
                      1주일
                    </button>
                    <button
                      type="button"
                      className={'comm-btn btn-secondary'}
                      style={{
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        const date = dayjs(endAt)
                        setStartAt(date.subtract(1, 'M').toDate())
                      }}
                      disabled={!editMode}
                    >
                      1개월
                    </button>
                    <button
                      type="button"
                      className={'comm-btn btn-secondary'}
                      style={{
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        const date = dayjs(endAt)
                        setStartAt(date.subtract(3, 'M').toDate())
                      }}
                      disabled={!editMode}
                    >
                      3개월
                    </button>
                    <button
                      type="button"
                      className={'comm-btn btn-secondary'}
                      style={{
                        marginRight: '10px',
                        width: '82px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      onClick={() => {
                        const date = dayjs(endAt)
                        setStartAt(date.subtract(1, 'y').toDate())
                      }}
                      disabled={!editMode}
                    >
                      1년
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-col mb-15">
                <label htmlFor="title" className="lbl">제목 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int col-2">
                  <div className="d-flex">
                    <input type="text" id="title" className="int" placeholder="제목" value={title} disabled={!editMode}
                      onChange={(e) => {
                        setTitle(e.target.value)
                    }} />
                  </div>
                </div>
              </div>
              <div className="form-col mb-15">
                <label htmlFor="active" className="lbl">대표 이미지 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int">
                  <div className="int-filebox">
                    <label htmlFor="a_fileName" className="comm-btn btn-secondary">파일 선택</label> 
                    <input className="upload-name int" placeholder="파일을 선택하세요." disabled="disabled" value={fileName} />
                    <input type="file" id="a_fileName" className="upload-hidden" disabled={!editMode} 
                      onChange={(e) => {
                        if(isEmpty(e.target.value)) {
                          setFileName('')
                          setAddRepImage([])
                          savePreview('')
                        }else {
                          setFileName(e.target.value.split('/').pop().split('\\').pop())
                          setAddRepImage(e.target.files[0])
                          savePreview(e.target.files[0])
                        }
                      }}
                    /> 
                  </div>
                  <p className="text-error">권장사이즈는 555 x 312 입니다.</p>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl"></label>
                <div className="col-int col-2">
                  { preview ? <img src={preview} alt="미리보기" /> : <div></div> }
                </div>
              </div>
              <div className="form-col mb-15">
                <label htmlFor="active" className="lbl">노출여부 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int">
                  <select id="active" className="int" style={{ width: '128px' }}
                    onChange={(e) => {
                      setActive(e.target.value)
                    }} 
                    value={active ?? true}
                    disabled={!editMode}
                  >
                    <option value={ true } key={ true }>노출</option>
                    <option value={ false } key={ false }>미노출</option>
                  </select>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl">내용 <span className="required"><span>필수입력</span></span></label>
              </div>
              <div className="form-col mb-15">
                <QuillEditor
                  html={content}
                  setHtml={setContent}
                  image={addImage}
                  setImage={setAddImage}
                  readOnly={editMode}
                />
              </div>
            </div>
            { params?.mode == 'reg' 
              ? (
                <div className="form-btn justify-content-center mt-30">
                  <button type="submit" className="comm-btn bd-radius-5 btn-gray" 
                    onClick={() => insertContents()} >등록</button>
                </div>
              )
              : editMode 
                ? (
                  <div className="form-btn justify-content-center mt-30">
                    <div className="me-10">
                      <button type="submit" className="comm-btn bd-radius-5 btn-danger" 
                        onClick={() => cancelContents()} >취소</button>
                    </div>
                    <div>
                      <button type="submit" className="comm-btn bd-radius-5 btn-gray" 
                        onClick={() => updateContents()} >수정</button>
                    </div>
                  </div>
                )
                : (
                  <div className="form-btn justify-content-center mt-30">
                    <Link to={`/contents/globalcamp`}>
                      <button type="submit" className="comm-btn bd-radius-5 btn-gray"
                        onClick={() => {}} >목록</button>
                    </Link>
                  </div>
                )
            }
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default InternshipStory
